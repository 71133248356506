import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-benefit-restrictions-rules-tasks',
  templateUrl: './benefit-restrictions-rules-tasks.component.html',
  styleUrls: ['./benefit-restrictions-rules-tasks.component.scss']
})
export class BenefitRestrictionsRulesTasksComponent implements OnInit {
  @Input() benefit;
  @Input() type: string;
  constructor() { }

  ngOnInit(): void {
    if(this.benefit){
      this.isRestrictionsAvailable(this.benefit)
    }
  }

  isRestrictionsAvailable(benefit) {
    if ((benefit && benefit.scopes &&
      ((benefit?.scopes?.spouse_partner && benefit.scopes?.spouse_partner?.spousePartnerRelocating) ||
        (benefit?.scopes?.valid_complex_rules && benefit?.scopes?.valid_complex_rules?.length > 0) ||
        ((benefit?.scopes?.departure_locations && benefit?.scopes?.departure_locations?.excludes && benefit?.scopes?.departure_locations?.excludes?.length) ||
          (benefit?.scopes?.departure_locations && benefit?.scopes?.departure_locations?.includes && benefit?.scopes?.departure_locations?.includes?.length)
          || (benefit?.scopes?.destination_locations && benefit?.scopes?.destination_locations?.includes && benefit?.scopes?.destination_locations?.includes?.length)
          || (benefit?.scopes?.destination_locations && benefit?.scopes?.destination_locations?.excludes && benefit?.scopes?.destination_locations?.excludes?.length)
          || (benefit?.scopes?.simple_rules && benefit?.scopes?.simple_rules?.length))))) {
      return true;
    } else {
      return false;
    }
  }

}
