<div mat-dialog-title>
    <div fxLayout="row" >
        <div fxFlex="85" fxLayoutAlign="start center">
           <p  id="mat-dialog-title-0" *ngIf="dialogData">{{dialogData.title}}</p>
        </div>
    </div>
    <mat-icon (click)="closeDialog('close')" class="mat-dialog-close-icon">close</mat-icon>
</div>
<div class="template_content">
    <div fxLayout="row" fxLayoutGap="1.25em" class="tab_row">
        <div fxFlex="25">
            <div>
                <div class="tab-div" [ngClass]="{'tab-div-active':(selectedTemplateName === 'Existing Templates')}" (click)="getTemplates('Active');">
                    <div fxFlex="20" class="tab-icon" fxLayoutAlign="start center">
                        <mat-icon>check_circle_outline</mat-icon>
                    </div>
                    <div fxFlex="80" class="tab-name" fxLayoutAlign="start center">
                        <p >
                            Existing Templates
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div fxFlex="25">
            <div class="tab-div" [ngClass]="{'tab-div-active':(selectedTemplateName === 'Draft Templates')}" (click)="getTemplates('Draft');">
                <div fxFlex="20" class="tab-icon" fxLayoutAlign="start center">
                    <img *ngIf="selectedTemplateName !== 'Draft Templates'" class="" src="../assets/icons/draft_icon.png" alt="" />
                    <img *ngIf="selectedTemplateName === 'Draft Templates'" class="" src="../assets/icons/draft_icon_white.png" alt="" />
                </div>
                <div fxFlex="80" class="tab-name" fxLayoutAlign="start center">
                    <p >
                        Draft Templates
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div fxLayout="row">
        <div fxFlex="100" >
            <div fxLayout="row" class="serch_input_row">
                <div fxFlex="8%" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon  class="search_icon">search</mat-icon>
                </div>
                <div fxFlex="92%" fxLayoutAlign="start center">
                    <input class="search_input"  [(ngModel)]="searchOptions.searchText" matInput placeholder="Search Templates…"  #searchInput  (ngModelChange)="searchTemplates(); ">
                </div>
            </div>
        </div>
    </div>
    <div class="template_list_section">
        <div fxLayout="row" class="template_section_row" *ngFor="let template of templatesData;let i = index;">
            <div  class="template_section_div">
                <section fxLayoutAlign="start start" class="template_section" [ngClass]="{'selected_templates': isSelectedTemplate(template)}">
                    <input class="incremental_checkbox" type="checkbox" [checked]="isSelectedTemplate(template)"  (change)="selectedTemplates(template,$event.currentTarget.checked ? 'add' : 'remove')" id="{{'increment'+i}}" />
                    <label for="{{'increment'+i}}" class="incremental_lbl">{{template.name}}</label>
                </section>
            </div>
        </div>
    </div>
</div>
<!-- <mat-dialog-actions align="end" *ngIf="dialogData && dialogData.actions.length">
    <button mat-button *ngFor="let action of dialogData.actions" [ngClass]="{'mat-dialog-close':(action.type == 'close'),'mat-dialog-delete':(action.type == 'delete'),'mat-dialog-restore':(action.type == 'restore' || action.type == 'add')}" (click)="closeDialog(action.type)"  >{{action.name}}</button>
</mat-dialog-actions> -->
<div class="footer_content">
    <div fxLayout="row" fxLayoutAlign="end center" class="footer_row">
        <div fxFlex="60%">
            <div fxLayout="row"  fxLayoutAlign="end center">
                <div fxFlex="30%"  fxLayoutAlign="end center">
                    <button mat-button class="mat-dialog-close" (click)="closeDialog('close')" >Close</button>
                </div>
                <div fxFlex="40%"  fxLayoutAlign="end center">
                    <button  mat-button class="mat-dialog-restore" [disabled]="!selectedTemplatesList.length" [ngClass]="{'disable-publish-btn': !selectedTemplatesList.length}" (click)="closeDialog('add')">Add to Template</button>
                </div>
            </div>
        </div>
    </div>
</div>