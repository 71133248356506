import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-sync-pgm-modal',
  templateUrl: './sync-pgm-modal.component.html',
  styleUrls: ['./sync-pgm-modal.component.scss']
})
export class SyncPgmModalComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SyncPgmModalComponent>) {}

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close({
      action: 'dismiss'
    });
  }

  onCancel(): void {
    this.dialogRef.close({
      action: 'dismiss'
    });
  }

  view(): void {
    this.dialogRef.close({
      action: 'view'
    });
  }
}
