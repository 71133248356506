<div class="row" class="header-toolbar">
    <div fxFlex="5%">
        <a class="back-btn-icon" aria-label="Example icon-button with menu icon" (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
        </a>
    </div>
    <div fxFlex="70%">
        <div fxFlex="93%">
        <span class="temp-header">Template :</span>
        <span class="temp-name-span">&nbsp;{{templateName}}</span>
        </div>
    </div>
    <div fxFlex="5%" fxFlexOffset="5%" fxLayoutAlign="end">
        <button *ngIf="this.tempStatus !== 'Archived'"  mat-icon-button class="toolbar-right-icon favorite-icon" (click)="editTemplate()" aria-label="Example icon-button with heart icon">
            <mat-icon class="mat-card-icons">edit</mat-icon>
        </button>
    </div>
    <div fxFlex="5%" fxLayoutAlign="end">
        <button *ngIf="this.tempStatus !== 'Archived'"  mat-icon-button class="toolbar-right-icon" (click)="duplicateTheTemp()" aria-label="Example icon-button with share icon">
            <img class="offering-logo-img" src="../assets/icons/material-content-copy.svg" alt="" />
        </button>
    </div>
    <div fxFlex="5%" fxLayoutAlign="end">
        <button *ngIf="this.tempStatus !== 'Archived'" mat-icon-button (click)="deleteTemplate()" class="toolbar-right-icon" aria-label="Example icon-button with share icon">
            <mat-icon class="mat-card-icons">delete</mat-icon>
        </button>
        <button *ngIf="this.tempStatus === 'Archived'" mat-icon-button (click)="restoreArchivedTempToDraft()" class="toolbar-right-icon" aria-label="Example icon-button with share icon">
            <mat-icon>settings_backup_restore</mat-icon>
        </button>
    </div>
    <div fxFlex="5%" fxLayoutAlign="end ">
        <!-- <button mat-icon-button class="toolbar-right-icon" aria-label="Example icon-button with share icon">
            <img class="offering-logo-img" src="../assets/icons/material-file-download.svg" alt="" />
        </button> -->
    </div>
</div>
<div class="template_content">
    <div fxLayout="row" class="offering_desc_row">
        <div fxFlex="100%" >
            <span class="offering_desc">{{templateDescription}}
            <!-- Lorem ipsum dolor sit amet, Consetuture edlit, sed diam nonmytempor invidunt ut labore et dolore aliquyam erat, sed diam voluputa, A vero eos accusam et jsuto duo dolores et ee rebum. Stet clita ksad gunenum, no sea takimata sanctus loream ipsum solor sit amet. Lorem ipsum dolor sot amet consetuter sadipscing eltr sed diam nomuny eidmod tempor inidunt ut ibore doloere magan aliquiam erat. sed volupta. At veri eos et accusam et justo duo dolores et ea rebum. Stet clita ksd gibebum, no sea takimat sanctus est Lorem dolor sit amet -->
            </span>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="100%" fxLayout="row"  class="search_row">
            <div fxFlex="50%" fxLayoutAlign="start center">
                <span class="offering-count-span">Added Offering(s) - {{addedOfferingsList.length}}</span>
            </div>
            <div fxFlex="{{!showSearchInput ? '5%' : '50%'}}" fxFlexOffset="{{!showSearchInput ? '55%' : '0%'}}" fxLayout="row" [ngClass]="{'serch_input_row_selected' : showSearchInput}">
                <div fxFlex="{{!showSearchInput ? '100%' : '10%'}}"  fxLayout="column" (click)="showSearchInput = !showSearchInput" fxLayoutAlign="center center">
                    <div [ngClass]="{'serch_input_row' : !showSearchInput}">
                        <mat-icon  class="search_icon">search</mat-icon>
                    </div>
                </div>
                <div *ngIf="showSearchInput" fxFlex="80%" fxLayoutAlign="start center">
                    <input class="search_input" [(ngModel)]="searchText" matInput placeholder="Search Offerings..."  #searchInput  (ngModelChange)="showSearchCloseIcon=true;searchOfferings()">
                </div>
                <div *ngIf="showSearchInput" fxFlex="10%" fxLayoutAlign="center center">
                    <mat-icon *ngIf="showSearchCloseIcon" class="close_icon" (click)="showSearchCloseIcon=false;searchText=null;searchOfferings();">close</mat-icon>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="showSearchCloseIcon && searchText && searchText.length >2"  fxLayout="row" class="results_row" fxLayoutAlign="end center">
        <div fxFlex="50%" >
            <span class="results_count_span"> {{(searchCount && searchCount !== '0') ? searchCount : 'No'}} Results</span>
            <span> found</span>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start" >
        <div fxFlex="100%"  >
            <ng-container *ngFor="let offering of addedOfferingsList" >
                <mat-card *ngIf="(!showSearchInput || !searchText || (searchText && searchText.length < 3) || (offering.displayName && offering.displayName.toLowerCase().includes(searchText.toLowerCase())))"  class="offering-card" >
                    <div fxLayout="row" class="" fxLayoutAlign="center center" >
                        <div fxFlex="10%" fxLayoutAlign="center center" *ngIf ="offering.iconDetails">
                            <img class="template-icon" src={{offering.iconDetails.iconName}} alt=""/>
                        </div>
                        <div fxFlex="10%" fxLayoutAlign="center center" *ngIf ="!offering.iconDetails">
                            <img src="../assets/images/offering_sample_img.png" alt=""/>
                        </div>
                        <div fxFlex="90%">
                            <div fxLayout="row wrap" class="offering_info" fxLayoutAlign="start center">
                                <div fxFlex="100%">
                                   <span class="offering-name"> {{offering.displayName}}</span>
                                </div>
                                <div fxFlex="100%">
                                    <span class="offering_desc"> {{offering.description}}</span>
                                </div>
                            </div>
                            <div fxLayout="row" class="offering_info offering_status_info"  fxLayoutAlign="start center">
                                <div fxFlex="40%">
                                    <span class="prod-name" *ngIf="offering.productSpecificationReference.displayName">{{offering.productSpecificationReference.displayName}}</span>
                                 </div>
                                 <div fxFlex="40%">
                                     <div fxLayot="row" fxLayoutAlign="start center">
                                        <div class="prod-status" *ngIf="dispalyCategory(offering).displayName" [ngStyle]="{'border-color':dispalyCategory(offering).colorCode}" >
                                            <span class="prod-status-color" [ngStyle]="{'background-color':dispalyCategory(offering).colorCode}"></span>
                                            <span class="prod-status-type">
                                                {{dispalyCategory(offering).displayName}}
                                            </span>
                                        </div>
                                        <span *ngIf="dispalyCategory(offering).count > 1" class="prod-status-count">+{{dispalyCategory(offering).count}}</span>
                                     </div>
                                 </div>
                                 <div fxFlex="20%">
                                    <div fxLayout="row" *ngIf="getCreatedBy(offering.createdBy)" fxLayoutAlign="start center">
                                        <span class="prod-user-icon" ><img class="offering-logo-img" src="../assets/icons/user_gift_icon.png" alt="" /></span>
                                        <span class="prod-user-name" >{{getCreatedBy(offering.createdBy)}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
              </ng-container>
        </div>
    </div>

</div>
<app-footer></app-footer>



