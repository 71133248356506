import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatOptionModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { FooterComponent } from './core/components/shared/footer/footer.component';
import { ProdMenuListComponent } from './core/components/product-menu-list/product-menu-list.component';
import { ProdTemplatesComponent } from './core/components/product-template/product-template.component';
import { ProdOfferingsComponent } from './core/components/product-offerings/product-offering.component';
import { BaseProdTemplateComponent } from './core/components/base-prod-template/base-prod-template.component'
import { ViewProdOfferingComponent } from './core/components/view-prod-offering/view-prod-offering.component';
import { ViewProdTemplateComponent } from './core/components/view-prod-template/view-prod-template.component';
import { EditPordTemplateComponent } from './core/components/edit-pord-template/edit-pord-template.component';
import { CreateProdTemplateComponent } from './core/components/create-prod-template/create-prod-template.component';
import { WarningModelComponent } from './core/components/common/warning-model/warning-model.component';
import { ToasterComponent } from './core/components/common/toaster/toaster.component';
import { BaseProdOfferingComponent } from './core/components/base-prod-offering/base-prod-offering.component';
import { AddTemplateModelComponent } from './core/components/common/add-template-model/add-template-model.component';
import { RestrictionItemComponent } from './core/components/common/restriction-item/restriction-item.component';
import { ApplicationUitypeRestrictionComponent } from './core/components/restriction-types/application-uitype-restriction/application-uitype-restriction.component';
import { LocationUitypeRestrictionComponent } from './core/components/restriction-types/location-uitype-restriction/location-uitype-restriction.component';
import { OperatorUitypeRestrictionComponent } from './core/components/restriction-types/operator-uitype-restriction/operator-uitype-restriction.component';
import { DropdownUitypeRestrictionComponent } from './core/components/restriction-types/dropdown-uitype-restriction/dropdown-uitype-restriction.component';
import { ComplexruleUitypeRestrictionComponent } from './core/components/restriction-types/complexrule-uitype-restriction/complexrule-uitype-restriction.component';
import { ToggleUitypeRestrictionComponent } from './core/components/restriction-types/toggle-uitype-restriction/toggle-uitype-restriction.component';
import { LogoutModalComponent } from './core/components/logout-modal/logout-modal.component'
import { ToastIdleTimeoutComponent, FormatTimePipe } from './core/components/toast-idle-timeout/toast-idle-timeout.component';
import { IconUploadModalComponent } from './core/components/icon-upload-modal/icon-upload-modal.component';
import { FilterModalComponent } from './core/components/filter-modal/filter-modal.component';
import { SyncProgramsToTemplateComponent } from './core/components/sync-programs-to-template/sync-programs-to-template.component';
import { SyncPgmModalComponent } from './core/components/sync-pgm-modal/sync-pgm-modal.component';
import { EditBenefitLogicDialogComponent } from './core/components/edit-benefit-logic-dialog/edit-benefit-logic-dialog.component';
import { BenefitSettingDialogComponent } from './core/components/benefit-setting-dialog/benefit-setting-dialog.component';
import { BenefitRestrictionsRulesTasksComponent } from './core/components/benefit-restrictions-rules-tasks/benefit-restrictions-rules-tasks.component';
import { PointsLogicModalComponent } from './core/components/points-logic-modal/points-logic-modal.component';
import { ConsultantOnlyModalComponent } from './core/components/consultant-only-modal/consultant-only-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    ProdMenuListComponent,
    ProdTemplatesComponent,
    ProdOfferingsComponent,
    BaseProdTemplateComponent,
    ViewProdTemplateComponent,
    CreateProdTemplateComponent,
    EditPordTemplateComponent,
    ViewProdOfferingComponent,
    WarningModelComponent,
    ToasterComponent,
    BaseProdOfferingComponent,
    AddTemplateModelComponent,
    RestrictionItemComponent,
    ApplicationUitypeRestrictionComponent,
    LocationUitypeRestrictionComponent,
    OperatorUitypeRestrictionComponent,
    DropdownUitypeRestrictionComponent,
    ComplexruleUitypeRestrictionComponent,
    ToggleUitypeRestrictionComponent,
    LogoutModalComponent,
    ToastIdleTimeoutComponent,
    FormatTimePipe,
    IconUploadModalComponent,
    FilterModalComponent,
    SyncProgramsToTemplateComponent,
    SyncPgmModalComponent,
    EditBenefitLogicDialogComponent,
    BenefitSettingDialogComponent,
    BenefitRestrictionsRulesTasksComponent,
    PointsLogicModalComponent,
    ConsultantOnlyModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatInputModule,
    MatOptionModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatTableModule,
    MatTabsModule,
    MatSidenavModule,
    MatListModule,
    MatDialogModule,
    DragDropModule,
    MatDividerModule,
    MatTooltipModule,
    MatSnackBarModule,
    FlexLayoutModule,
    MatMenuModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    OverlayModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: ToasterComponent,
      timeOut: 5000,
      preventDuplicates: true,
    }),
    AppRoutingModule // I must be last!! https://angular.io/guide/router#module-import-order-matters
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    { provide: MAT_DIALOG_DATA, useValue: {} }
  ],
  bootstrap: [
    AppComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
