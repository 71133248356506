<!-- Restrictions -->
<ng-container *ngIf="type === 'restrictions'">
    <div fxFlex="100" class="tab-content">
      <div *ngIf="isRestrictionsAvailable(benefit) else noInfoAdded">  
      <h4><span fxFlex = "80">
            Restrict this benefit to: </span></h4>
            <!---For Transfereeswith Spouse-->
            <div *ngIf="benefit && benefit?.scopes?.spouse_partner &&
                    benefit?.scopes?.spouse_partner?.spousePartnerRelocating"><span>Transferees with Spouse</span></div>
            <!---For Transfereeswith Spouse-->
        
            <!---For ComplexRules-->
            <div *ngIf="benefit && benefit?.scopes?.valid_complex_rules && benefit?.scopes?.valid_complex_rules?.length > 0">
                <span *ngFor="let element of benefit.scopes.valid_complex_rules">
                    {{element.name}}<br/>
                </span>
            </div>
            <!---For Departure Locations-->
            <div *ngIf="benefit && benefit.scopes && benefit.scopes.departure_locations">
                <div *ngIf="benefit?.scopes?.departure_locations?.includes && benefit?.scopes?.departure_locations?.includes?.length > 0">
                    <span class = "rule">Departure Location Includes  </span><br/>
                    <span>{{benefit.scopes.departure_locations.includes.join(',')}}</span>
                </div>
                <div *ngIf="benefit?.scopes?.departure_locations?.excludes && benefit?.scopes?.departure_locations?.excludes.length > 0">
                    <span class = "rule">Departure Location Excludes  </span><br/>
                    <span>{{benefit.scopes.departure_locations.excludes.join(',')}}
                    </span>
                </div>
            </div>
            <!---For Destination Locations-->
            <div *ngIf="benefit.scopes.destination_locations">
                <div *ngIf="benefit?.scopes?.destination_locations?.includes && benefit?.scopes?.destination_locations?.includes?.length > 0">
                    <span class = "rule">Destination Location Includes  </span><br/>
                    <span >{{benefit.scopes.destination_locations.includes.join(',')}}
                    </span>
                </div>
                <div *ngIf="benefit?.scopes?.destination_locations?.excludes && benefit?.scopes?.destination_locations?.excludes?.length > 0">
                    <span class = "rule">Destination Location Excludes  </span><br/>
                    <span>
                        {{benefit.scopes.destination_locations.excludes.join(',')}}  
                    </span>
                </div>
            </div>
            <!--Number of Relocating Children-->
            <div *ngIf="benefit?.scopes?.simple_rules && benefit?.scopes?.simple_rules?.length">
                <div *ngFor="let element of benefit.scopes.simple_rules">
                    <span class = "rule"> {{element.type}} </span><br/>
                    <span *ngFor="let val of element.values">
                        <span>{{val.operator}} {{val.value}}</span><br/>
                    </span>                                        
                </div>                
            </div>
        </div>
        <ng-template #noInfoAdded>
            <p>No Information Added</p>
        </ng-template>
    </div>
</ng-container>
<!--Rules-->
<ng-container *ngIf="type === 'rules'">
    <div fxFlex = "100"  class="tab-content">
        <div  *ngIf = "((benefit && benefit?.exclusions && benefit.exclusions?.length > 0 )|| (benefit && benefit?.prerequisiteRule && benefit?.prerequisiteRule?.length > 0) || (benefit && benefit?.inclusions && benefit?.inclusions.length > 0) || (benefit && benefit?.includeRule && benefit?.includeRule?.length > 0)) else noInfoAdded">
            <h4><span fxFlex = "80">If this benefit is selected: </span></h4>
            <div *ngIf="benefit.prerequisiteRule && benefit.prerequisiteRule?.length > 0"><span class = "rule">Enable  </span> <span *ngFor="let element of benefit.prerequisiteRule; let i = index">{{element.displayName}}{{i < benefit.prerequisiteRule?.length -1 ? ', ' : '.'}}</span></div>
            <div *ngIf="benefit.exclusions && benefit.exclusions?.length > 0"><span class = "rule">Disable  </span> <span *ngFor="let element of benefit.excludeRule; let i = index">{{element}}{{i < benefit.excludeRule.length -1 ? ', ' : '.'}} </span></div>
            <div *ngIf="benefit.inclusions && benefit.inclusions?.length > 0"><span class = "rule">Select  </span> <span *ngFor="let element of benefit.includeRule; let i = index">{{element}}{{i < benefit.includeRule.length -1 ? ', ' : '.'}} </span></div>
        </div>
        <ng-template #noInfoAdded>
            <p>No Information Added</p>
        </ng-template>
    </div>
</ng-container>
<!--Tasks-->
<ng-container *ngIf="type === 'tasks'">
    <div fxFlex = "100"  class="tab-content">
        <div  *ngIf = "(this.benefit && this.benefit?.tasks && this.benefit?.tasks?.length); else noInfoAdded">
            <h4><span fxFlex = "80">Tasks: </span></h4>
            <div  class="tab-content tasks-content">
                <ul><li *ngFor="let element of benefit.tasks; let i = index">{{element}}</li></ul>
            </div>
        </div>
        <ng-template #noInfoAdded>
            <p>No Information Added</p>
        </ng-template>
    </div>
</ng-container>