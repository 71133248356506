import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { AppConfigService } from './app-config.service';
import { OktaAuth, OktaAuthOptions, Token } from '@okta/okta-auth-js';
import { CookieService } from 'ngx-cookie-service';

/** This service is used for managing user session as well as aiding access to legacy COL */
@Injectable({
  providedIn: 'root'
})
export class UserSessionService {
  public authClient: OktaAuth;

  /** standard constructor for services */
  constructor(
    private readonly appConfig: AppConfigService,
  ) {
    const authOpts: OktaAuthOptions = {
      clientId: (this.appConfig.getConfig('oktaClientId')).toString(),
      issuer: (this.appConfig.getConfig('oktaUrl')).toString(),
      authorizeUrl: `${this.appConfig.getConfig('oktaUrl')}/v1/authorize`,
      redirectUri: (this.appConfig.getConfig('oktaRedirectUri')).toString(),
      postLogoutRedirectUri: (this.appConfig.getConfig('oktaRedirectUri')).toString(),
      tokenManager: {
        storage: 'sessionStorage'
      },
    };
    this.authClient = new OktaAuth(authOpts);
  }
  public refreshSession() {
    return this.authClient.token.getWithoutPrompt().then(tokenOrTokens => {
      console.log('getWithoutPrompt()', tokenOrTokens); // Leave this debug code in place
      return tokenOrTokens;
    }).catch(err => {
      // console.error(err); // Leave this debug code in place -- commented out due to error being displayed regardless
      return; // Not authenticated
    });
  }
}
