<div class="row" class="header-toolbar">
    <div fxFlex="5%">
        <a class="back-btn-icon" aria-label="Back button Icon" (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
        </a>
    </div>
    <div fxFlex="70%">
        <div fxFlex="93%">
        <span class="temp-header">Sync - {{templateName}}</span>
        </div>
    </div>
</div>
<div class="template_content">
    <div  fxLayout="row" class="section-title">
        <div  fxFlex="50%"  fxLayoutAlign="start start" style="padding: 10px 0;">
            <span>Template Programs</span>
        </div>
        <div fxFlex="50%"  *ngIf="showDone"  fxLayout="row" fxLayoutAlign="end center">
            <button style="width: 200px;" [disabled]="disableDone" [ngClass]="{'disableDone-btn': disableDone}" class="save-btn" (click)="goBack()">Done</button>
        </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="100%" fxLayout="row">
            <!--Programs List Section-->
            <div fxFlex="40%" fxLayoutAlign="start start">
                <div fxFlex="100%" fxLayout="row" class="program_list_container">
                    <!--search panel-->
                    <div class="search_input_row">
                        <div  fxFlex="100%" fxLayout="row" >
                            <div fxFlex="8%" fxLayout="column" fxLayoutAlign="center center">
                                <mat-icon class="search_icon">search</mat-icon>
                            </div>
                            <div fxFlex="84%" fxLayoutAlign="start center">
                                <input class="search_input" [(ngModel)]="searchText" (ngModelChange)="searchTemplatePrograms()" matInput placeholder="Search for Program Name/Client Number"
                                    #searchInput>
                            </div>
                            <div fxFlex="8%" *ngIf="searchText" fxLayoutAlign="center center">
                                <mat-icon class="close_icon" (click)="clearsearch()">close</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="program_list_section">
                        <div class="templates_info_section" >
                            <div class="template-info-gorup-alignment">
                                <div fxLayout="row" class="template-list-header-row" fxLayoutAlign="start center">
                                    <div fxFlex="50%" class="align-icon">
                                        <span>Program Name</span>
                                        <mat-icon (click)="searchOptions.sortDir = searchOptions.sortDir == 'ASC' ? 'DESC' : 'ASC';sortProgramTemplates('programName');">sort</mat-icon>
                                    </div>
                                    <div fxFlex="35%">
                                       <span>Client Name</span> 
                                    </div>
                                    <div fxFlex="25%" class="align-icon">
                                        <span>Client Number</span> 
                                        <mat-icon (click)="searchOptions.sortDir = searchOptions.sortDir == 'ASC' ? 'DESC' : 'ASC';sortProgramTemplates('clientNumber');">sort</mat-icon>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="template-gorup-alignment" >
                            <div class="template_lists_section"  >
                                <div class="template_list_div" *ngFor="let program of programsList;let last = last;"  style="cursor: pointer;position: relative;">
                                    <mat-card fxLayout="row" fxLayoutAlign="start start" class="template-list-card-row">
                                        <div fxFlex="40%" (click)="goToProgram(program)">
                                            <span class="temp-name-span text-overflow" [matTooltip]="program.programName"> {{program.programName}} </span>
                                            <span *ngIf="program.lastSyncedAt" class="temp-name-span text-overflow last-synced" [matTooltip]="program.programName"> Last Synced: {{program.lastSyncedAt | date: 'YYYY-MM-dd'}} </span>
                                        </div>
                                        <div fxFlex="35%" (click)="goToProgram(program)">
                                            <span class="temp-addby-span text-overflow" [matTooltip]="program.clientName">{{program.clientName}}</span>
                                        </div>
                                        <div fxFlex="25%" (click)="goToProgram(program)">
                                            <span class="temp-addby-span">{{program.clientNumber}}</span>
                                        </div>
                                    </mat-card>
                                   
                                </div>
                                
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
            <!--Selected Program Content Section-->
            <div fxFlex="60%" fxLayout="row">
                <ng-container>
                    <div fxFlex="100%" *ngIf="selectedProgramOfferings.length; else noProgram" class="selected_program_container">
                        <!--selected program header-->
                        <div>
                            <div class="actions-container">
                                <div fxLayout="row"  fxLayoutAlign="start center">
                                    <div fxFlex="50%"  fxLayoutAlign="start start" fxLayoutGap="5px">
                                        <span>Selected Program: <span class="pgm-name-span">{{selectedProgram.programName}}</span></span>
                                    </div>
                                    <div fxFlex="50%" fxLayoutAlign="end center" fxLayoutGap="5px">
                                        <button [disabled]="saveAsRetroDisable || CloneCopyDisable" [ngClass]="{'disable-saveAsRetro-btn': saveAsRetroDisable}" class="save-btn" (click)="saveConfirmModel()">Save as Retroactive</button>
                                    </div>
                                    <!--As of now commented, Future enhancement has to be done-->
                                    <!-- <div fxFlex="25%"  fxLayoutAlign="end center" fxLayoutGap="5px">
                                        <button  class="save-btn">Save as - go forward</button>
                                    </div> -->
                                </div>
                            </div>
                            <div class="offerings-container">
                                <ng-container  *ngFor="let offeringGroup of offeringsList; let j=index">
                                    <div [attr.aria-label]="offeringGroup.group" class="category-label" *ngIf = "offeringGroup.items.length > 0 ">
                                        <span class="category-name-span">{{ offeringGroup.group }}</span>
                                    </div>
                                    <!-- <h3><span role="none">{{ offeringGroup.group | titlecase }}</span></h3> -->
                                    <ng-container *ngFor="let offering of offeringGroup.items; let i= index">
                                        <div  class="offering-card-container" [ngClass]="offering?.suppress ? 'suppress-offering':null">
                                            <div fxLayout="row" [ngClass]="offering.clonedCopy ? 'highlight-offering':null">
                                                <mat-card class="offering-card" fxFlex="calc(100% - 10px)" fxFlex.sm="calc(50%-25px)"  [ngClass]="{ 'consultant-only': offering.consultantofferingInfo?.consultant && offering?.points !== 'Guaranteed' ,'isNewelyAdded':offering.isNewlyAddedRef }" *ngIf = "offering">
                                                    <mat-card-content>
                                                        <div fxLayout="row" fxLayoutAlign.xs="center center" role="row" fxLayoutGap="15px">
                                                            <div fxFlex="20" fxLayout="column" fxLayoutGap="10px" role="cell" class="img-icon">
                                                                <mat-chip class="temp-chip templiving-logo" *ngIf = "offering?.tempLiving">Supplier Orders <img src="../../../../../../assets/icons/icon_supplierOrders.svg" alt="Icon here" role="figure" class="icon-img"></mat-chip>
                                                                <mat-chip class="temp-chip insured-logo" *ngIf = "offering?.clientIsCartusInsured">Cartus Insured<img src="../../../../../../assets/icons/icon_cartusInsured.svg" alt="Icon here" role="figure" class="icon-img"></mat-chip>
                                                                <img [src]="offering.iconLink" alt="offering" role="figure" class="mat-elevation-z3">
                                                                <div *ngIf="offering?.hybridTempLiving" style="border: 0.2px solid gray;">
                                                                    <mat-chip *ngIf="offering?.incremental &&!isChipEditable[i] && !offering.isNewlyAddedRef " style="margin-bottom: 5px;"
                                                                        class="chip-style">
                                                                        <div class="rangeIncrementUnit" [class.tn-focus]='edit-point-edit'>Core {{offering?.rangeIncrementUnit}}
                                                                            Per
                                                                            Selection </div>
                                                                        <div>{{offering?.coreUnitsPerSelection}}</div>
                                                                        <button tabindex="0" mat-icon-button
                                                                            *ngIf="offering?.incremental"
                                                                            (click)="rangeIncrementValue = offering.rangeIncrementValue; enabledChipToEdit(i,j);">
                                                                            <mat-icon class="mat-card-icons">edit</mat-icon>
                                                                        </button>
                                                                    </mat-chip>
                                                                    <mat-chip class="chip-style" (mouseover)="editRaneIncrementVal[i] = true"
                                                                        *ngIf="offering?.incremental &&!isChipEditable[i] &&!offering.isNewlyAddedRef " color="#452D7C" selected>
                                                                        <div class="rangeIncrementUnit" [class.tn-focus]='edit-point-edit'>Flex {{ offering?.rangeIncrementUnit
                                                                            }} Per
                                                                            Selection </div>
                                                                        <div> {{ offering?.rangeIncrementValue }}</div>
                                                                        <button tabindex="0" mat-icon-button
                                                                            *ngIf="offering?.incremental"
                                                                            (click)="rangeIncrementValue = offering.rangeIncrementValue; enabledChipToEdit(i,j); ">
                                                                            <mat-icon class="mat-card-icons">edit</mat-icon>
                                                                        </button>
                                                                    </mat-chip>
                                                                    <div class="chip-outer-box" *ngIf="offering?.incremental && isChipEditable[i]">
                                                                        <div class="timeframeOuter">
                                                                            <span class="timeframeSpan"> Timeframe </span>
                                                                            <button tabindex="0" mat-icon-button class="matIconStyle" (click)="closeHybrid(i,j)"
                                                                                style="margin-right:10px">
                                                                                <mat-icon>cancel</mat-icon>
                                                                            </button>
                                                                            <button tabindex="0" mat-icon-button class="matIconStyle"
                                                                                (click)="closeRangeIncrementValueMenu(offering, index); isChipEditable[i]=false;">
                                                                                <mat-icon style="color: #3865f8;">check_circle</mat-icon>
                                                                            </button>
                                                                        </div>
                                                                        <div>
                                                                            <mat-form-field appearance="fill" style="width: 100%;">
                                                                                <mat-select matNativeControl [formControl]="timeSpanValue" [(ngModel)]="offering.rangeIncrementUnit"
                                                                                    name="timeFrame">
                                                                                    <mat-option value="Days" selected="selected">Days</mat-option>
                                                                                    <mat-option value="Months">Months</mat-option>
                                                                                </mat-select>
                                                                            </mat-form-field>
                                                                            <mat-chip style="margin-top: -10px;" class="StyleChip">Core {{offering.rangeIncrementUnit}} per selection
                                                                                <input type="number" number-only class="inputSelection" [(ngModel)]="offering.coreUnitsPerSelection"
                                                                                    (keyup.backspace)="onMatChipKeyPressCore($event,offering.coreUnitsPerSelection,j,i)">
                                                                            </mat-chip>
                                                                            <mat-chip class="StyleChip">Flex {{offering.rangeIncrementUnit}} per selection
                                                                                <input type="number" class="inputSelection" number-only tabindex="0" required name="rangeIncrementVal"
                                                                                    id="rangeIncrementVal_{{i}}" [(ngModel)]="rangeIncrementValue" #rangeIncrementVal="ngModel"
                                                                                    (keyup.backspace)="onMatChipKeyPress($event,rangeIncrementValue,j,i)">
                                                                            </mat-chip>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="!offering?.hybridTempLiving">
                                                                    <mat-chip (mouseover)="editRaneIncrementVal[i] = true"
                                                                        *ngIf="offering?.incremental &&!isChipEditable[i] &&!offering.isNewlyAddedRef" color="#452D7C" selected>
                                                                        <div class="rangeIncrementUnit" [class.tn-focus]='edit-point-edit'>{{ offering?.rangeIncrementUnit }} Per
                                                                            Selection </div>
                                                                        <div> {{ offering?.rangeIncrementValue }}</div>
                                                                        <button tabindex="0" mat-icon-button class="edit-point" (focusout)="editRaneIncrementVal[i] = false"
                                                                            [ngStyle]="editRaneIncrementVal[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
                                                                            [ngClass]="{'edit-point-edit': editRaneIncrementVal[i]}"
                                                                            *ngIf="offering?.incremental"
                                                                            (click)="rangeIncrementValue = offering.rangeIncrementValue; enabledChipToEdit(i,j); ">
                                                                            <mat-icon class="mat-card-icons">edit</mat-icon>
                                                                        </button>
                                                                    </mat-chip>
                                                                    <mat-chip class="chip-form style-chip " *ngIf="offering?.incremental && isChipEditable[i]">
                                                                        <form style="display: flex;flex-flow: row wrap;align-items: center;">
                                                                            <mat-form-field style="width: 33%; font-size: 13px;" *ngIf="offering?.incremental">
                                                                                <mat-select [formControl]="timeSpanValue" [(ngModel)]="offering.rangeIncrementUnit" ngDefaultControl>
                                                                                    <mat-option *ngFor="let data of timespanValues" style="font-size: 15px;" [value]="data"
                                                                                        name="timeSpanValue" class="form-control">
                                                                                        {{ data }}
                                                                                    </mat-option>
                                                                                </mat-select>
                                                                            </mat-form-field>
                                                                            <mat-form-field [ngStyle]="offering?.incremental?{'width': '10%','margin-left':'3%'} : {'width': '20%'}">
                                                                                <input matInput number-only ngDefaultControl tabindex="0" number required name="rangeIncrementVal"
                                                                                    id="rangeIncrementVal_{{i}}" [(ngModel)]="rangeIncrementValue" #rangeIncrementVal="ngModel"
                                                                                    (keyup.backspace)="onMatChipKeyPress($event,rangeIncrementValue,i)">
                                                                            </mat-form-field>
                                                                            <button tabindex="0" mat-icon-button class="edit-point"
                                                                                (focusout)="editRaneIncrementVal[i] = false; isChipEditable[i]=false;"
                                                                                [ngStyle]="editRaneIncrementVal[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
                                                                                [ngClass]="{'edit-point-edit': editRaneIncrementVal[i]}"
                                                                                *ngIf="offering?.incremental"
                                                                                (click)="closeRangeIncrementValueMenu(offering, index); isChipEditable[i]=false;">
                                                                                <mat-icon class="mat-card-icons">edit</mat-icon>
                                                                            </button>
                                                                        </form>
                                                                    </mat-chip>
                                                                </div>
                                                                <mat-chip class="matchip-css style-chip " (mouseover) = "editPerPointCurrency[i] = true"  *ngIf="offering?.incrementalCurrency &&!offering.isNewlyAddedRef" color="#452D7C" selected><div class="dollarPerPointUnit" [class.tn-focus]='edit-point-edit'>Value Per Point </div><div class="dollar-text"> {{ offering?.perPointCurrency }}</div> <div class="left-margin-style dollar-text">{{offering?.rangeIncrementUnit}}</div>
                                                                    <button tabindex="0" mat-icon-button class="edit-point" (focusout) = "editPerPointCurrency[i] = false; "
                                                                        [ngStyle]= "editPerPointCurrency[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editPerPointCurrency[i]}"
                                                                        *ngIf = "offering?.incrementalCurrency"
                                                                        [matMenuTriggerFor]="perPointCurrencyMenu"
                                                                        (menuOpened)="openRangeIncrementValueMenu()"
                                                                        >
                                                                        <mat-icon>edit</mat-icon> 
                                                                    </button>
                                                                    <mat-menu #rangeIncrementValueMenu="matMenu" xPosition="before" overlapTrigger="true"
                                                                    (closed) = "$event == undefined && (rangeIncrementVal.dirty || rangeIncrementVal.touched) ? offering.rangeIncrementValue = rangeIncrementValue : null; closeRangeIncrementValueMenu(offering, index); rangeIncrementValue = null;editRaneIncrementVal[i] = false;">
                                                                        <input matInput number-only tabindex="0" ngDefaultControl number required placeholder="Enter Range Increment Value" name="rangeIncrementVal" id="rangeIncrementVal_{{i}}" [(ngModel)]="rangeIncrementValue" #rangeIncrementVal="ngModel" class="form-control input-point"
                                                                            (click)="$event.stopPropagation()">
                                                                    </mat-menu>
                                    
                                                                    <mat-menu #perPointCurrencyMenu="matMenu" xPosition="before" overlapTrigger="true"
                                                                        (closed) = "$event == undefined && (perPointCurrencyVal.dirty || perPointCurrencyVal.touched) ? offering.perPointCurrency = perPointCurrency : null; closePerPointCurrencyMenu(offering); perPointCurrency = null; editPerPointCurrency[i] = false;">
                                                                        <input matInput number-only tabindex="0" ngDefaultControl number required placeholder="Enter Per point Value" name="perPointCurrencyVal" id="perPointCurrencyVal_{{i}}" [(ngModel)]="perPointCurrency"  #perPointCurrencyVal="ngModel"  class="form-control input-point" 
                                                                            (click)="$event.stopPropagation();">
                                                                    </mat-menu>
                                                                </mat-chip>
                                                            </div>
                                                            
                                                            <div fxFlex="75" role="cell" class="offering-detail">
                                                                <div fxLayout="row" fxLayoutGap="15px">                                   
                                                                    <div fxFlex="50" role="cell" [attr.aria-label]="'offering Title: ' + offering.displayName" class="title-container">
                                                                        <mat-form-field appearance="outline">
                                                                            <mat-label>Title</mat-label>
                                                                            <input matInput name="titleVal" ngDefaultControl  [disabled]="offering?.suppress"
                                                                            [(ngModel)]="!offering.displayName ? 'N/A' : offering.displayName"  class="input_text" placeholder="Title" (focusin)="editProgramTitle(offering)"  (focusout)="editProgramTitle(offering)">
                                                                        </mat-form-field>
                                                                    </div>
                                                                    
                                                                    <div fxFlex="30" role="cell" [attr.aria-label]="'Total Points: ' + offering.points"  fxLayout = "row" fxLayoutGap="1em" *ngIf="!isMMUser"
                                                                    (mouseover) = "editPoint[i] = true;" (mouseout) = "editPoint[i] = false">
                                                                        <div fxFlex="100">
                                                                            <mat-form-field appearance="outline"  [ngStyle]= "!(pointVal.touched || pointVal.dirty) || offering.points ? {'color': '#424242'} : {'color': '#f00'} ">
                                                                                <mat-label>Points</mat-label>
                                                                                <div fxLayout = "row"  (focus) = "editPoint[i] = true; " fxLayoutAlign="start start"  tabindex="0">   
                                                                                    <span  fxLayoutAlign="start start" *ngIf="offering.singleSelectTier; else cashout">
                                                                                        <input fxFlex="80%" matInput name="offeringPoints" ngDefaultControl
                                                                                         class="input_text" value="Variables" readonly placeholder="Points" [disabled]="offering?.suppress">
                                                                                        <button *ngIf="!offering?.suppress" fxFlex="20%" tabindex="0"  [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}"   (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point form-button" 
                                                                                            (click) = "openPointsLogicModal(offering)">
                                                                                            <mat-icon  class="mat-card-icons">edit</mat-icon> 
                                                                                        </button>
                                                                                    </span>
                                                                                    <ng-template #cashout>
                                                                                        <span  fxLayoutAlign="start start">

                                                                                            <input fxFlex="80%" matInput name="offeringPoints" ngDefaultControl
                                                                                             class="input_text" readonly [value]="(offering.points || (offering.points ===0 && (!offering?.advCashOutV2 && !offering?.advCashOut) )) ? (offering.points === 'Guaranteed'? 'Core': offering.points): (offering?.advCashOutV2 || offering?.advCashOut) ? 'Logic':''" disabled="true" placeholder="Points">
                                                                                            
                                                                                            <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point  form-button" 
                                                                                                [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i]}"
                                                                                                *ngIf = "!offering?.advCashOutV2 && !offering?.advCashOut && !offering?.incremental && !offering?.incrementalCurrency && !offering?.multiplicity && !offering?.suppress || (offering?.multiplicity && editMultiplicity[i]) || (offering?.incremental && editIncremental[i])" [matMenuTriggerFor]="pointMenu" (menuOpened)="openPointMenu()">
                                                                                                <mat-icon   class="mat-card-icons">edit</mat-icon> 
                                                                                            </button>
                                                
                                                                                            <!-- Advance point logic for editing -->
                                                                                            <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point form-button" 
                                                                                                [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                                                                *ngIf = "(offering?.advCashOut || offering?.advCashOutV2) && !offering?.suppress" (click) = "openPointsLogicModal(offering)"
                                                                                >
                                                                                                <mat-icon   class="mat-card-icons">edit</mat-icon> 
                                                                                            </button>
                                                    
                                                                                                                        
                                                                                        </span>
            
                                                                                    </ng-template>
            
                                                                                    <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false; editMultiplicity[i] = false; editIncremental[i] = false;" mat-icon-button class="edit-point form-button" 
                                                                                    [ngStyle]= "editPoint[i] || (editMultiplicity[i] || editIncremental[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} " [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                                                    *ngIf = "((offering?.multiplicity && !editMultiplicity[i]) || (offering?.incremental && !editIncremental[i])) && !offering?.suppress" (click) = "editMultiplicity[i] = true;editIncremental[i] = true; editRangeMax(offering); openPointMenu()">
                                                                                        <mat-icon   class="mat-card-icons">edit</mat-icon> 
                                                                                    </button>
                                                                                    
                                                                                    <button fxFlex="20%" tabindex="0"  mat-icon-button class="edit-point form-button" (focusout) = "!editIncrementalCurrency[i] ? editPoint[i] = false: null;"
                                                                                        [ngStyle]= "(editPoint[i] || editIncrementalCurrency[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} "
                                                                                        [ngClass]="{'edit-point-edit': editIncrementalCurrency[i]}"
                                                                                        *ngIf = "((offering?.incrementalCurrency && !offering?.suppress))" 
                                                                                        (click) = "editIncrementalCurrency[i]=!editIncrementalCurrency[i]; editMaxSelection(offering, editIncrementalCurrency[i]); ">
                                                                                        <mat-icon   class="mat-card-icons">edit</mat-icon>
                                                                                    </button>
                                                                                    <mat-menu #pointMenu="matMenu" xPosition="before" overlapTrigger="true" 
                                                                                        (closed) = "$event == undefined && (pointVal.dirty || pointVal.touched) ? offering.points = points : null; closePointMenu(offering); points = null;editMultiplicity[i] = false;editIncremental[i] = false">
                                                                                        <button mat-menu-item *ngIf = "!(offering?.multiplicity || offering?.incremental || offering?.incrementalCurrency)" (click) = "offering.points = 'Guaranteed'">Core</button>
                                                                                        <input matInput ngDefaultControl number-only tabindex="0" number required placeholder="Enter Points" name="pointVal" id="pointVal_{{i}}" [(ngModel)]="points" #pointVal="ngModel" class="form-control input-point"
                                                                                        (click)="$event.stopPropagation()">
                                                                                    </mat-menu>
                                                                                </div>
                                                                            </mat-form-field>
                                                                        </div>
                                                                    </div>
                                                
                                                                    <div fxFlex="30" role="cell" [attr.aria-label]="'Total Points: ' + offering.points" *ngIf="isMMUser"
                                                                        (mouseover) = "editPoint[i] = true;" fxLayout = "row" fxLayoutGap="1em" (mouseout) = "editPoint[i] = false">
                                                                        <div fxFlex="auto">
                                                                            <mat-form-field appearance="outline"  [ngStyle]= "!(pointVal.touched || pointVal.dirty) || offering.points ? {'color': '#424242'} : {'color': '#f00'} ">
                                                                                <mat-label>Points</mat-label>
                                                                                <div fxLayout = "row"   (focus) = "editPoint[i] = true; " fxLayoutAlign="start start"  tabindex="0">   
                                                                                    <span  fxLayoutAlign="start start">

                                                                                        <input fxFlex="80%" matInput  name="offeringAmount" ngDefaultControl
                                                                                         readonly disabled="true" class="input_text" [value]="(offering?.amount === 'Guaranteed'? 'Core': offering.amount)" placeholder="Amount">
                                                                                        
                                                                                    </span>
                                                                                    <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point"
                                                                                        [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i]}"
                                                                                        *ngIf = "!isMMUser && !offering?.suppress && !offering?.advCashOut && !offering?.incremental && !offering?.incrementalCurrency && !offering?.multiplicity || (offering?.multiplicity && editMultiplicity[i]) || (offering?.incremental && editIncremental[i])" [matMenuTriggerFor]="pointMenu" (menuOpened)="openPointMenu()">
                                                                                        <mat-icon class="mat-card-icons">edit</mat-icon> 
                                                                                    </button>
                                        
                                                                                    <!-- Advance point logic for editing -->
                                                                                    <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                                                        [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                                                        *ngIf = "!isMMUser && (offering?.advCashOutV2 || offering?.advCashOut) && !offering?.suppress" (click) = "openPointsLogicModal(offering)">
                                                                                        <mat-icon class="mat-card-icons">edit</mat-icon> 
                                                                                    </button>
                                        
                                        
                                                                                    <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false; editMultiplicity[i] = false; editIncremental[i] = false;" mat-icon-button class="edit-point" 
                                                                                        [ngStyle]= "editPoint[i] || (editMultiplicity[i] || editIncremental[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} " [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                                                        *ngIf = "!isMMUser && !offering?.suppress && ((offering?.multiplicity && !editMultiplicity[i]) || (offering?.incremental && !editIncremental[i]))" (click) = "editMultiplicity[i] = true;editIncremental[i] = true; editRangeMax(offering); openPointMenu()">
                                                                                        <mat-icon class="mat-card-icons">edit</mat-icon> 
                                                                                    </button>
                                                                                    
                                                                                    <button fxFlex="20%" tabindex="0"  mat-icon-button class="edit-point" (focusout) = "!editIncrementalCurrency[i] ? editPoint[i] = false: null;"
                                                                                        [ngStyle]= "(editPoint[i] || editIncrementalCurrency[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} "
                                                                                        [ngClass]="{'edit-point-edit': editIncrementalCurrency[i]}"
                                                                                        *ngIf = "!isMMUser && (offering?.incrementalCurrency) && !offering?.suppress"                                                                                         (click) = "editIncrementalCurrency[i]=!editIncrementalCurrency[i]; editMaxSelection(offering, editIncrementalCurrency[i]); ">
                                                                                        <mat-icon  class="mat-card-icons">edit</mat-icon>
                                                                                    </button>
                                                                                    <mat-menu #pointMenu="matMenu" xPosition="before" overlapTrigger="true" 
                                                                                        (closed) = "$event == undefined && (pointVal.dirty || pointVal.touched) ? offering.points = points : null; closePointMenu(offering); points = null;editMultiplicity[i] = false;editIncremental[i] = false">
                                                                                        <button mat-menu-item *ngIf = "!isMMUser && !(offering?.multiplicity || offering?.incremental || offering?.incrementalCurrency)" (click) = "offering.points = 'Guaranteed'">Core</button>
                                                                                        <input matInput ngDefaultControl number-only tabindex="0" number required placeholder="Enter Points" name="pointVal" id="pointVal_{{i}}" [(ngModel)]="points" #pointVal="ngModel" class="form-control input-point"
                                                                                        (click)="$event.stopPropagation()">
                                                                                    </mat-menu>
                                                                                </div>
                                                                            </mat-form-field>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <!-- Choices and Max Selections -->
                                                                    <div fxFlex="30" role="cell" [attr.aria-label]="'Total Points: ' + offering.points"  fxLayout = "row" fxLayoutGap="1em"
                                                                    (mouseover) = "editPoint[i] = true;" (mouseout) = "editPoint[i] = false">
                                                                        <div fxFlex="80" *ngIf="offering.singleSelectTier && !isMMUser; else maxSelection">
                                                                            <mat-form-field appearance="outline" >
                                                                                <mat-label># Choices</mat-label>
                                                                                <div fxLayout = "row" class="select-edit-tier" (focus) = "editPoint[i] = true; " fxLayoutAlign="start start"  tabindex="0">
                                                                                    <span fxLayoutAlign="start start" >
                                                                                        <input fxFlex="80%" type="number" readonly number-only number  matInput  tabindex="0" name="tierChoices" *ngIf="offering?.singleSelectTier" ngDefaultControl
                                                                                        class="input_text" [value]="offering?.tierValues?.length ? offering?.tierValues?.length : 0" readonly placeholder="Choices">
                                                                                        <button *ngIf="!offering?.suppress" fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point form-button" 
                                                                                        [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}"  (click)="onChoiceIconClick(i)">
                                                                                        <mat-icon>remove_red_eye</mat-icon> 
                                                                                        </button>
                                                                                        <div class="selected-tier" *ngIf="visibleIndex === i">
                                                                                            <mat-card>
                                                                                                <div *ngFor="let tierValue of offering.tierValues; let i=index">
                                                                                                    <div>{{tierValue.value}}<span class="tier-value">{{tierValue.displayValue}}</span></div>
                                                                                                    </div>
                                                                                                <div class="select-edit-option" *ngIf="!offering?.suppress" (click) = "openPointsLogicModal(offering)">Edit Option <span class="arrow-right-icon"><mat-icon>keyboard_arrow_right</mat-icon></span></div>
                                                                                            </mat-card>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            </mat-form-field>
                                                                        </div>
                                                                        <ng-template #maxSelection>
                                                                            <div fxFlex="80%" *ngIf = "offering?.multiplicity || offering?.incremental || offering?.advCashOutV2 || offering?.advCashOut || offering?.incrementalCurrency">
                                                                                <mat-form-field appearance="outline" >
                                                                                    <mat-label>Max Selections</mat-label>
                                                                                    <div fxLayout = "row"  fxLayoutAlign="start start"  tabindex="0" [ngStyle]= "offering?.incremental ? offering.maxSelection == 0 ? {'color': '#f00'} : {'color': '#424242'} : offering?.incrementalCurrency ? (offering?.incrementalCurrency && (offering?.rangeMax / offering?.rangeIncrementValue === 0)) ? {'color': '#f00'} : {'color': '#424242'} : (!offering.maxSelection || offering.maxSelection == 0) ? {'color': '#f00'} : {'color': '#424242'}">   
                                                                                        <span  fxLayoutAlign="start start">
                                                                                            <!--incrementalCurrency Input-->
                                                                                            <input fxFlex="80%" aria-label="test1"  matInput *ngIf = "!(editMultiplicity[i] || editIncremental[i] || editIncrementalCurrency[i])" name="maxSelection" ngDefaultControl
                                                                                            class="input_text" readonly disabled="true" [value]="(offering?.maxSelection || offering?.maxSelection ===0) ? offering?.maxSelection : offering?.incremental || offering?.incrementalCurrency ? (offering?.rangeMax / offering?.rangeIncrementValue === 0 || offering?.rangeIncrementValue === '' || offering?.rangeIncrementValue === '0' ? '' : offering?.rangeMax / offering?.rangeIncrementValue) : (offering?.advCashOutV2 || offering?.advCashOut) ? 'N/A':''" placeholder="Max Selections">
                                                                                           
                                                                                            <!--Editable Input-->
                                                                                            <input  fxFlex="80%" type="number" ngDefaultControl matInput number-only number tabindex="0" name="maxSelection" id="maxSelection_{{i}}" #maxSelection = "ngModel"
                                                                                            [(ngModel)]="offering.maxSelection" *ngIf="editMultiplicity[i] || editIncremental[i] || editIncrementalCurrency[i]" (focusout) = "editMultiplicity[i] = false;editIncremental[i] = false; editPoint[i] = false; editIncrementalCurrency[i] = false; closePointMenu(offering); offering?.incrementalCurrency? editMaxSelection(offering, editIncrementalCurrency[i]): null">
                                                                                        </span>
                                                                                    </div>
                                                                                </mat-form-field>
                                                                            </div>
                                                                        </ng-template>
                                                                    </div>
    
                                                                </div>
                                                               
                                                            </div>
            
                                                        </div>
                                                        <br>
                                                        <div fxLayout="row" fxLayoutAlign.xs="center center" role="row" fxLayoutGap="5px"> 
                                                            <div fxFlex="100" class="description-container"> <!--(mouseover)="editDesc[i] = true" (mouseout)="editDesc[i] = false"-->
                                                                <mat-form-field class="description-form-field" appearance="outline">
                                                                    <mat-label>Description</mat-label>
                                                                    <textarea matInput ngDefaultControl tabindex="0" fxflex="80%" name="descVal" id="descVal_{{i}}"  #descVal="ngModel" 
                                                                     [(ngModel)]="offering.description" class="input_text" placeholder="Description" [class.tn-focus]='edit-point-edit'
                                                                     [disabled]="offering?.suppress" (focusin)="editProgramDescription(offering)" (focusout)="editProgramDescription(offering)"></textarea>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                        <br>
                                                        <!-- Benefit Display logic start -->
                                                        <div fxLayout="row" fxLayoutAlign.xs="center center" role="row"
                                                        fxLayoutGap="5px">
                                                            <div fxFlex="100" *ngIf="!offering.isNewlyAddedRef" class="benefit-logic"
                                                                (focus)="editDisplayLogic[i] = true"
                                                                (focusout)="editDisplayLogic[i] = false"
                                                                (mouseover)="editDisplayLogic[i] = true"
                                                                (mouseout)="editDisplayLogic[i] = false" fxLayoutGap="4px"
                                                                [ngStyle]="offering?.suppress ? {'pointer-events': 'none'} : ''">
                                                                <!-- benefit.exclusions?.length > 0 || benefit.prerequisites?.length > 0 ||  -->
                                                                <div fxLayoutAlign="start start" class="benefit-display-logic-header">
                                                                    <img src="../../../../../../assets/images/account_tree.svg"
                                                                        alt="Icon here" role="figure">
                                                                            <h3 fxFlex="60%" class="edit-benefit-logic-title"> Benefit Display Logic
                                                                                <button *ngIf="!offering?.suppress" (click)="openEditBenefitDialog(offering)"
                                                                                    class="edit-display-logic"><mat-icon>edit</mat-icon>
                                                                                    <span>Edit</span></button>
                                                                            </h3>
                                                                </div>
                                                                <div fxLayoutGap="14px" class="scopes benefit-display-logic-header">
                                                                    <mat-tab-group disableRipple="true"mat-align-tabs="start" mat-stretch-tabs>
                                                                        <mat-tab label="Restrictions">
                                                                            <app-benefit-restrictions-rules-tasks [benefit]="offering"
                                                                                [type]="'restrictions'"></app-benefit-restrictions-rules-tasks>
                                                                        </mat-tab>
                                                                        <mat-tab label="Rules">
                                                                            <app-benefit-restrictions-rules-tasks [benefit]="offering"
                                                                                [type]="'rules'"></app-benefit-restrictions-rules-tasks>
                                                                        </mat-tab>
                                                                        <mat-tab label="Tasks">
                                                                            <app-benefit-restrictions-rules-tasks [benefit]="offering"
                                                                                [type]="'tasks'"></app-benefit-restrictions-rules-tasks>
                                                                        </mat-tab>
                                                                    </mat-tab-group>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Benefit Display logic end -->
                                                        <br>
                                                        <div fxLayout="row" fxLayoutAlign.xs="end center" role="row" fxLayoutGap="5px">
                                                            <mat-checkbox fxFlex="30%" class="offering-checkboxes"  fxLayoutAlign="end end" ngDefaultControl
                                                            (click)="openConsultantModal(offering)"  
                                                            [(ngModel)] = "offering.consultantBenefitInfo && offering.consultantBenefitInfo.consultant " 
                                                            *ngIf = "!offering?.advCashOutV2 && !offering?.advCashOut && !offering?.incremental && !offering?.hybridTempLiving && !offering?.multiplicity && !(offering?.points === 'Guaranteed')"
                                                            [disabled] ="offering?.suppress">
                                                            <span>Consultant-Only Benefit</span></mat-checkbox>
                                                            <mat-checkbox fxFlex="40%"  class="offering-checkboxes" ngDefaultControl
                                                                [(ngModel)]="offering.isDEIEnabled" 
                                                                (change)="editSustanabilityDEI(offering, 'isDEIEnabled')"
                                                                *ngIf="offering.hasOwnProperty('isDEIEnabled')" fxLayoutAlign="end end"
                                                                [disabled] ="offering?.suppress">
                                                                <span >Diversity, Equity & Inclusion</span></mat-checkbox>
                                                            <mat-checkbox fxFlex="30%" class="offering-checkboxes" ngDefaultControl
                                                                [(ngModel)]="offering.isSustainabilityEnabled" 
                                                                (change)="editSustanabilityDEI(offering, 'isSustainabilityEnabled')"
                                                                *ngIf="offering.hasOwnProperty('isSustainabilityEnabled')" fxLayoutAlign="end end"
                                                                [disabled] ="offering?.suppress">
                                                                <span>Sustainability</span></mat-checkbox>
                                                        </div>
                                                    </mat-card-content>
                                                </mat-card>
                                            </div>
                                            <!-- icons operations section -->
                                            <div fxLayout="row" [ngClass]="offering.clonedCopy ? 'highlight-icons':null">
                                                <div fxFlex="100%">
                                                    <button class="isNewelyAdded1" *ngIf="!offering.clonedCopy && offering.isNewlyAddedRef" mat-button matSuffix
                                                        aria-label="Delete Benefit" role="button" (click)="deleteisNewlyAdded(offering)">
                                                        <mat-icon>delete_outline</mat-icon>
                                                    </button>
                                                    <mat-card  class="icons-card" *ngIf="!offering.isNewlyAddedRef"
                                                        style="height: 65% !important;width:25% !important ;margin-top: 0% !important;">
                                                        <button *ngIf="!offering.clonedCopy && !offering.isNewlyAddedRef" mat-button matSuffix mat-icon-button
                                                            aria-label="Delete Benefit"
                                                            [ngStyle]="!offering.clonedCopy ? {'padding-right': '14px'} : null" role="button"
                                                            (click)="deleteBenefit(offering)">
                                                            <mat-icon>{{ offering?.suppress ? 'restore_icon': 'delete' }}</mat-icon>
                                                        </button>
                                                        <button mat-button matSuffix mat-icon-button aria-label="Delete Benefit" role="button"
                                                            *ngIf="offering.clonedCopy"
                                                            [ngStyle]="offering.clonedCopy ? {'padding-right': '4px'} : {'padding-left':'6px'}"
                                                            (click)="discardBenefit(offering,j, i)">
                                                            <mat-icon>clear</mat-icon>
                                                        </button>
                                                        <button *ngIf="!((!(!offering.clonedCopy && !offering.suppress))) && !offering.isNewlyAddedRef" 
                                                            [disabled] = "offering.parentClonedCopy" mat-button matSuffix mat-icon-button  aria-label="Delete Benefit" [ngStyle]="!offering.clonedCopy ? {'padding-right': '14px'} : null" role="button" (click)="cloneBenefit(offering, focusable)">
                                                            <mat-icon *ngIf="!offering.clonedCopy">content_copy</mat-icon>
                                                        </button>
                                                        <button *ngIf="!offering.isNewlyAddedRef && !offering.suppress"  [disabled] = "offering.parentClonedCopy" mat-button matSuffix mat-icon-button  aria-label="Delete Benefit" [ngStyle]="!offering.clonedCopy ? {'padding-right': '14px'} : null" role="button" (click)="benefitSetting(offering, index)">
                                                            <mat-icon *ngIf="!offering.clonedCopy ">settings</mat-icon>
                                                        </button>
                                                        <button *ngIf="offering.clonedCopy" mat-button matSuffix mat-icon-button  aria-label="Save offering" role="button" style="padding-right: 4px;" (click)="cloneBenefit(offering)" #focusable>
                                                            <mat-icon>done</mat-icon>
                                                        </button>
                                                        <button tabindex="0" mat-icon-button (click) = "sortCategories()"
                                                            *ngIf = "!offering.suppress"
                                                            [matMenuTriggerFor]="categoryMenu"
                                                            ><mat-icon class="category-change">more_vert</mat-icon>
                                                        </button>
                                                        <mat-menu #categoryMenu="matMenu" xPosition="before" overlapTrigger="true" class="categoryMenu">
                                                            <div>
                                                                <button tabindex="0" mat-icon-button disabled class = 'default-category'><div>Send to</div> 
                                                                <div fxLayout="row" fxLayoutAlign=" center" >
                                                                    <p>{{offering.category}}</p>
                                                                    <mat-chip class="matchip-css style-chip current-chip " color="#452D7C" selected>Current </mat-chip>
                                                                </div>
                                                                </button>
                                                            </div>    
                                                            <div *ngFor = "let category of categoriesList">
                                                                <button tabindex="0" mat-icon-button (click) = "changeCategory(offering, category)"
                                                                *ngIf = "offering.category != category">{{category}}</button>
                                                            </div>
                                                        </mat-menu>
                                                    </mat-card> 
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    <ng-template #noProgram>
                        <div fxFlex="100%" fxLayout="row" class="no_selected_program_offerings_container">
                            <div class="no-pgm-select-container">
                                <div class="no-pgm-select"> <p>No Program Selected</p> </div>
                                <div class="no-pgm-select-box"> <p>Select a program from the list to customise its properties</p></div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<app-footer></app-footer>
