<div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="10px" fxLayoutGap.xs="0em">Icon Library
    <span class="close-icon" (click)="onClose()" title="close">
        <mat-icon>close</mat-icon>
    </span>
</div>
<div>
    <mat-form-field class="table-search" appearance="outline">
        <span matPrefix class="lg-only" role="none">
            <mat-icon>search</mat-icon>
        </span>
        <input matInput tabindex="0" placeholder="Search icons..." aria-label="Search icons..."
            [(ngModel)]="searchValue" role="text" (keyup)="filter(searchValue)">
    </mat-form-field>
</div>
<hr>

<div class="icon-list-background" id="iconDiv" fxLayoutAlign="space-between none" fxLayout="row wrap"
    fxLayoutGap="32px">
    <div class="m-5" fxFlex=20 *ngFor="let img of (searchValue ? filterIconList : allIconList)">
        <label>
            <input type="radio" name="image-checkbox" [value]="icon"
                (change)="OnChangeImg(img.iconName, img.iconDisplayName)">
            <img class="img_icon" src={{img.iconName}}>
        </label>
        <p class="image-title">{{img.iconDisplayName}}</p>
    </div>
</div>

<div fxLayoutAlign="end end" class="mt-10">
    <button class="cancel-btn mr-5" mat-button (click)="onCancel()">cancel</button>
    <button class="save-btn" [disabled]="!isIconSelected" (click)="save()" mat-button>save</button>
</div>