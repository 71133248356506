<div class="dialog-container"  role="region">
    <!-- Title of pop-up -->
    <div style="padding: 0 10px !important;" mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px" fxLayoutGap.xs="0em" role="heading">
        <h2 class="divisions-title">Consultant-Only Benefit</h2>
        <a class="close-icon" (click)="cancel()" title="close" role="button" aria-label="Close Program Modal" title="Close Program Modal">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <!-- Title of pop-up end here -->
  
          <!--dialog-title start  -->
        <div class="dialog-title-container">
          <div class="dialog-wrapper">
            <div class="dialog-title-card">
              <mat-card class="mat-elevation-z8">{{data.benefit.displayName}}</mat-card>
            </div>
            <div class="dialog-title-desc">
              <p>{{data.benefit.description}}</p>
            </div>
          </div>
        </div>
        <!--dialog-title end here  -->
        
        <div style="padding: 0 18px !important;" >
  
          <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedOption">
            <mat-label class="option-label">Choose Qualifying Question</mat-label>
            <mat-radio-button style="display: block;" [value]="1">The transferee meets the minimum requirements for this product</mat-radio-button>
            <mat-radio-button style="display: block; padding-top: 18px" [value]="2">The client has approved the transferee for this product </mat-radio-button>
          </mat-radio-group>
          <mat-checkbox class="activateByDefault-checkbox" (change)="checkUncheckActivatedByDefault($event.checked)" [(ngModel)]="isChecked" [checked] ="isChecked">Activated by default</mat-checkbox>
        </div>
    
    <!-- pop up action buttons start -->
    <mat-dialog-actions style="padding: 20px 18px 0 !important"  fxLayout="row" fxLayoutAlign="end end">
        <button mat-button class="cancel-btn text-button rest-btn" (click)="cancel()">CANCEL</button>
        <button [disabled]="!selectedOption" mat-button class="contained-button save-btn" (click)='save()'>Save</button>
    </mat-dialog-actions>
    <!-- pop up action buttons end -->
  
  </div>