import { Injectable } from '@angular/core';
import { Observable,Subject, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseClientService } from './base-client.service';
import { urlType } from '../models/urlType';
import { NgxSpinnerService } from "ngx-spinner";

export interface Benefit {
  singleSelectTier?: any;
  tierValues?: any;
  reference: string;
  productSpecificationReference: string;
  category: string;
  consultantBenefitInfo?: { consultant: boolean, qualifyingInput?: number , activatedByDefault?: boolean};
  displayName: string;
  description: string;
  points: number;
  amount?: number;
  suppress?: boolean;
  multiplicity?: boolean;
  incremental?: boolean;
  maxSelection: number;
  prerequisites?: Array<any>;
  exclusions?: Array<any>;
  inclusions?: Array<any>;
  prerequisiteRule?: Array<any>;
  excludeRule?: Array<any>;
  includeRule?: Array<any>;
  scopes?: any;
  rangeMax?: number;
  rangeMin?: number;
  rangeIncrementValue?: number;
  rangeIncrementUnit?: string;
  incrementalTimespan?:boolean;
  tierConfigs?: Array<any>;
  cashOut?: boolean;
  advCashOut?: boolean;
  advCashOutV2?: boolean;
  pointsPerSelection?: number;
  icon?: string;
  perPointCurrency?: number;
  incrementalCurrency?: boolean;
  isCloned?:boolean;
  clonedCopy?:boolean;
  clonedFrom?: string;
  parentClonedCopy ?: boolean
  editTitle?: boolean; //flag for ui only not for api
  editDescription?: boolean; //flag for ui only not for api
  isNewlyAdded ?: boolean; // flag to determine new offerings
  isNewlyAddedRef? :boolean;
  hybridTempLiving?:boolean;
  preMaxSelection?: any; // temp variable
  preRangeIncrementValue?: any; //temp variable
  preRangeIncrementUnit?: any; //temp variable
  preCoreUnitsPerSelection?: any; //temp variable
  preRangeMax?: any; //temp
  preRangeMin?: any; //temp
  coreUnitsPerSelection?:number;
  productSubDetails :{
    internationalProduct?: {
      productName?: string;
      subProductName?: string;
      requiresRevision?:boolean;
    },
    usDomesticProduct?: {
      productName?:string;
      subProductName?: string;
      requiresRevision?:boolean;
 
    }
  },
  isDEIEnabled?: boolean;
  isSustainabilityEnabled?: boolean;
  tasks:Array<string>;
  currencyCode?: string;
}

@Injectable({
 providedIn: 'root'
})
export class ProdTemplatesService {
  public observable:boolean = false;
  public isPublishedSuccessfully = null;

  /**
   * base constructor
   * @param baseClientService baseclient service
   * @param spinner spinner modal service
   */

    constructor(private http:HttpClient, private readonly baseClientService: BaseClientService,
      private spinner: NgxSpinnerService) { }



    changeInTemplates() {
      return this.observable;
    }

    resetTemplateStatus(){
      this.observable = false;
    }

    onChangeInTemplates(){
      this.observable = true;
    }

    getPublishedTemplateStatus(){
      return this.isPublishedSuccessfully;
    }

    setPublishedTemplateStatus(flag:string){
      this.isPublishedSuccessfully = flag;
    }

     /**
     * Get all product templates total count
   */
    getAllTemplatesCount() {
      return this.baseClientService.get('count/templates','').pipe(   
        map((response) =>{
         return response.body;  
        })
      ); 
    }

    /**
     * Get all product templates list for specific or all types
     * @param prams string it contains skip , type and limit
   */
    getAllTemplates(prams:String){
        return this.baseClientService.get(`templates?${prams}`,'').pipe(   
          map((response) =>{
           return response;  
          })
        ); 
    }

    /**
     * Get specific product templates details by id
     * @param prams string it contains template Id
   */
    getTemplateById (prams:String) {
      return this.baseClientService.get(`templates${prams}`,'').pipe(   
        map((response) =>{
         return response.body;  
        })
      ); 
    }

    /**
     * Delete product templates which are in Draft or Archived statuses
     * @param id it is template Id
   */
    deleteTemplateById (id) {
      return this.baseClientService.delete(`templates/${id}`,'').pipe(   
        map((response) =>{
         return response.body;  
        })
      ); 
    }

    /**
     * Move product templates which are Active status
     * @param id it is template Id
   */
    moveTemplateToArchive (id) {
      return this.baseClientService.post(`templates/${id}/archive`,'').pipe(   
        map((response) =>{
         return response.body;  
        })
      ); 
    }



    /**
     * To create a new template
     * @param data Objecte it contains template information with added offerings details
   */

    createNewTemplate (data) {
      return this.baseClientService.post(`templates`,data).pipe(   
        map((response) =>{
         return response.body;  
        })
      ); 
    }

    /**
     * To Publish a template
     * @param templateData Template data
   */

    ActivateTemplate (templateData) {
      return this.baseClientService.post(`templates/activate`,templateData).pipe(   
        map((response) =>{
         return response.body;  
        })
      ); 
    }

    /**
     * To update Active template
     * @param templateId Template id 
     * @param templateData Template data
   */

    SaveActivatedTemplate (templateId,templateData) {
      return this.baseClientService.post(`templates/${templateId}/save`,templateData).pipe(   
        map((response) =>{
          return response.body;  
        })
      ); 
    }

    /**
     * To update Draft or Active template
     * @param templateId Template id 
     * @param templateData Template data
   */

    updateDraftTemplate(templateId,templateData){
      return this.baseClientService.put(`templates/${templateId}`,templateData).pipe(   
        map((response) =>{
          return response.body;  
        })
      ); 
    }

     /**
     * To chane the status of Archived template
     * @param templateId Template id 
     * @param templateData Template data
   */

    moveTemplateToDraft(templateId,templateData){
      return this.baseClientService.patch(`templates/${templateId}`,templateData).pipe(   
        map((response) =>{
          return response.body;  
        })
      ); 
    }



    showSpinner (name?:string , data?) {
      this.spinner.show(name,data);
    }

    hideSpinner (name?:string) {
      this.spinner.hide(name);
    }

    /**
     * Get specific template's programs list by id
     * @param tmpId string it contains template Id
    */
    getTemplateProgramsById (tmpId:String) {
      return this.baseClientService.get(`templates${tmpId}`,'').pipe(   
        map((response) =>{
         return response.body;  
        })
      ); 
    }

    /**
     * Get specific template's programs list by id
     * @param tmpId string it contains template Id
    */
    getOfferingsById (programId:String) {
      return this.baseClientService.getInt(`/v1/admin/program/benefit/standardtemplatedelta?programId=${programId}`,'', urlType.apiInt).pipe(   
        map((response) =>{
         return response.body;  
        })
      ); 
    }

    getNewLegacy(): Observable<any> {
      return this.baseClientService
      .getInt<any>(`/v1/admin/value-list?key=LegacyProductSubProduct`, '', urlType.apiInt)
      .pipe(   
        map((response) =>{
         return response.body;  
        })
      ); 
    }

    removeIconFromReq(data: any) {
      if (data.hasOwnProperty('benefits')) {
        data.benefits.forEach(ele => {
          ele.hasOwnProperty('icon') ? delete ele.icon : null;
        });
      }
      return data;
    }

    updateBenefitForProgram(data: any, programId: string) {
      data = this.removeIconFromReq(data);
      const url = `/v1/admin/program/${programId}/push-delta`;
      return this.baseClientService
        .putInt<any>(url, data, '' ,urlType.apiInt)
        .pipe(
          map((response) =>{
            return response.body;  
          })
        );
    }
}