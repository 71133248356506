<form *ngIf="offeringInfoForm" [formGroup]="offeringInfoForm">
<div class="row" class="header-toolbar">
    <div fxFlex="5%">
        <a class="back-btn-icon" aria-label="Example icon-button with menu icon" (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
        </a>
    </div>
    <div fxFlex="85%">
        <div fxFlex="100%">
            <span class="offering-header">{{offeringAction === 'create' ? 'Create Offering' : offeringAction === 'duplicate' ? 'Duplicate Offering : ' : 'Edit Offering :'}}
            </span>
            <span class="offering-name-span">&nbsp;{{offeringName}}</span>
        </div>
    </div>
    <div fxFlex="5%" fxFlexOffset="5%">
        <button (click)="openDeleteModel()" *ngIf="dispalyDelIcon && !offeringInfoForm?.disabled" mat-icon-button class="toolbar-right-icon" aria-label="Example icon-button with share icon">
            <mat-icon class="">delete</mat-icon>
        </button>
    </div>
</div>
<div class="offering_content">
    <div fxLayout="row" fxLayoutAlign="start">
        <div fxFlex="100%">
            <mat-card class="offering-card">
                <div fxFlex="35%">
                    <div class="preview_section">
                        <div fxLayout="row" class="image_row">
                            <div fxFlex="20">
                                <div>
                                    <span class="preview_span">Preview :</span>
                                </div>
                            </div>
                        
                            <div fxFlex="70" >
                                <div class="preview_image_background" fxLayoutAlign="center center">
                                    <img src={{iconSrc}} />
                                </div>
                            </div>
                            <!--edit icon-->
                            <div fxLayoutAlign="end start" *ngIf="checkForBBRole()">
                                <button class="edit-icon" (click)="iconUploadPopup()">
                                    <mat-icon>edit_icon</mat-icon>
                                </button>
                            </div>
                        </div>
                        
                        <div fxLayout="row wrap" class="name_row">
                            <div fxFlex="100">
                                <div fxFlex="20">
                                    <div>
                                        <span class="preview_span">Name :</span>
                                    </div>
                                </div>
                                <div fxFlex="70" >
                                    <div>
                                        <span class="preview_span_val">{{iconSrcName}}</span>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="100">
                                <div fxFlex="20">
                                    <div>
                                        <span class="preview_span">Size :</span>
                                    </div>
                                </div>
                                <div fxFlex="70" >
                                    <div>
                                        <span class="preview_span_val">35kb</span>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="100">
                                <div fxFlex="20">
                                    <div>
                                        <span class="preview_span">Format :</span>
                                    </div>
                                </div>
                                <div fxFlex="70" >
                                    <div>
                                        <span class="preview_span_val">.png</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" class="icons_row">
                            <div fxFlex="100">
                                <div fxLayoutAlign="end">
                                    <div fxFlex="15%" fxLayoutAlign="end">
                                        <button  mat-icon-button class="toolbar-right-icon">
                                            <mat-icon class="">remove_circle</mat-icon>
                                        </button>
                                    </div>
                                    <div fxFlex="15%" fxLayoutAlign="end">
                                        <button  mat-icon-button class="toolbar-right-icon">
                                            <img class="offering-logo-img" src="../assets/icons/material-file-download.svg" alt="" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div fxFlex="65%" *ngIf="offeringInfoForm && offeringInfoForm.get('offeringDetail')" >
                    <div  fxLayout="row wrap" class="offering-details-section" formGroupName="offeringDetail">
                            <div fxFlex="100%">
                                <mat-form-field appearance="outline"  #prodField>
                                    <mat-label *ngIf="prodField._control.focused || offeringInfoForm.get('offeringDetail').get('productReference').value">Product</mat-label>
                                    <mat-label *ngIf="!prodField._control.focused && !offeringInfoForm.get('offeringDetail').get('productReference').value">Select Product</mat-label>
                                    <input type="text" matInput (keyup)="getAllProducts($event)" formControlName="productReference" placeholder=""  [matAutocomplete]="auto">
                                     <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getProductDetails($event)" (closed)="isProductValid($event)" [displayWith]="productDisplayFn">
                                        <mat-option *ngIf="!productsList || !productsList.length" disabled>No product available</mat-option>
                                        <mat-option *ngFor="let product of productsList" [value]="product">
                                            <span>{{product.displayName}}</span><br>
                                            <span class="prod-description">{{product.description}}</span>
                                    </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div fxFlex="100%" class="input_row  offering_name_row">
                                <mat-form-field appearance="outline" class="mat-form-field-textarea">
                                    <mat-label>Offering Name</mat-label>
                                    <textarea matInput maxlength="75" formControlName="displayName" (keydown)="preventLineBreak($event)" class="input_text" placeholder="Offering Name"></textarea>
                                </mat-form-field>
                            </div>
                            <div fxFlex="100%" class="input_row">
                                <mat-form-field appearance="outline" class="mat-form-field-textarea">
                                    <mat-label>Description</mat-label>
                                    <textarea matInput rows="10" class="input_text"formControlName="description" placeholder="Description"></textarea>
                                </mat-form-field>
                            </div>
                            <div fxFlex="100%" class="input_row" fxLayoutGap="1.25em">
                                <div fxFlex="25">
                                    <div >
                                        <mat-form-field appearance="outline" class="">
                                            <mat-label>Category</mat-label>
                                            <mat-select [(ngModel)]="offeringCategory" [ngModelOptions]="{standalone: true}" [multiple]="isMultipleCategoryAllwd" [disabled]="offeringInfoForm?.disabled" (openedChange)="matFocusedEvent($event,'category')" (selectionChange)="productCategoryChange()"  disableOptionCentering="true">
                                              <mat-select-trigger *ngIf="isMultipleCategoryAllwd">
                                                {{offeringCategory ? offeringCategory[0] : ''}}
                                                <span *ngIf="offeringCategory?.length > 1" class="additional-selection">
                                                  +{{offeringCategory.length - 1}} {{offeringCategory?.length === 2 ? 'other' : 'others'}}
                                                </span>
                                              </mat-select-trigger>
                                              <mat-option *ngFor="let category of getCategoryValues()" [value]="category.displayName">{{category.displayName}}</mat-option>
                                            </mat-select>
                                            <mat-icon *ngIf="!matSelectFocused.category" matSuffix>keyboard_arrow_down</mat-icon>
                                            <mat-icon *ngIf="matSelectFocused.category" matSuffix>keyboard_arrow_up</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="22">
                                    <div>
                                        <mat-form-field appearance="outline" class="">
                                            <mat-label>Type</mat-label>
                                            <mat-select formControlName="type"  (ngModelChange)="offeringTypeChange()" (openedChange)="matFocusedEvent($event,'type')" (selectionChange)="incrementalChanges()" >
                                              <mat-option *ngFor="let type of offeringTypeList" [value]="type.value">{{type.displayValue}}</mat-option>
                                            </mat-select>
                                            <mat-icon *ngIf="!matSelectFocused.type" matSuffix>keyboard_arrow_down</mat-icon>
                                            <mat-icon *ngIf="matSelectFocused.type" matSuffix>keyboard_arrow_up</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="40" *ngIf="(offeringInfoForm.get('offeringDetail').get('type') && offeringInfoForm.get('offeringDetail').get('type').value  === 'Variable')">
                                    <div fxLayout ="row" class="min_max_field_div">
                                        <span class="material-icons mat-info-icon">info</span>
                                        <span class="variable-text">Variable Offerings require the client-specific choices and points to be defined by the program administrator for their clients</span>
                                    </div>
                                </div>
                                <div fxFlex="12" *ngIf="(offeringInfoForm.get('offeringDetail').get('type') && offeringInfoForm.get('offeringDetail').get('type').value  === 'Multiplicity')">
                                    <div class="min_max_field_div">
                                        <mat-form-field appearance="outline" class="min_max_field_div">
                                            <mat-label>Min</mat-label>
                                            <input matInput type="number"
                                                autocomplete="none"
                                                [min]="0"
                                                [max]="offeringInfoForm.get('offeringDetail').get('multiMax').value"
                                                class="input_text min_max_fields"
                                                (blur)="minMaxValueChange()"
                                                (keydown)="minMaxValidations($event,false,0,offeringInfoForm.get('offeringDetail').get('multiMax').value)"
                                                formControlName="multiMin"
                                                placeholder="Min">
                                            <mat-icon matSuffix (click)="offeringInfoForm?.disabled || changeMinMaxValues('increment','min')">add</mat-icon>
                                            <mat-icon matPrefix (click)="offeringInfoForm?.disabled || changeMinMaxValues('decrement','min')">remove</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="12" *ngIf="(offeringInfoForm.get('offeringDetail').get('type') && offeringInfoForm.get('offeringDetail').get('type').value  === 'Multiplicity')">
                                    <div class="min_max_field_div">
                                        <mat-form-field appearance="outline" class="">
                                            <mat-label>Max</mat-label>
                                            <input matInput formControlName="multiMax"
                                              type="number"
                                              class="input_text min_max_fields" 
                                              (blur)="minMaxValueChange()"
                                              [min]="offeringInfoForm.get('offeringDetail').get('multiMin').value"
                                              (keydown)="minMaxValidations($event,false,offeringInfoForm.get('offeringDetail').get('multiMin').value,null)"
                                              placeholder="Max">
                                            <mat-icon matSuffix (click)="offeringInfoForm?.disabled || changeMinMaxValues('increment','max')">add</mat-icon>
                                            <mat-icon matPrefix (click)="offeringInfoForm?.disabled || changeMinMaxValues('decrement','max')">remove</mat-icon>
                                        </mat-form-field>
                                        
                                    </div>
                                </div>
                                <div fxFlex="25" class="singleMultiplicity"  *ngIf="(offeringInfoForm.get('offeringDetail').get('type') && offeringInfoForm.get('offeringDetail').get('type').value  === 'Multiplicity')">
                                    <div class="min_max_field_div">
                                        <!-- <mat-label>Single-select &nbsp;</mat-label> -->
                                        <mat-slide-toggle formControlName="singleSelectMultiplicity">Single Select</mat-slide-toggle>                                        
                                    </div>
                                </div>
                                
                            </div>
                    </div>
                    <!-- <div fxLayout="row wrap" class="offering-details-section">
                        <div fxFlex="100%" class="input_row">
                            <section fxLayoutAlign="start start">
                                <input class="incremental_checkbox" type="checkbox" id="increment" formControlName="isIncremental" (ngModelChange)="incrementalChanges()" />
                                <label for="increment" class="incremental_lbl">Incremental</label>
                              </section>
                        </div>
                    </div> -->
                    <div fxLayout="row wrap" class="offering-details-section" *ngIf="(offeringInfoForm.get('offeringDetail').get('type') && offeringInfoForm.get('offeringDetail').get('type').value  === 'Incremental')">
                            <div fxFlex="100%" class="input_row last_info_row" fxLayoutGap="1.25em"  formGroupName="incremental">
                                <div fxFlex="25">
                                    <div>
                                        <mat-form-field appearance="outline" class="">
                                            <mat-label>Increment Type</mat-label>
                                            <mat-select formControlName="type" (ngModelChange)="incrementTypesChange($event)" (openedChange)="matFocusedEvent($event,'incrementType')">
                                              <mat-option *ngFor="let increment of incrementTypes" [value]="increment.value">{{increment.displayValue}}</mat-option>
                                            </mat-select>
                                            <mat-icon *ngIf="!matSelectFocused.incrementType" matSuffix>keyboard_arrow_down</mat-icon>
                                            <mat-icon *ngIf="matSelectFocused.incrementType" matSuffix>keyboard_arrow_up</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="25" *ngIf="offeringInfoForm.get('incremental').get('type').value === 'Timespan'">
                                    <div>
                                        <mat-form-field appearance="outline" class="">
                                            <mat-label>Timeframe</mat-label>
                                            <mat-select formControlName="timeframe"  (openedChange)="matFocusedEvent($event,'timeframe')" disableOptionCentering="true">
                                              <mat-option *ngFor="let timeFrame of timeFrameTypes" [value]="timeFrame.value">{{timeFrame.displayValue}}</mat-option>
                                            </mat-select>
                                            <mat-icon *ngIf="!matSelectFocused.timeframe" matSuffix>keyboard_arrow_down</mat-icon>
                                            <mat-icon *ngIf="matSelectFocused.timeframe" matSuffix>keyboard_arrow_up</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="20">
                                    <div class="min_max_field_div">
                                        <mat-form-field appearance="outline">
                                            <mat-label *ngIf="offeringInfoForm.get('incremental').get('type').value !== 'Amount'">Increment Value</mat-label>
                                            <mat-label *ngIf="offeringInfoForm.get('incremental').get('type').value  === 'Amount'">Increment Value in USD</mat-label>
                                            <input matInput type="number" (keydown)="minMaxValidations($event,false,-1,null)"  formControlName="incrementValue" 
                                             maxlength="50" (blur)="incrementValueChange()"  class="input_text" placeholder="Increment Value">
                                          </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="20">
                                    <div class="min_max_field_div">
                                        <mat-form-field appearance="outline" class="">
                                            <mat-label>Max Increment</mat-label>
                                            <input matInput type="number"  (blur)="incrementValueChange()" (keydown)="minMaxValidations($event,false,0,null)" formControlName="maxIncrementValue"  maxlength="50" class="input_text min_max_fields" placeholder="Max">
                                            <mat-icon matSuffix (click)="offeringInfoForm?.disabled || offeringInfoForm.get('incremental').disabled || changeMinMaxValues('increment','increment')">add</mat-icon>
                                            <mat-icon matPrefix (click)="offeringInfoForm?.disabled || offeringInfoForm.get('incremental').disabled || changeMinMaxValues('decrement','increment')">remove</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--  Hybrid flex-->
                        <div fxLayout="row wrap" class="offering-details-section" *ngIf="(offeringInfoForm.get('offeringDetail').get('type') && offeringInfoForm.get('offeringDetail').get('type').value  === 'Hybrid')">
                            <div fxFlex="100%" class="input_row last_info_row" fxLayoutGap="1.25em"  formGroupName="incremental">
                                <div fxFlex="16">
                                    <div>
                                        <mat-form-field appearance="outline" class="">
                                            <mat-label>Increment Type</mat-label>
                                            <mat-select formControlName="type"  (openedChange)="matFocusedEvent($event,'hybridType')">
                                              <mat-option *ngFor="let increment of hybridType" [value]="increment.value">{{increment.displayValue}}</mat-option>
                                            </mat-select>
                                            <mat-icon *ngIf="!matSelectFocused.hybridType" matSuffix>keyboard_arrow_down</mat-icon>
                                            <mat-icon *ngIf="matSelectFocused.hybridType" matSuffix>keyboard_arrow_up</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="15" *ngIf="offeringInfoForm.get('incremental').get('type').value === 'Timespan'">
                                    <div>
                                        <mat-form-field appearance="outline" class="">
                                            <mat-label>Timeframe</mat-label>
                                            <mat-select formControlName="timeframe"  (openedChange)="matFocusedEvent($event,'timeframe')" disableOptionCentering="true">
                                              <mat-option *ngFor="let timeFrame of hybridTimeframeType" [value]="timeFrame.value">{{timeFrame.displayValue}}</mat-option>
                                            </mat-select>
                                            <mat-icon *ngIf="!matSelectFocused.timeframe" matSuffix>keyboard_arrow_down</mat-icon>
                                            <mat-icon *ngIf="matSelectFocused.timeframe" matSuffix>keyboard_arrow_up</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="10">
                                    <div class="min_max_field_div">
                                        <mat-form-field appearance="outline">
                                            <mat-label >Core Amount</mat-label>    
                                            <input matInput type="number" (keydown)="minMaxValidations($event,false,-1,null)"  formControlName="timeGuaranteed" 
                                             maxlength="50" (blur)="incrementValueChange()"  class="input_text" placeholder="Increment Value">
                                          </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="15">
                                    <div class="min_max_field_div">
                                        <mat-form-field appearance="outline">
                                            <mat-label *ngIf="offeringInfoForm.get('incremental').get('type').value !== 'Amount'">Flex Increment Amount</mat-label>
                                            <input matInput type="number" (keydown)="minMaxValidations($event,false,-1,null)"  formControlName="incrementValue" 
                                             maxlength="50" (blur)="incrementValueChange()"  class="input_text" placeholder="Increment Value">
                                          </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="20">
                                    <div class="min_max_field_div">
                                        <mat-form-field appearance="outline" class="">
                                            <mat-label>Max Flex Selections</mat-label>
                                            <input matInput type="number"  (blur)="incrementValueChange()" (keydown)="minMaxValidations($event,false,0,null)" formControlName="maxIncrementValue"  maxlength="50" class="input_text min_max_fields" placeholder="Max">
                                            <mat-icon matSuffix (click)="offeringInfoForm?.disabled || offeringInfoForm.get('incremental').disabled || changeMinMaxValues('increment','increment')">add</mat-icon>
                                            <mat-icon matPrefix (click)="offeringInfoForm?.disabled || offeringInfoForm.get('incremental').disabled || changeMinMaxValues('decrement','increment')">remove</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxFlex="13">
                                    <div class="min_max_field_div">
                                        <mat-form-field appearance="outline">
                                            <mat-label *ngIf="offeringInfoForm.get('incremental').get('type').value !== 'Amount'">Flex Total Values</mat-label>
                                            <input matInput  formControlName="flexTotalValues" 
                                             maxlength="50" (blur)="incrementValueChange()" [ngModel]="calculatedValue()"  class="input_text" placeholder="" readonly>
                                          </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </mat-card>
        </div>
    </div>
    <div formGroupName="prodSubDetails" *ngIf="checkForBBRole()">
        <mat-card class="offering_logic_card">
            <div fxLayout="row">
                <div fxFlex="50" fxLayoutAlign="start center">
                    <p class="offering_logic_card_title">Legacy Product Mapping</p>
                </div>
            </div>
            <div fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayoutGap.xs="0px">
                <div fxFlex="50%" class="product_mapping" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    <div>
                        <h5 class="product_details_heading">
                            <mat-icon>public</mat-icon>International Product Details
                        </h5>
                        <mat-form-field class="product_details_form">
                            <mat-label>Select International product</mat-label>
                            <input type="text" aria-label="Number" matInput formControlName="intProdName" [matAutocomplete]="autoIP"
                                (keyup)="internationalUpdateFilter($event)" />
                            <mat-autocomplete #autoIP="matAutocomplete" (opened)="showIP=true"
                                (optionSelected)="changeInternationalProduct($event.option.value)">
                                <mat-option *ngFor="let option of productList;let i=index" [value]="option.productName">
                                    {{option.productName }}
                                </mat-option>
                            </mat-autocomplete>
                            <button matSuffix mat-icon-button aria-label="Clear" (click)="onClearIntProduct()" *ngIf="showIP">
                                <mat-icon class="close_button">close</mat-icon>
                            </button>
                        </mat-form-field>
                        <div>
                            <mat-icon class="arrow_right">subdirectory_arrow_right</mat-icon>
                            <span>
                                <mat-form-field class="subproduct_details_form">
                                    <mat-label>Select International subproduct</mat-label>
                                    <input type="text" aria-label="Number" formControlName="intSubProdName" matInput
                                        [matAutocomplete]="autoIS" />
                                    <mat-autocomplete #autoIS="matAutocomplete" (opened)="showIS=true" >
                                        <ng-container *ngIf="InternationalsubProduct !== ''">
                                            <mat-option *ngFor="let option of InternationalsubProduct" [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                    <button matSuffix mat-icon-button aria-label="Clear" *ngIf="showIS" (click)="onClearIntSubProduct()">
                                        <mat-icon class="close_button">close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </span>
                        </div>
                    </div>
                </div>
                <div fxFlex="50%" class="product_mapping" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                    <div>
                        <h5 class="product_details_heading">
                            <mat-icon>local_shipping</mat-icon>US Domestic Product Details
                        </h5>
                        <mat-form-field class="product_details_form">
                            <mat-label>Select Domestic product</mat-label>
                            <input type="text" aria-label="Number" matInput formControlName="usProdName" [matAutocomplete]="autoDP"
                                (keyup)="domesticUpdateFilter($event)" />
                            <mat-autocomplete #autoDP="matAutocomplete" (opened)="showDP=true" 
                                (optionSelected)="changeDomesticProduct($event.option.value)">
                                <mat-option *ngFor="let option of domesticProductList;let i=index" [value]="option.productName">
                                    {{option.productName}}
                                </mat-option>
                            </mat-autocomplete>
                            <button matSuffix mat-icon-button aria-label="Clear" *ngIf="showDP" (click)="onClearDomProduct()">
                                <mat-icon class="close_button">
                                    close</mat-icon>
                            </button>
                        </mat-form-field>
                        <div>
                            <mat-icon class="arrow_right">subdirectory_arrow_right</mat-icon>
                            <span>
                                <mat-form-field class="subproduct_details_form">
                                    <mat-label>Select Domestic subproduct</mat-label>
                                    <input type="text" aria-label="Number" formControlName="usSubProdName" matInput [matAutocomplete]="autoDS" />
                                    <mat-autocomplete #autoDS="matAutocomplete" (opened)="showDS=true">
                                        <ng-container *ngIf="domesticsubProduct !== ''">
                                            <mat-option *ngFor="let option of domesticsubProduct" [value]="option">
                                                {{option}}
                                            </mat-option>
                                        </ng-container>
                                    </mat-autocomplete>
                                    <button matSuffix mat-icon-button aria-label="Clear" (click)="onClearDomSubProduct()" *ngIf="showDS">
                                        <mat-icon class="close_button">close</mat-icon>
                                    </button>
                                </mat-form-field>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>

    <div fxLayout="row" >
        <div fxFlex="100">
            <mat-card class="offering_logic_card" *ngIf="!offeringInfoForm?.disabled ||( offeringInfoForm?.disabled &&  offeringInfo && (offeringInfo.restrictions.length || offeringInfo.rules.length || offeringInfo.tasks.length))">
                <div fxLayout="row">
                    <div fxFlex="50" fxLayoutAlign="start center">
                        <p class="offering_logic_card_title">Offering logic <span class="offering_logic_span"> (optional)</span></p>
                    </div>
                    
                </div>
                <div fxLayout="row" class="restrictions_info" *ngIf="(offeringInfoForm?.disabled && offeringInfo && offeringInfo?.restrictions && offeringInfo?.restrictions.length ) || (!offeringInfoForm?.disabled && offeringInfo && offeringInfo?.restrictions )"> 
                    <div fxFlex="100">
                        <div fxLayout="row">
                            <div fxFlex="50" fxLayoutAlign="start center">
                                <span class="offering_logic_restrictions_title">Define Restriction(s)</span>
                            </div>
                            <div fxFlex="50" fxLayoutAlign="end center">
                                <button mat-button class="publish-btn" [ngClass]="{'disable-publish-btn' : disableAddResBtn || offeringInfoForm?.disabled}"
                                    (click)="isOpen = !isOpen" [disabled]=" disableEditOffering" cdkOverlayOrigin #trigger="cdkOverlayOrigin">Add Restriction</button>
                            </div>
                            <ng-template
                                cdkConnectedOverlay
                                [cdkConnectedOverlayOrigin]="trigger"
                                [cdkConnectedOverlayOpen]="isOpen"
                                >
                               <ng-container >
                                   <div class="restrictions_overlay">
                                       <p class="restrictions_overlay_type">Restriction Type</p>
                                        <ul class="restrictions_overlay_ul">
                                            <li *ngFor="let criteiria of scopingCriteriaTypes" [hidden]="!criteiria.visible" [ngClass]="{'active' : !criteiria.disabled}">
                                                <span class="restrictions_overlay_span">{{criteiria.type}}</span>
                                                <mat-icon *ngIf="!criteiria.disabled" style="float: right;" (click)="addScopingCriteria(criteiria)">add_circle</mat-icon>
                                                <mat-icon *ngIf="criteiria.disabled" style="float: right;" (click)="removeRestriction(criteiria.type)">remove_circle</mat-icon>
                                            </li>
                                        </ul>
                                   </div>
                               </ng-container>
                            </ng-template>
                        </div>
                        <div fxLayout="row" *ngIf="!offeringInfoForm || !offeringInfoForm.get('restrictions') || !offeringInfoForm.get('restrictions')['controls'].length">
                            <p class="empty_restrictions_lbl">
                                Restriction list is empty, click on 'Add Restriction' to define restriction(s)
                            </p>
                        </div>
                        
                        <div fxLayout="row wrap" *ngIf="offeringInfoForm && offeringInfoForm.get('restrictions') && offeringInfoForm.get('restrictions')['controls'].length" [formArrayName]="'restrictions'">
                            <!-- <div   fxFlex="100%" *ngFor="let restriction of restrictions;let firstItem = first;let lastItem = last ; let evenItem = even;" class="restrictions_info_div">
                                <app-restriction-item [restriction]="restriction" [disableEditOffering]="disableEditOffering" (remove)="removeRestriction($event)" (restrictionChanges)="changeInRestrictionItems($event)" [incrementalType]="offeringInfo.incremental ? offeringInfo.incremental.type : null "></app-restriction-item>
                            </div>   -->
                            <div   fxFlex="100%" *ngFor="let item of offeringInfoForm?.get('restrictions')['controls']; let i = index;" class="restrictions_info_div">
                                <app-restriction-item [restriction]="item"  [parentForm]="offeringInfoForm?.get('restrictions')['controls'][i]" [disableEditOffering]="disableEditOffering" (remove)="removeRestriction($event)"  [incrementalType]="(offeringInfoForm.get('incremental') && offeringInfoForm.get('incremental').get('type') && offeringInfoForm.get('incremental').get('type').enabled) ? offeringInfoForm.get('incremental').get('type').value : 'null'" [offeringType] = "offeringInfoForm.get('offeringDetail') && offeringInfoForm.get('offeringDetail').get('type')  ? offeringInfoForm.get('offeringDetail').get('type').value : 'null'"></app-restriction-item>
                            </div>  
                        </div>
                    </div>
                </div>
                <div fxLayout="row"  class="restrictions_info" *ngIf="(offeringInfoForm?.disabled && offeringInfo && offeringInfo?.rules && offeringInfo?.rules.length ) || (!offeringInfoForm?.disabled && offeringInfo && offeringInfo?.rules )" [formArrayName]="'rules'">
                    <div fxFlex="100">
                        <div fxLayout="row">
                            <div fxFlex="50" fxLayoutAlign="start center">
                                <span class="offering_logic_restrictions_title">Define Rule(s)</span>
                            </div>
                            
                            <div fxFlex="50" fxLayoutAlign="end center">
                                <button mat-button class="restrictions_button " [disabled]=" offeringInfoForm?.disabled"  [ngClass]="{'empty_restriction_btn' :(!offeringInfo || !offeringInfoForm?.get('rules')['controls'] || !offeringInfoForm?.get('rules')['controls'].length ) }" (click)="offeringInfoForm?.get('rules')['controls'].length || addNewRule()">Add Rule</button>
                            </div>
                        </div>
                        <div fxLayout="row" *ngIf="!offeringInfo || !offeringInfoForm?.get('rules')['controls'] || !offeringInfoForm?.get('rules')['controls'].length">
                            <p class="empty_restrictions_lbl">
                                Rules list is empty, click on ‘Add Rule’ to define rule(s)
                            </p>
                        </div>
                        <div fxLayout="row wrap" *ngIf="offeringInfo && offeringInfoForm?.get('rules')['controls'] && offeringInfoForm?.get('rules')['controls'].length">
                            <div fxFlex="100" class="restrictions_info_div" >
                                <div fxLayout="row wrap" class="resction_details_div"  *ngFor="let rule of offeringInfoForm?.get('rules')['controls'];let i = index; let lastRule = last; let firstRule = first;" [formGroupName]="i">
                                    <div fxFlex="95">
                                        <div fxLayout="row wrap" class="resction_details_row">
                                            <div fxFlex="100" fxLayoutGap="1.25em">
                                                <div  fxFlex="auto">
                                                    <div class="rules_field">
                                                        <mat-form-field appearance="outline" class="" #ruleField>   
                                                            <mat-label *ngIf="ruleField._control.focused || (rule && rule.value && rule.value.rule)">Rule #{{i+1}}</mat-label>
                                                            <mat-label *ngIf="!ruleField._control.focused && (!rule.value || !rule.value || !rule.value.rule)">Select Rule</mat-label>
                                                            <mat-select formControlName="rule" (openedChange)="matFocusedEvent($event,'rules'+i)" disableOptionCentering="true">
                                                              <mat-option *ngFor="let rule of OfferingRulesList" [disabled]="isSelcetedRule(rule.value,i)" [value]="rule.value">{{rule.displayValue}}</mat-option>
                                                            </mat-select>
                                                            <mat-icon *ngIf="!matSelectFocused['rules'+i]" matSuffix>keyboard_arrow_down</mat-icon>
                                                            <mat-icon *ngIf="matSelectFocused['rules'+i]" matSuffix>keyboard_arrow_up</mat-icon>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div  fxFlex="auto">
                                                    <div class="rules_field">
                                                        <mat-form-field appearance="outline" class=""  [ngClass]="{'multi_display_drodown mat_auto_complete_chip' : offeringInfoForm?.get('rules').get(''+i).get('offerings')['controls'].length}" #linkedOfferingField>
                                                            <mat-label *ngIf="linkedOfferingField._control.focused || offeringInfoForm?.get('rules').get(''+i).get('offerings')['controls'].length">Linked Offering</mat-label>
                                                            <mat-label *ngIf="!linkedOfferingField._control.focused && !offeringInfoForm?.get('rules').get(''+i).get('offerings')['controls'].length">Select Linked Offering</mat-label>
                                                            <mat-chip-list #ruleschipList fxLayout="row wrap">
                                                                <mat-chip
                                                                fxFlex="auto"
                                                                *ngFor="let offering of offeringInfoForm?.get('rules').get(''+i).get('offerings')['controls']; let offeringIndex = index;"
                                                                fxLayoutAlign="start center"
                                                                [removable]="true" 
                                                                >
                                                                <div fxLayoutAlign="start center" class="mat_chip_div">
                                                                    <span>{{ offering.get('displayName') ? offering.get('displayName').value : ''}}</span>
                                                                <mat-icon matChipRemove *ngIf="!offeringInfoForm?.disabled" (click)="removeSelectedOffering(offering,i,offeringIndex)" >cancel</mat-icon>
                                                                </div>
                                                                </mat-chip>
                                                                <input
                                                                placeholder="Search Offerings..."
                                                                id="{{'ruleInput'+ i}}"
                                                                matInput
                                                                (keyup)="searchOfferings($event)"
                                                                [disabled]="offeringInfoForm?.disabled"
                                                                (blur)="linkedOfferingInput.value = ''"
                                                                #linkedOfferingInput
                                                                [matAutocomplete]="auto"
                                                                [matChipInputFor]="ruleschipList"
                                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                (matChipInputTokenEnd)="add($event)" />
                                                            </mat-chip-list>
                                                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event,i,'ruleInput'+ i)">
                                                                <mat-option *ngFor="let offering of offeringsData" [disabled]="isOfferingSelected(offering)" [value]="{'reference':offering.reference,'displayName':offering.displayName}">
                                                                {{offering.displayName}}
                                                                </mat-option>
                                                            </mat-autocomplete>
                                                        </mat-form-field>
                                                        
                                                    </div>
                                                </div>
                                                <div fxFlex="20" >
                                                    <div fxFlex="25" *ngIf=" offeringInfoForm?.get('rules') &&  offeringInfoForm?.get('rules')['controls'].length > 1 && !offeringInfoForm?.disabled">
                                                        <div class="row_delete_icon" fxLayoutAlign="center center">
                                                            <img src="../assets/icons/arrow_delete.svg" (click)="removeRule(i)" alt=""/>
                                                        </div>
                                                    </div>
                                                    <div fxFlex="25" *ngIf="lastRule && OfferingRulesList.length > offeringInfoForm?.get('rules')['controls'].length && !offeringInfoForm?.disabled">
                                                        <div class="row_delete_icon" fxLayoutAlign="center center">
                                                            <mat-icon (click)="addNewRule()">add</mat-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxFlex="5">
                                        <div *ngIf="firstRule && !offeringInfoForm?.disabled" fxLayoutAlign="end start">
                                            <mat-icon (click)="clearData('rules')" class="offering_delete_icon">remove</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                
                <div fxLayout="row" class="restrictions_info" *ngIf="(offeringInfoForm?.disabled && offeringInfo && offeringInfo?.tasks && offeringInfo?.tasks.length ) || (!offeringInfoForm?.disabled && offeringInfo && offeringInfo?.tasks )">
                    <div fxFlex="100" [formArrayName]="'tasks'">
                        <div fxLayout="row">
                            <div fxFlex="50" fxLayoutAlign="start center">
                                <span class="offering_logic_restrictions_title">Define Task(s)</span>
                            </div>
                            <div fxFlex="50" fxLayoutAlign="end center">
                                <button mat-button class="restrictions_button " [disabled]="offeringInfoForm?.disabled"  [ngClass]="{'empty_restriction_btn' :(!offeringInfo || !offeringInfoForm?.get('tasks')['controls'] || !offeringInfoForm?.get('tasks')['controls'].length) }" (click)="offeringInfoForm?.get('tasks')['controls'].length || addNewTask()">Add Task</button>
                            </div>
                        </div>
                        <div fxLayout="row" *ngIf="(!offeringInfoForm || !offeringInfoForm?.get('tasks')['controls'] || !offeringInfoForm?.get('tasks')['controls'].length)">
                            <p class="empty_restrictions_lbl">
                                Task list is empty, click on ‘Add Task’ to define task(s)
                            </p>
                        </div>
                        <div fxLayout="row" *ngIf="offeringInfoForm?.get('tasks')['controls'].length">
                            <div cdkDropList  fxFlex="100" class="restrictions_info_div cdk-list " (cdkDropListDropped)="drop($event)">
                               
                                <div fxLayout="row wrap" class="resction_details_div cdk-box" fxLayoutAlign="start start" fxLayoutGap="1.25em"   *ngFor="let task of offeringInfoForm?.get('tasks')['controls'];let j =index; let lastTask = last; let firstTask = first; ">
                                    
                                    <div fxFlex="70" cdkDrag [cdkDragDisabled]="offeringInfoForm?.disabled">
                                        <div  fxLayout="row" fxLayoutGap="1.25em" class="resction_details_row">
                                            <div class="drag_img_div" fxFlex="1" fxLayoutAlign="start center" style="margin-right: 0;">
                                                <img  cdkDragHandle  src="../assets/icons/mat_drag_icon.png" alt="" />
                                            </div>
                                            <div fxFlex="auto" class="task">
                                                <mat-form-field appearance="outline" class="mat-form-field-textarea">
                                                    <mat-label>Task #{{j+1}}</mat-label>
                                                    <textarea matInput [formControlName]="j"  class="input_text"></textarea>
                                                </mat-form-field>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div fxFlex="20" class="resction_details_row">
                                        <div fxFlex="25" *ngIf=" offeringInfoForm?.get('tasks')['controls'] &&   offeringInfoForm?.get('tasks')['controls'].length > 1 && !offeringInfoForm?.disabled">
                                            <div class="row_delete_icon" fxLayoutAlign="center center">
                                                <img src="../assets/icons/arrow_delete.svg" (click)="removeTask(j)" alt=""/>
                                            </div>
                                        </div>
                                        <div fxFlex="25" *ngIf="lastTask &&  offeringInfoForm?.get('tasks')['controls'].length < 4 && !offeringInfoForm?.disabled">
                                            <div class="row_delete_icon" fxLayoutAlign="center center">
                                                <mat-icon (click)="addNewTask()">add</mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div fxFlex="auto">
                                        <div *ngIf="firstTask && !offeringInfoForm?.disabled" fxLayoutAlign="end start">
                                            <mat-icon (click)="clearData('tasks')" class="offering_delete_icon">remove</mat-icon>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="asso_temp_row" *ngIf="offeringAction === 'edit' && offeringStatus === 'Active'">
        <div fxLayout="row">
            <div fxFlex="50%" fxLayoutAlign="start center">
                <span class="asso_temp_title">Associated Template(s) - {{associatedTemplates?.length ? associatedTemplates?.length : 0}}</span>
            </div>
            <div fxFlex="50%" fxLayoutAlign="end center">
                <button mat-button class="restrictions_button empty_restriction_btn" (click)="addToTemplate()" >Add to Template</button>
            </div>
            
        </div>
        <div fxLayout="row wrap">
            <ng-container  *ngFor="let template of associatedTemplates">
                <div fxFlex="100%" fxLayout="column" class="asso_temp_card_div">
                    <mat-card >
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="50%" class="asso_temp_name">
                                <span >
                                    {{template.name}}
                                </span>
                            </div>
                            <div fxFlex="10%" fxLayoutAlign="center center">
                                <span class="asso_temp_type">{{template.status}}</span>
                            </div>
                            <div fxFlex="15%"  fxLayoutAlign="center center">
                                <span *ngIf="template.offerings" class="asso_temp_offerings_count">{{template.offerings.length}} offering(s)</span>
                            </div>
                            <div fxFlex="12%" class="asso_temp_created" fxLayoutAlign="center center">
                                <span>{{getCreatedBy(template.createdBy)}}</span>
                            </div>
                            <div fxFlex="10%" class="asso_temp_created" fxLayoutAlign="center center">
                                <span>{{template.createdAt  | date: 'YYYY-MM-dd'}}</span>
                            </div>
                            <div fxFlex="3%" fxLayoutAlign="end center" class="offering_delete_icon_div">
                                <mat-icon class="offering_delete_icon" (click)="removeTemplate(template)">remove</mat-icon>
                            </div>
                        </div>
                    </mat-card>
                </div>
            </ng-container>
            
        </div>
    </div>
</div>
</form>
<div class="footer_content">
    <div fxLayout="row" fxLayoutAlign="end center" class="footer_row">
        <div fxFlex="40%">
            <div fxLayout="row"  fxLayoutAlign="end center">
                <div fxFlex="20%"  fxLayoutAlign="center center">
                    <button  mat-button color="primary" class="cancel-btn"  (click)="cancel()">Cancel</button>
                </div>
                <div fxFlex="40%" *ngIf="offeringStatus !== 'Active' && !offeringInfoForm?.disabled"  fxLayoutAlign="center center">
                    <button mat-stroked-button [disabled]="(!offeringInfoForm?.valid || !offeringCategory || !offeringCategory.length)"  class="save-btn" [ngClass]="{'disable-save-btn':(!offeringInfoForm?.valid  || !offeringCategory || !offeringCategory.length )}"  (click)="save('Draft')">Save as Draft</button>
                </div>
                <div fxFlex="40%"  fxLayoutAlign="center center">
                    <button  mat-raised-button [disabled]="(!offeringInfoForm?.valid || !offeringCategory || !offeringCategory.length) && !disableEditOffering " class="publish-btn" [ngClass]="{'disable-publish-btn': (!offeringInfoForm?.valid || !offeringCategory  || !offeringCategory.length) && !disableEditOffering}" (click)="save('Active')">Save and Publish</button>
                </div>
            </div>
        </div>
    </div>
</div>
