import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseClientService } from './base-client.service';
// import { urlType } from '../models/urlType';
import { NgxSpinnerService } from "ngx-spinner";
import { response } from 'express';

export interface SimpleRule {
    type: string;
    _scopingCategory: string;
    scopingType: string;
    fact: string;
    uiType: string;
    id?: string;
    values: Array<OperatorValue>
};
export interface OperatorValue {
    operator: string;
    value: number;
  }
  export interface Benefit {
    singleSelectTier?: any;
    tierValues?: any;
    reference: string;
    productSpecificationReference: string;
    category: string;
    consultantBenefitInfo?: { consultant: boolean, qualifyingInput?: number , activatedByDefault?: boolean};
    displayName: string;
    description: string;
    points: number;
    amount?: number;
    suppress?: boolean;
    multiplicity?: boolean;
    incremental?: boolean;
    maxSelection: number;
    prerequisites?: Array<any>;
    exclusions?: Array<any>;
    inclusions?: Array<any>;
    prerequisiteRule?: Array<any>;
    excludeRule?: Array<any>;
    includeRule?: Array<any>;
    scopes?: any;
    rangeMax?: number;
    rangeMin?: number;
    rangeIncrementValue?: number;
    rangeIncrementUnit?: string;
    incrementalTimespan?:boolean;
    tierConfigs?: Array<any>;
    cashOut?: boolean;
    advCashOut?: boolean;
    advCashOutV2?: boolean;
    pointsPerSelection?: number;
    icon?: string;
    perPointCurrency?: number;
    incrementalCurrency?: boolean;
    isCloned?:boolean;
    clonedCopy?:boolean;
    clonedFrom?: string;
    parentClonedCopy ?: boolean
    editTitle?: boolean; //flag for ui only not for api
    editDescription?: boolean; //flag for ui only not for api
    isNewlyAdded ?: boolean; // flag to determine new offerings
    isNewlyAddedRef? :boolean;
    hybridTempLiving?:boolean;
    preMaxSelection?: any; // temp variable
    preRangeIncrementValue?: any; //temp variable
    preRangeIncrementUnit?: any; //temp variable
    preCoreUnitsPerSelection?: any; //temp variable
    preRangeMax?: any; //temp
    preRangeMin?: any; //temp
    coreUnitsPerSelection?:number;
    productSubDetails :{
      internationalProduct?: {
        productName?: string;
        subProductName?: string;
        requiresRevision?:boolean;
      },
      usDomesticProduct?: {
        productName?:string;
        subProductName?: string;
        requiresRevision?:boolean;
   
      }
    },
    isDEIEnabled?: boolean;
    isSustainabilityEnabled?: boolean;
    tasks:Array<string>;
    currencyCode?: string;
  }
@Injectable({
 providedIn: 'root'
})
export class ProdOfferingsService {

    bbRole= new BehaviorSubject([]);

    public observable:boolean =false;

    /**
   * base constructor
   * @param baseClientService baseclient service
   * @param spinner spinner modal service
   */
    constructor(private http:HttpClient, private readonly baseClientService: BaseClientService,
        private spinner: NgxSpinnerService) { }

    
    

    resetOfferingStatus(){
        this.observable = false;
    }

    changeInOfferings(){
        return this.observable;
    }

    onChangeInOfferings(){
        this.observable = true;
    }


    /**
     * Get color codes for product categories 
   */
    getProdCategoryColorCodes(){
        let productKey = 'ProductCategories';
        return this.getvalueLists(productKey); 
    }


     /**
     * Get all product offerings total count
   */
    getAllOfferingsCount() {
    return this.baseClientService.get('count/offerings','').pipe(   
        map((response) =>{
        return response.body;  
        })
    ); 
    }

    /**
     * Get all product offerings list for specific or all types
     * @param prams string it contains skip , type and limit
 */
    getAllOfferings(prams:String , payload?: any):Observable<any>{
        return this.baseClientService.post(`offerings?${prams}`, payload).pipe(   
        map((response) =>{
            return response;  
        })
        ); 
    }

    /**
     * Get  offering details by reference value
     * @param reference string it reference key value of offering object
 */
     getOfferingByReference(reference:String){
        return this.baseClientService.get(`offerings/${reference}?expandParties=true`,'').pipe(   
            map((response) =>{
                return response.body;  
            })
        ); 
    }

     /**
     * Get  all associated template details by reference value
     * @param reference string it reference key value of offering object
 */
    getTemplatesByReference(reference:String){
        return this.baseClientService.get(`offerings/${reference}/templates`,'').pipe(   
            map((response) =>{
                return response.body;  
            })
        ); 
    }

    /**
         * Get value list arry based on key name
         * @param key string it reference name of the value lists
    */
    getvalueLists(key) {
        return this.baseClientService.get(`valueLists/${key}`,'').pipe(   
            map((response) =>{
            return response.body;  
            })
        );
    }


    /**
         * Get contry list based on search text
         * @param searchText string it should be more than 3 charecters
    */
    getCoutryLists (searchText) {
        return this.baseClientService.get(`locations/countries?searchText=${searchText}`,'').pipe(   
            map((response) =>{
                return response.body;  
            })
        );
    }

    /**
         * Get contry list based on search text
         * @param searchText string it should be more than 3 charecters
    */
    getCityLists (searchText,countryCode) {
        return this.baseClientService.get(`locations/autocomplete?searchText=${searchText}&countryCode=${countryCode}`,'').pipe(   
            map((response) =>{
                return response.body;  
            })
        );
    }


    /**
        * saveOffering
        * @param offeringReference offering reference value
    */
    saveOffering (offeringData) {
        return this.baseClientService.post(`offerings/save`,offeringData).pipe(   
            map((response) =>{
                return response.body;  
            })
        ); 
    }

    /**
        * update assosiated template
        * @param offeringReference offering reference value
    */
    updateTemplatesToOffering (offeringReference,templateData) {
        return this.baseClientService.post(`offerings/${offeringReference}/templates`,templateData).pipe(   
            map((response) =>{
                return response.body;  
            })
        ); 
    }

     /**
        * Revive an Archived offering
        * @param offeringReference offering reference value
    */
    restoreArchvedOffering (offeringReference) {
        return this.baseClientService.post(`offerings/${offeringReference}/revive`,'').pipe(   
            map((response) =>{
                return response.body;  
            })
        ); 
    }

    /**
        *Archive an offering for the given offering reference
        * @param offeringReference offering reference value
    */
    moveToArchive (offeringReference) {
        return this.baseClientService.post(`offerings/${offeringReference}/archive`,'').pipe(   
            map((response) =>{
                return response.body;  
            })
        );
    }

    /**
        * Delete the template by offering referece
        * @param offeringReferenceId offering reference value
    */
     deleteOffering (offeringReferenceId) {
        return this.baseClientService.delete(`offerings/${offeringReferenceId}`).pipe(   
            map((response) =>{
                return response.body;  
            })
        ); 
    }

    /**
        *Get all product specs. Only service, benefit, and expense specs. No bundle specs
        * @param searchText search keyword
    */
    getAllProducts (searchText?) {
        return this.baseClientService.get(`products?searchText=${searchText}`,'').pipe(   
            map((response) =>{
                return response.body;  
            })
        );
    }

     /**
        *Get a product spec by product reference. If the product found also has a multiplicity setup, the multiplicity bundle will be returned as well as the product.
        * @param searchText search keyword
    */
    getProductSpecs (prodReferenceId) {
        return this.baseClientService.get(`products/${prodReferenceId}`,'').pipe(   
            map((response) =>{
                return response.body;  
            })
        );
    }

     /**
        *Gets a list of Scoping-Criterias which have name and description property
    */
    getComplexCriteriaList(){
        return this.baseClientService.get(`scopes/complex`,'').pipe(   
            map((response) =>{
                return response.body;  
            })
        );
    }
    getNewLegacy(){
        return this.baseClientService.get(`valueLists/LegacyProductSubProduct`,'').pipe(   
            map((response) =>{
                return response.body;  
            })
        );
      }

    showSpinner (name?:string , data?) {
        this.spinner.show(name,data);
    }

    hideSpinner (name?:string) {
    this.spinner.hide(name);
    }



    // will get the list of icon in Icon upload popup
   getAllIconList(){
       return this.baseClientService.get(`offering-icons?skip=1&limit=5000`,'').pipe(
    map((response) => {
        return response.body;
        })
      );
   }

   /**will get all the products */
    getAllProductsList() {
        return this.baseClientService.get(`products`, '').pipe(
            map((response) => {
                return response.body;
            })
        );
    }
   
    /**will get all the created by values */
    getCreatedByList() {
        return this.baseClientService.get(`offerings/createdBy?status=Active`, '').pipe(
            map((response) => {
                return response.body;
            })
        );
    }


}
