import { Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Router, RoutesRecognized, NavigationEnd, NavigationStart } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from "ngx-spinner";
import { ProdOfferingsService } from './core/services/product-offerings.service';
import { UserContextService } from './core/services/user-context.service';
import Timeout = NodeJS.Timeout;
import { LogoutModalComponent } from './core/components/logout-modal/logout-modal.component';
import { ToastIdleTimeoutComponent } from './core/components/toast-idle-timeout/toast-idle-timeout.component';
import { IdleTimeoutService } from './core/services/idle-timeout.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppConfigService } from './core/services/app-config.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { UserSessionService } from './core/services/user-session.service';
import { UserContext } from './core/models/userContext';

/** Base application component */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit,OnDestroy {

  colorCodesObj: any = {}

  /** Idle timeout minutes */
  idleTimeoutMinutes = 15;
  /** Idle timeout cookie name */
  idleCookieName = 'lastAction';
  /** Idle timeout keep-alive interval */
  pingInterval = 0;
  /** Idle timeout poll interval */
  pollInterval = 1000;
  /** Idle timeout check time */
  idleCheckSeconds = 60;
  /** showLogout used to determine whether the page displays the logout link */
  /** Idle timeout last mouse X */
  lastX = 0;
  /** Idle timeout last mouse Y */
  lastY = 0;
  showLogout = false;
  refreshTokenInterval: any;
  refreshTokenTimeout = 9; // 3 chances to update in a 30 minnute window
  refreshSessionTimeout = 60;
  isIdleTimeoutWarning = false;
  /** NodeJS Timeout */
  lastTimeOut: Timeout;

  idleTimeoutWarningInitiateMinutes: any;
  userDetails = {contextId: '', userName: [' ',' ']} as UserContext
  idleTimeoutWarningMinutes = 13;
  logoutImg = {
    src: '',
    srcOn: '../assets/icons/logout_dkblue.svg',
    srcOut: '../assets/icons/logout.svg',
    mobileSrcOut: '../assets/icons/logout-white.svg'
  };

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  /** logoutDialogRef to access the Dialog */
  logoutDialogRef: MatDialogRef<LogoutModalComponent>;

  public termsOfUseUrl: string;
  public privacyNotice: string;
  loadChildren: boolean;
  /** standard base constructor */
  constructor(public prodOfferingsService: ProdOfferingsService,
    public dialog: MatDialog,
    public readonly router: Router,
    private readonly appConfig: AppConfigService,
    private _ngZone: NgZone,
    private _snackBar: MatSnackBar,
    private liveAnnouncer: LiveAnnouncer,
    private readonly idleTimeoutService: IdleTimeoutService,
    public userSvc: UserContextService,
    private userSessionService: UserSessionService,
    private cookieService: CookieService) { }

  refreshIdleCookie() {
    this.idleTimeoutService.timeoutData.next(false);
    const currentTime = new Date().getTime();
    const idleExpireMs = currentTime + (this.idleTimeoutMinutes * 60000) - 500;
    this.idleTimeoutWarningInitiateMinutes = currentTime + (this.idleTimeoutWarningMinutes * 60000) - 500;
    this.isIdleTimeoutWarning = false;
    if (document.location.hostname === 'localhost') {
      this.cookieService.set(this.idleCookieName, idleExpireMs.toString()); // Developer
    } else {
      this.cookieService.set(
        this.idleCookieName,
        idleExpireMs.toString(),
        1,
        '/',
        '.cartus.com',
        true
      );
    }
  }

  /** Get cards for display and set view title */
  ngOnInit() {
    // sessionStorage.setItem('car-ses-con'
    this.prodOfferingsService.showSpinner();
    const context = this.cookieService.get('car-ses-con');
    if (context) {
      this.userSvc.getContext(context).subscribe({
        next: (response) => {
          console.log(JSON.stringify(response))
          if (response) {
            this.userDetails = response
            sessionStorage.setItem('car-ses-con', this.userDetails.contextId);
            this.getProdCategoryColorCodes();
          }
        },
        error: (error) => {
          this.prodOfferingsService.hideSpinner();
        }
      });
      sessionStorage.setItem('car-ses-con', context);
    } else {
      this.userSvc.getContexts().subscribe({
        next: (response) => {
          console.log(JSON.stringify(response))
          if (response && response.length) {
            this.userDetails = response[0];
            sessionStorage.setItem('car-ses-con', this.userDetails.contextId);
            this.getProdCategoryColorCodes();
          }
        },
        error: (error) => {
          this.prodOfferingsService.hideSpinner();
        }
      });
    }
    this.refreshSessionInApp();
    this.idleTimeoutService.timeoutData$.pipe().subscribe((response) => {
      if (response) {
        this._ngZone.run(() => {
          this._snackBar.openFromComponent(ToastIdleTimeoutComponent, {
            // duration:120000,
            verticalPosition: this.verticalPosition,
            horizontalPosition: this.horizontalPosition,
          })
        });
      } else {
        if (this.isIdleTimeoutWarning == true) {
          this._snackBar.dismiss();
        }
      }
    })
  }

  getProdCategoryColorCodes() {
    this.prodOfferingsService.getProdCategoryColorCodes().subscribe({
      next: (response) => {
        this.prodOfferingsService.hideSpinner();
        if (response && response[0] && response[0]['values'] && response[0]['values'].length) {
          let result = response[0]['values'].sort((a, b) => a.order - b.order );
          let categoryDisplayNames = [...result];
          // console.log(response);
          result.forEach((element, index) => {
            if (element.displayName) {
              categoryDisplayNames[index] = element.displayName;
              this.colorCodesObj[element.displayName.replaceAll(' ', '')] = {
                color: element.color,
                order: element.order,
                displayName: element.displayName
              }
            }
          });
          if (Object.keys(this.colorCodesObj).length) {
            sessionStorage.setItem('colorCodes', JSON.stringify(this.colorCodesObj))
          }
          if(categoryDisplayNames.length) {
            sessionStorage.setItem('categoryDisplayNames', JSON.stringify(categoryDisplayNames))
          }
          // console.log(this.colorCodesObj);
        }
        this.goToDashBoard();
      },
      error: (error) => {
        this.goToDashBoard();
        this.prodOfferingsService.hideSpinner();
      }
    });
  }

  public refreshSessionInApp() {
    this.refreshTokenInterval = setInterval(() => {
      this.refreshToken()
    }, 6000 * this.refreshTokenTimeout);
  }

  goToDashBoard () {
    this.startIdleTimeCountDown();
    this.loadChildren = true;
  }

  refreshToken() {
    this.userSessionService.refreshSession().then((freshToken: any) => {
      if (freshToken) {
        this.cookieService.set(
          'car-ses-tok',
          freshToken.tokens.accessToken.accessToken,
          null, // We are relying on Okta session expiration
          '/',
          '.cartus.com',
          true
        );
      }
    })
      .catch((err) => {
        console.log('error in freshToken :', err);
      });
  }

  /** Listen for keypress events */
  @HostListener('document:keypress', ['$event'])
  onKeyPress() {
    this.refreshIdleCookie();
  }

  /** Listen for mouse events */
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    if (e.pageX !== this.lastX || e.pageY !== this.lastY) {
      this.lastX = e.pageX;
      this.lastY = e.pageY;
      this.refreshIdleCookie();
    }
  }

  /** Start idle timeout feature */
  startIdleTimeCountDown() {
    if (this.lastTimeOut) {
      clearTimeout(this.lastTimeOut);
    }
    this.refreshIdleCookie();
    this._ngZone.runOutsideAngular(() => {
      this.lastTimeOut = setTimeout(this.checkIdle.bind(this), this.pollInterval);
    });
  }

  /** Check idle timeout status */
  checkIdle() {
    const sesTok = !!this.appConfig.getConfig('mocked') ? 'a' : this.cookieService.get('car-ses-tok');
    const idleExpireMs = parseInt(this.cookieService.get(this.idleCookieName), 10);
    const currTimeMs = new Date().getTime();
    if (!sesTok || currTimeMs > idleExpireMs) {
      this.logout('4');
    } else {
      if (!this.isIdleTimeoutWarning && currTimeMs >= this.idleTimeoutWarningInitiateMinutes) {
        this.isIdleTimeoutWarning = true;
        this.idleTimeoutService.timeoutData.next(true);
      }
      this.pingInterval += this.pollInterval;
      if (this.pingInterval === 1000 * this.idleCheckSeconds) {
        this.pingInterval = 0;
      }
      this._ngZone.runOutsideAngular(() => {
        this.lastTimeOut = setTimeout(this.checkIdle.bind(this), this.pollInterval);
      });
    }
  }

  /** logout of the application.  Logout URL handled by configuration */
  logout(code: string = '') {
    clearInterval(this.refreshTokenInterval);
    sessionStorage.clear();
    let codeUrl = '';
    if (code.length > 0) {
      codeUrl = '&code=' + code;
    }
    const logoutURL = this.appConfig.getConfig('login') + '/#/logout?appId=1' + codeUrl;
    if (this.userSvc.windowRef && this.userSvc.windowRef.length > 0) {
      this.userSvc.windowRef.forEach(ref => {
        ref.location.href = 'https://onlinedev.cartus.com/resp/Public#/logout';
      });
    }
    this.router.navigate(['/externalRedirect', { externalUrl: logoutURL }], {
      skipLocationChange: true
    });
  }

  setLogoutDisplay(event) {
    this.showLogout = event; // event is emitted value
  }

  /** filterModal method to open the Filter modal popup */
  logoutModal(): void {
    this.logoutDialogRef = this.dialog.open(LogoutModalComponent, {
      panelClass: 'logoutModal',
      disableClose: true,
      maxWidth: '100%',
      autoFocus: false,
    });
    this.logoutDialogRef.afterClosed().subscribe(result => {
    });
  }

  ngOnDestroy() {
    sessionStorage.clear();
 }

}
