<div fxLayout="column" class="main">

    <div mat-dialog-title disableTypography class="title" fxLayout="row" fxLayoutAlign="space-between"
        fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div> Filter Offerings</div>
        <a href="#" class="close-icon" (click)="onDismiss($event)" title="close" role="button">
            <mat-icon>close</mat-icon>
        </a>
    </div>

    <div fxLayout="column">
        <div fxLayout="row" class="search" fxLayoutAlign="space-between" fxLayoutGap="20px" fxLayoutGap.xs="0em">
            <div class="sub-title" fxFlex="24%" fxLayout.xs="column" fxLayoutAlign="start">
                Selected Filters
            </div>
            <div fxFlex="76%" fxLayout.xs="column" *ngIf="selectedFilters.length > 0">
                <div class="filter_chip">
                    <mat-chip-list aria-label="filter selected">
                        <mat-chip *ngFor="let filter of selectedFilters" class="filter-selected-chip" [removable]="true">
                            {{ filter.displayName }}
                            <mat-icon tabindex="0" class="close-chip" aria-label="cancel selected filter" matChipRemove (click)="onRemoveChip(filter)">
                                cancel</mat-icon>
                        </mat-chip>
                    </mat-chip-list>
                </div>
            </div>
            <div *ngIf="selectedFilters.length === 0" class="filter_chip" fxFlex="80%" fxLayout="column" >
                <input class="search_input" matInput placeholder="No filter selected" [disabled]="true" style="margin: 0.625em;">
            </div>
        </div>
        <div fxLayoutAlign="end end" fxFlex="100%" fxLayout="row" fxLayoutGap="20px" fxLayoutGap.xs="0em">
            <div fxFlex="24%" fxLayout.xs="column" fxLayoutAlign="start"></div>
            <div fxFlex="76%" fxLayout.xs="column" class="serch_input_row">
                <div fxFlex="10%" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon class="search_icon">search</mat-icon>
                </div>
                <div fxFlex="90%" fxLayoutAlign="start center">
                    <input [(ngModel)]="searchText" (ngModelChange)="onSearch()" class="search_input" matInput placeholder="{{searchPlaceHolder}}" #searchInput >
                </div>
            </div>
        </div>
        
    </div>

    <div class="container" fxLayout.xs="column" fxLayout="row">
        <div class="left_div" fxFlex="25%" fxLayoutAlign="start" fxLayout="column">
            <button *ngFor="let type of filterTypes; let index = index" [ngClass]="{'selected' : type.selected}" (click)="onClickFilterType(type)" id="categories">{{type.displayName}}
                <div *ngIf="type.count > 0 ">{{type.count}}</div>
            </button>
        </div>
        <div class="right_div" fxFlex="75%">
            <!-- <div fxLayout="row" class="serch_input_row">
                <div fxFlex="10%" fxLayout="column" fxLayoutAlign="center center">
                    <mat-icon class="search_icon">search</mat-icon>
                </div>
                <div fxFlex="90%" fxLayoutAlign="start center">
                    <input [(ngModel)]="searchText" (ngModelChange)="onSearch()" class="search_input" matInput placeholder="{{searchPlaceHolder}}" #searchInput >
                </div>
            </div> -->
            <div class="product_list">
                <section *ngFor="let value of filterTypeValues.values">
                    <input class="checkbox_list cursor-hand" type="checkbox" [checked]="value.checked" (change)="onCheckBoxClick(value, filterTypeValues.type)"/>
                    <label class="text-label">{{value.displayName}}</label>
                </section>
            </div>
        </div>
    </div>

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="20px" fxLayoutGap.xs="0px"
        fxLayoutAlign.xs="space-between center" class="action-block">
        <button mat-button class="cancel-btn btn cursor-hand" (click)="onDismiss($event)" title="close">Cancel</button>
        <button mat-raised-button class="filter-btn btn cursor-hand" (click)="onFilterClick()">Filter</button>
    </mat-dialog-actions>
</div>