<div mat-dialog-title>
    <div fxLayout="row" >
        <div fxFlex="15" fxLayoutAlign="start center">
            <mat-icon class="restore-icon" *ngIf="dialogData.type === 'restore'">settings_backup_restore</mat-icon>
            <img *ngIf="dialogData.type !== 'restore'" src="../assets/images/warning_img.png" alt=""/>
        </div>
        <div fxFlex="85" fxLayoutAlign="start center">
           <p  id="mat-dialog-title-0" *ngIf="dialogData">{{dialogData.title}}</p>
        </div>
    </div>
<mat-icon (click)="closeDialog('close')" class="mat-dialog-close-icon">close</mat-icon>

</div>
<div mat-dialog-content *ngIf="dialogData">{{dialogData.content}}</div>
<mat-dialog-actions align="end" *ngIf="dialogData && dialogData.actions.length">
  <button mat-button *ngFor="let action of dialogData.actions" [ngClass]="{'mat-dialog-close':(action.type === 'close'),'mat-dialog-delete':(action.type === 'delete'),'mat-dialog-restore':(action.type === 'restore' || action.type === 'discard')}" (click)="closeDialog(action.type)">{{action.name}}</button>
</mat-dialog-actions>