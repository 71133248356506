
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

import { ProdOfferingsService } from '../../services/product-offerings.service';
import { ProdTemplatesService } from '../../services/product-templates.service'
import { WarningModelComponent } from '../common/warning-model/warning-model.component';
import { SyncPgmModalComponent } from '../sync-pgm-modal/sync-pgm-modal.component';
import { FeatureFlag, FeatureFlagService } from '../../services/feature-flag.service';



@Component({
  selector: 'app-edit-pord-template',
  templateUrl: './edit-pord-template.component.html',
  styleUrls: ['./edit-pord-template.component.scss']
})
export class EditPordTemplateComponent implements OnInit, OnDestroy {

  btnConfig = {
    showCancelBtn:true,
    showSaveDraftBtn:false,
    showPublishBtn:true
  };
  availableOfferingsList = [];
  addedOfferingsList = [];
  tempStatus: string;
  templateName: any;
  templateDescription: any;
  templateInfo: any;
  tempType = 'edit';
  isDuplicateTempName = false;
  isSyncProgramEnabled: boolean = false;
  templateId: any;
  sub: any;
  searchOptions = {
    expandOfferings:true,
    expandParties:true
  }

  constructor(
    public router: Router,
    public prodOfferingsService: ProdOfferingsService,
    public prodTemplatesService :ProdTemplatesService,
    public dialog : MatDialog,
    private _Activatedroute : ActivatedRoute,
    private featureFlagService: FeatureFlagService
  ){

  }

  ngOnInit(): void {
    console.log("CreateTemplateComponent loads");
    this.tempStatus = sessionStorage.getItem("tempStatus");
    this.getFeatureFlag();
    if(this.tempStatus == 'Active'){
      this.btnConfig = {
        showCancelBtn:true,
        showSaveDraftBtn:false,
        showPublishBtn:true
      };
    }else{
      this.btnConfig = {
        showCancelBtn:true,
        showSaveDraftBtn:true,
        showPublishBtn:true
      };
    }
    if(sessionStorage.getItem('isDuplicate')){
      this.tempType = 'duplicate'
    }
    if(sessionStorage.getItem('templateInfo')){
      this.templateInfo = JSON.parse(sessionStorage.getItem('templateInfo'));     
      this.templateName = this.templateInfo['name'];
      this.templateDescription = this.templateInfo['description'];
      this.addedOfferingsList = this.templateInfo['offerings'];
    }

    this.sub = this._Activatedroute.paramMap.subscribe(params => { 
      console.log(params);
      this.templateId = params.get('id'); 
      if(sessionStorage.getItem('templateInfo')){
        this.templateInfo = JSON.parse(sessionStorage.getItem('templateInfo'));
        this.templateName = this.templateInfo['name'];
        this.templateDescription = this.templateInfo['description'];
        this.addedOfferingsList = this.templateInfo['offerings']
      }else if(this.templateId){
        this.getTemplateInfo();
      }
    });
    
  }

  goBack (event?) {
    this.router.navigate(['/view-template',this.templateId]);
  }

  deleteTemplateEvent(flag){
    if(flag){
      this.deleteTemplate();
    }
  }

  deleteTemplate(){
    if(this.tempStatus == 'Active'){
      let dialogRef =this.dialog.open(WarningModelComponent,{
        data:{
          title:"Delete Confirmation",
          content:"Are you sure you want to delete this Template? This template will be moved to ‘Archived Template’ .",
          actions:[
            {name:'Cancel',type:'close'},
            {name:'Delete',type:'delete'},
          ]
        },
        width:'32.74em'
      });

      dialogRef.afterClosed().subscribe((action) =>{
        if(action == 'delete'){
         this.changeActiveTempStatus();
        }
      })
    }else{
      let dialogRef =this.dialog.open(WarningModelComponent,{
        data:{
          title:"Permanent Delete Confirmation",
          content:"Are you sure you want to delete this Template permanently? You won’t be able to restore it.",
          actions:[
            {name:'Cancel',type:'close'},
            {name:'Delete',type:'delete'},
          ]
        },
        width:'32.74em'
      });

      dialogRef.afterClosed().subscribe((action) =>{
        if(action == 'delete'){
          let id = this.templateInfo['_id'];
          this.prodTemplatesService.showSpinner();
          this.prodTemplatesService.deleteTemplateById(id).subscribe({
            next: (response) => {
              this.prodTemplatesService.hideSpinner();
              this.prodTemplatesService.onChangeInTemplates();
              this.goBack();
            },
            error: (error) => {
              this.prodTemplatesService.hideSpinner();
            }
          });
        }
      })
    }
    
  }

  changeActiveTempStatus () {
    this.prodTemplatesService.showSpinner();
    this.prodTemplatesService.moveTemplateToArchive(this.templateInfo['_id']).subscribe({
      next: (response) => {
        console.log(response);
        this.prodTemplatesService.hideSpinner();
        if (response) {
          this.prodTemplatesService.onChangeInTemplates();
          this.goBack();
        }
      },
      error: (error) => {
        this.prodTemplatesService.hideSpinner();
      }
    });
  }


  cancel ($event) {
    let dialogRef =this.dialog.open(WarningModelComponent,{
      data:{
        title:"Discard Changes",
        content:"Are you sure you want to discard the changes on this Template? You will lose all the changes that you have made.",
        actions:[
          {name:'Cancel',type:'close'},
          {name:'Discard',type:'discard'},
        ]
      },
      width:'32.74em'
    });

    dialogRef.afterClosed().subscribe((action) =>{
      if(action == 'discard'){
        this.router.navigate(['/prod-menu-list/product-templates']);
      }
    })
  }

  saveDraft (data) {
    let fiftyYearsFromNow = new Date(new Date().setFullYear(new Date().getFullYear()+50)).toJSON();
    data['fromDate']= fiftyYearsFromNow;
    let id = this.templateInfo['_id'];
    this.prodTemplatesService.showSpinner();
    this.prodTemplatesService.updateDraftTemplate(id, data).subscribe({
      next: (response) => {
        this.prodTemplatesService.hideSpinner();
        this.prodTemplatesService.onChangeInTemplates();
        this.router.navigate(['/prod-menu-list/product-templates']);
        console.log();
      },
      error: (error) => {
        this.prodTemplatesService.hideSpinner();
        if (error.message && error.message.includes("Name must be unique")) {
          this.isDuplicateTempName = true;
        }
      }
    });
  }

  publish (data) {
    
    this.prodTemplatesService.showSpinner();
    if(this.tempStatus == 'Active'){
      this.prodTemplatesService.SaveActivatedTemplate(this.templateInfo['_id'], data).subscribe({
        next: (response) => {
          this.prodTemplatesService.hideSpinner();
          if (response) {
            console.log(response);
            this.prodTemplatesService.onChangeInTemplates();
            this.prodTemplatesService.setPublishedTemplateStatus('true');
             /** Attention Popup to Sync programs to template */
            if(this.isSyncProgramEnabled && data.offerings.length !== this.templateInfo.totalOfferings) {
              this.syncTemplatesConfirmPopUp();
            } else {
              this.router.navigate(['/prod-menu-list/product-templates']);
            }
          }
        },
        error: (error) => {
          this.prodTemplatesService.hideSpinner();
          this.prodTemplatesService.setPublishedTemplateStatus('false');
          if (error.message && error.message.includes("Name must be unique")) {
            this.isDuplicateTempName = true;
          } else {
            this.router.navigate(['/prod-menu-list/product-templates']);
          }
        }
      });
    }else if(this.tempStatus == 'Draft'){
      data['_id'] = this.templateInfo['_id']
      this.prodTemplatesService.ActivateTemplate(data).subscribe({
        next: (response) => {
          if (response) {
            this.prodTemplatesService.hideSpinner();
            this.prodTemplatesService.onChangeInTemplates();
            this.prodTemplatesService.setPublishedTemplateStatus('true');
            this.router.navigate(['/prod-menu-list/product-templates']);
          }
        },
        error: (error) => {
          this.prodTemplatesService.setPublishedTemplateStatus('false');
          this.prodTemplatesService.hideSpinner();
          if (error.message && error.message.includes("Name must be unique")) {
            this.isDuplicateTempName = true;
          } else {
            this.router.navigate(['/prod-menu-list/product-templates']);
          }
        }
      });
    }
  }

  getTemplateInfo () {
    this.prodTemplatesService.showSpinner();
    this.prodTemplatesService.getTemplateById(this.prepareSearchCriteria()).subscribe({
      next: (response) => {
        console.log(response);
        if (response && response[0]) {
          sessionStorage.setItem('templateInfo', JSON.stringify(response[0]));
          this.templateName = response[0]['name'];
          this.templateDescription = response[0]['description'];
          this.addedOfferingsList = response[0]['offerings']
        }
        this.prodTemplatesService.hideSpinner();
      },
      error: (error) => {
        this.prodTemplatesService.hideSpinner();
      }
    });
  }

  prepareSearchCriteria () {
    return `/${this.templateId}?${Object.keys(this.searchOptions).map(key => `${key}=${this.searchOptions[key]}`).join("&")}`
  }

  syncTemplatesConfirmPopUp() {
    const dialogRef = this.dialog.open(SyncPgmModalComponent, {
      disableClose: true,
      width: '40vw'
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data.action === 'view') {
        this.router.navigate([`/sync-programs-to-template/${this.templateInfo['_id']}`]);
      } else {
        this.router.navigate(['/prod-menu-list/product-templates']);
      }
    })
  }

  getFeatureFlag() {
    this.featureFlagService.getFeatureFlagsBBUrl().subscribe(
      (res) => {
        const featureFlag: FeatureFlag[] = res.items;
        this.isSyncProgramEnabled =  featureFlag && featureFlag.find( ele => ele.key === 'sync_programs' ).on;
      }
    )
  }

  ngOnDestroy(){
    this.sub.unsubscribe();
  }
}
