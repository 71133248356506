import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService, GlobalConfig } from 'ngx-toastr';
import { Benefit, ProdTemplatesService } from '../../services/product-templates.service';
import { BenefitSettingDialogComponent } from '../benefit-setting-dialog/benefit-setting-dialog.component';
import { WarningModelComponent } from '../common/warning-model/warning-model.component';
import { EditBenefitLogicDialogComponent } from '../edit-benefit-logic-dialog/edit-benefit-logic-dialog.component';
import { PointsLogicModalComponent } from '../points-logic-modal/points-logic-modal.component';
import { ConsultantOnlyModalComponent } from '../consultant-only-modal/consultant-only-modal.component';


@Component({
  selector: 'app-sync-programs-to-template',
  templateUrl: './sync-programs-to-template.component.html',
  styleUrls: ['./sync-programs-to-template.component.scss']
})
export class SyncProgramsToTemplateComponent implements OnInit, OnDestroy {
  searchText:string = '';
  templateId: any;
  templateName:string = '';
  templateInfo: any;
  sub: any;
  programsList: Array<any> = [];
  totalPgmsCount = 0;
  searchOptions = {
    pageNumber:1,
    itemsPerPage:10,
    sortDir:'ASC',
    sortColumn:'programName',
    searchText:''
  };
  disableScroll: boolean = false;
  selectedProgram: any;
  selectedProgramOfferings = [];
  /**MOVE pro variables */
  // to capture edit flex point
  points: any;
  rangeIncrementValue: number;
  timeSpanValue = new UntypedFormControl();
  saveAsRetroDisable: boolean;
  CloneCopyDisable: boolean;
  perPointCurrency: number;
  panelOpenState = false;
  editExpiration = false;
  editPolicy = false;
  editpolicyCallRequired = false;
  editContract = false;
  editTotalPoints = false;
  editTotalAmmount = false;
  editAllocationType = false;
  editPoint: Array<boolean> = [];
  editDisplayLogic: Array<boolean> = [];
  editMultiplicity: Array<boolean> = [];
  editIncremental: Array<boolean> = [];
  editIncrementalCurrency: Array<boolean> = [];
  editPerPointCurrency: Array<boolean> = [];
  editIsCartusInsured = false;
  editRaneIncrementVal: Array<boolean> = [];
  editInitialContactRequired = false;
  timespanValues: string[] = ['Hours', 'Days', 'Weeks', 'Months', 'Years'];
  timeSpanValues: string[] = ['Days','Months'];
  isChipEditable: Array<boolean> = [];
  originalBenefitsList: Array<Benefit> = [];
  initialBenefitsList: Array<Benefit> = [];
  newelyAddedBenefits = [];
  isTriggeredAccessAdded : boolean = true;
  public visibleIndex = -1;
  public totalChoice = 0;
  public tiersValues: [];
  public displayNames: any;
  @ViewChild(MatMenuTrigger) matMenuTrigger: MatMenuTrigger;
  @ViewChild('pointVal', { static: true }) pointVal: ElementRef;
  @ViewChildren('focusable') focusable: QueryList<MatButton>;
  @ViewChild('perPointCurrencyVal', { static: true }) perPointCurrencyVal: ElementRef;
  @ViewChildren('title') title: QueryList<ElementRef>;
  @ViewChild('rangeIncrementVal', { static: true }) rangeIncrementVal: ElementRef;
  @ViewChildren('rangeIncrementUnit') rangeIncrementUnit: QueryList<ElementRef>;
  updatedBenefits: Array<Benefit> = [];
  public isMMUser: boolean = false;

  offeringsList: Array<{ group: string, items: Array<Benefit> }>;
  BenefitCategories;
  categoriesList;
  connectedTo = []; //for drag and drop connection
  searchValue: string = null;
  cloneBenefitCount: number = 1;
  options: GlobalConfig;

  disableDone:boolean = true;
  showDone:boolean = false;

  constructor(
        public router: Router,
        public prodTemplatesService: ProdTemplatesService,
        private _Activatedroute:ActivatedRoute,
        public dialog: MatDialog,
        public toastr: ToastrService,
        public cd: ChangeDetectorRef
        ) {
          this.options = this.toastr.toastrConfig;
    }

    ngOnInit(){
      this.templateInfo = sessionStorage.getItem('templateInfo') ?  JSON.parse(sessionStorage.getItem('templateInfo')) : null;
      if(this.templateInfo) {
        this.templateName = this.templateInfo.name;
        this.BenefitCategories = sessionStorage.getItem('categoryDisplayNames') ?  JSON.parse(sessionStorage.getItem('categoryDisplayNames')) : [];
        this.categoriesList = JSON.parse(JSON.stringify(this.BenefitCategories));
        this.initialBenefitsList = this.templateInfo.offerings;
      }
      this.sub = this._Activatedroute.paramMap.subscribe(params => {
        this.templateId = params.get('id');
        this.getTemplateInfo();
      });
    }

    getTemplateInfo () {
      if(this.templateId){
        this.disableScroll = true;
        this.prodTemplatesService.showSpinner();
        this.prodTemplatesService.getTemplateProgramsById(this.prepareSearchCriteria()).subscribe({
          next: (response) => {
            this.programsList = [];
            this.selectedProgramOfferings = [];
            this.prodTemplatesService.hideSpinner();
            if (response && response.hasOwnProperty('programsList') && response['programsList'].length) {
              this.totalPgmsCount = response['count'];
              response['programsList'].forEach(program => {
                this.programsList.push(program);
              })
            }
            this.disableScroll = false;
          },
          error: (error) => {
            this.disableScroll = false;
            this.prodTemplatesService.hideSpinner();
            console.log(error);
            if(error.message) {
              this.dialog.open(WarningModelComponent, {
                data: {
                  title: "Error",
                  content: error.message,
                  actions: [
                    { name: 'Close', type: 'close' },
                  ]
                },
                width: '32.74em'
              });
            }
          }
        });
      }
    }


    goBack () {
      this.router.navigate(['/prod-menu-list/product-templates']);
    }

    prepareSearchCriteria () {
      let params = this.searchOptions;
      if(params.searchText == '') {
        delete params.searchText
      }
      return `/${this.templateId}/client-programs?${Object.keys(params).map(key => `${key}=${params[key]}`).join("&")}`
    }

    goToProgram(program) { 
      this.selectedProgram = program;
      this.prodTemplatesService.showSpinner();
      this.prodTemplatesService.getOfferingsById(program._id).subscribe({
        next: (response) => {
          this.selectedProgramOfferings = []
          this.prodTemplatesService.hideSpinner();
          this.showDone = true;
          if (response && response.hasOwnProperty('benefits') && response['benefits'].length) {
            this.selectedProgramOfferings = response['benefits'];
            this.generateBenefitsInit();
          } else if(response && response.hasOwnProperty('benefits') && !response['benefits'].length) {
            const opt = JSON.parse(JSON.stringify(this.options))
            this.toastr.show(
              `The selected Program doesn't have any newly added benefits`,
              'Warning',
              opt
            );
          }
        },
        error: (error) => {
          this.prodTemplatesService.hideSpinner();
          console.log(error);
          if(error.message) {
            this.dialog.open(WarningModelComponent, {
              data: {
                title: "Error",
                content: error.message,
                actions: [
                  { name: 'Close', type: 'close' },
                ]
              },
              width: '32.74em'
            });
          }
        }
      });
    }

    @HostListener('window:scroll', ['$event'])
    addProgramsToList (event) {
      console.log(event);
      const element = event.target;
      let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight +100;
      let max = document.documentElement.scrollHeight;
      let maxCount = this.totalPgmsCount;
      let loadedProgramsCount = this.searchOptions.pageNumber * this.searchOptions.itemsPerPage;

      // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
      if(!this.disableScroll && (pos >= max) &&  (maxCount > loadedProgramsCount))   {
        this.searchOptions.pageNumber ++;
        this.disableScroll = true;
        this.getTemplateInfo();
      }
    }

    /* To Open Benefit logic dialog for editing*/
  openEditBenefitDialog(benefit) {
    const triggeredAccess = { type: 'triggered_access', name: 'Triggered Access', configurations: {} };
    const allRestrictionList: Array<any> = [
      { type: 'transferee_related', name: 'Transferee with Spouse' },
      { type: 'complex_rules', name: 'Intra-US or intra-Canada', description: 'Moves within the US or within Canada' },
      { type: 'complex_rules', name: 'US/Canada cross-border', description: 'Moves between the US and Canada' },
      { type: 'complex_rules', name: 'International', description: 'US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada' },
      { type: 'complex_rules', name: 'Intra-country', description: 'Moves where the departure country and destination country match' },
      { type: 'complex_rules', name: 'Inter-country', description: 'Moves where the departure country and destination country do not match' },
      { type: 'complex_rules', name: 'Accompanied', description: 'For transferees that are accompanied by a spouse or partner and/or dependents' },
      { type: 'departure_locations', name: 'Departure Location Includes…', includes: [] },
      { type: 'departure_locations', name: 'Departure Location Excludes…', excludes: [] },
      { type: 'destination_locations', name: 'Destination Location Includes…', includes: [] },
      { type: 'destination_locations', name: 'Destination Location Excludes…', excludes: [] },
      { type: 'complex_rules', name: 'Inter-region', description: 'Moves where the departure region and destination region do not match' },
      { type: 'complex_rules', name: 'Intra-region', description: 'Moves where the departure region and destination region match' },
      { type: 'simple_rules', name: 'Number of Relocating Children'}
    ];
  
    if(benefit && benefit.scopes && benefit.scopes.complex_rules && benefit.scopes.complex_rules.length > 0 && benefit.scopes.hasOwnProperty('complex_rules') && benefit.scopes.valid_complex_rules){
      benefit.scopes.valid_complex_rules.forEach(ele => {
        benefit.scopes.complex_rules.findIndex(rule => ele.id === rule.id) >= 0 ? null : benefit.scopes.complex_rules.push(ele);
      })
    }
    let prerequisites = [];
    if(benefit.prerequisiteRule && benefit.prerequisiteRule.length > 0){
      benefit.prerequisiteRule.forEach(item => {
      const index = this.originalBenefitsList.findIndex(benefitRef => benefitRef.reference === item.reference);
      prerequisites.push(this.originalBenefitsList[index]);
    }) };
    //prerequisitesBenefit.length > 0 ? prerequisitesBenefit[0].prerequisites.forEach(ref => prerequisites.push(ref)) : null;
    const benefitList = this.originalBenefitsList.filter(item => item.reference !== benefit.reference && !isNaN(item.points));
    benefitList.sort((a, b) => a.displayName.localeCompare(b.displayName));
    const dialogRef = this.dialog.open(EditBenefitLogicDialogComponent, {
      panelClass: 'dialog-full-width',
      autoFocus: false,
      data: {
        benefit: { ...benefit },
        benefitList: [...benefitList],
        allBenefitList: [...this.initialBenefitsList],
        prerequisites: prerequisites,
        allRestrictionList: allRestrictionList
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        benefit = { ...data.benefit };
        this.addBenefitToUpdatedList(benefit);
        this.updateProductDetails(benefit, 'rules');
        this.updateProductDetails(benefit, 'restriction');
        this.updateProductDetails(benefit, 'tasks');
        if (data.prerequisites) {
          benefit.prerequisiteRule = [];
          data.prerequisites.length > 0 ? data.prerequisites.forEach((item) => {
            const parentBenefit = this.originalBenefitsList.find(benefitObj => benefitObj.reference === item);
            /** to set prerequisite rule for benefit */
            if(benefit.prerequisiteRule && parentBenefit.displayName){ benefit.prerequisiteRule.push(parentBenefit)};
            benefit.prerequisiteRule.sort((a, b) => a.displayName.localeCompare(b.displayName));
            // (benefit.prerequisiteRule? benefit.prerequisiteRule.splice(benefit.prerequisiteRule.findIndex(rule => rule.displayName == parentBenefit.displayName), 1): benefit.prerequisiteRule = []);
            /** to set parent prerequisite */
            parentBenefit.prerequisites && parentBenefit.prerequisites.length > 0 &&
              !parentBenefit.prerequisites.includes(benefit.reference) ?
              parentBenefit.prerequisites.push(benefit.reference) : parentBenefit.prerequisites = [benefit.reference];
            /** to remove prerequisite from benefit */
            const missingPrerequiiste = prerequisites.filter(prerequisite => data.prerequisites.indexOf(prerequisite.reference) < 0);
            missingPrerequiiste.length > 0 ? missingPrerequiiste.forEach(missprerequisite => {
              const missingPrerequiisteBenefit = this.originalBenefitsList.find(benefitObj => benefitObj.reference === missprerequisite.reference);
              missingPrerequiisteBenefit.prerequisites = missingPrerequiisteBenefit.prerequisites.filter(prerequisiteItem => prerequisiteItem !== benefit.reference);
              this.addBenefitToUpdatedList(missingPrerequiisteBenefit)
              this.updateProductDetails(missingPrerequiisteBenefit, 'rules');
            }) : null;

            this.addBenefitToUpdatedList(benefit);
            this.addBenefitToUpdatedList(parentBenefit);
            this.updateProductDetails(benefit, 'rules');
            this.updateProductDetails(parentBenefit, 'rules');

          }) : prerequisites.forEach((item) => {
            const parentBenefit = this.originalBenefitsList.find(benefitObj => benefitObj.reference === item.reference);
            /** to set prerequisite rule for benefit */
            benefit.prerequisiteRule && benefit.prerequisiteRule.length > 0 ?
              benefit.prerequisiteRule = [] : null;
            /**to set parent prerequisite */
            parentBenefit.prerequisites && parentBenefit.prerequisites.length > 0 ?
              parentBenefit.prerequisites.splice(parentBenefit.prerequisites.findIndex(benefitObj => benefitObj === benefit.reference), 1) : null;
            this.addBenefitToUpdatedList(benefit);
            this.addBenefitToUpdatedList(parentBenefit);
            this.updateProductDetails(benefit, 'rules');
            this.updateProductDetails(parentBenefit, 'rules');
          });
        }
      }
    })
  }

    ngOnDestroy() {
      this.sub.unsubscribe();
    }

    searchTemplatePrograms(){
      if(this.searchText && this.searchText.length > 2){
        this.searchOptions.pageNumber = 1;
        this.updateProgramList(this.searchText)
      } else if(!this.searchText){
        this.updateProgramList('')
      }
    }

    updateProgramList(searchText){
      this.programsList = [];
      this.totalPgmsCount = 0;
      this.searchOptions.searchText = searchText;
      this.getTemplateInfo()
    }

    clearsearch(){
      this.searchText = '';
      this.searchTemplatePrograms();
    }

    sortProgramTemplates (columnName) {
      this.programsList = [];
      this.totalPgmsCount = 0;
      this.searchOptions.pageNumber = 1;
      this.searchOptions.sortColumn = columnName;
      this.getTemplateInfo()   
     }

     generateBenefitsInit() {
      //MOVEPRO ngOnInit
      if(this.selectedProgramOfferings.length) {
        if(this.selectedProgramOfferings.filter(benefit => benefit && benefit.hasOwnProperty('amount')) !== undefined && this.selectedProgramOfferings.filter(benefit => benefit && benefit.hasOwnProperty('amount')).length) {
          this.isMMUser = true;
        }
        this.selectedProgramOfferings = this.selectedProgramOfferings.sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.selectedProgramOfferings.forEach((item) => {
            // to add NewlyAddedRef for newlyadded card  to api and delete before sending
            if (item.hasOwnProperty('isNewlyAdded')) {
            item['isNewlyAddedRef'] = true;
            }
            if (item.hasOwnProperty('productSubDetails')) {
              if (item.productSubDetails && item.productSubDetails.hasOwnProperty('internationalProduct')) {
                if (item.productSubDetails && !item.productSubDetails.internationalProduct.hasOwnProperty('requiresRevision')) {
                  item.productSubDetails.internationalProduct.requiresRevision = false;
                }
              } else {
                let req =
                {
                  "productName": "",
                  "subProductName": "",
                  "requiresRevision": false
                }
                item.productSubDetails.internationalProduct = req;
              }
              if (item.productSubDetails && item.productSubDetails.hasOwnProperty('usDomesticProduct')) {
                if (!item.productSubDetails.usDomesticProduct.hasOwnProperty('requiresRevision')) {
                  item.productSubDetails.usDomesticProduct.requiresRevision = false;
                }
              } else {
                let req =
                {
                  "productName": "",
                  "subProductName": "",
                  "requiresRevision": false
                }
                item.productSubDetails.usDomesticProduct = req;
              }
            } else {
              let req = {
                "internationalProduct": {
                  "productName": "",
                  "subProductName": "",
                  "requiresRevision": false
                },
                "usDomesticProduct": {
                  "productName": "",
                  "subProductName": "",
                  "requiresRevision": false
                }
              }
              item.productSubDetails = req;
            }
          });
          this.originalBenefitsList = JSON.parse(JSON.stringify(this.selectedProgramOfferings));
          this.initialBenefitsList = JSON.parse(JSON.stringify(this.selectedProgramOfferings));
          this.configureProgramBenefits(this.selectedProgramOfferings);
        
        this.editPoint.forEach((i, index) => {
          i = false;
          this.editMultiplicity[index] = false;
          this.editDisplayLogic[index] = false;
          this.editIncremental[index] = false;
          this.editRaneIncrementVal[index] = false;
          this.editIncrementalCurrency[index] = false;
          this.editPerPointCurrency[index] = false;
          this.isChipEditable[index] = false;
        });
      }
     }

     configureProgramBenefits(benefitList) {
      let benefits: Benefit[] = this.loadRule(benefitList);
      if (this.isMMUser) {
        const guaranteedBenefits = benefits.filter(benefit => benefit && benefit.hasOwnProperty('amount') && benefit.amount?.toString() === 'Guaranteed');
        benefitList = guaranteedBenefits.concat(benefits.filter(benefit => benefit && benefit.hasOwnProperty('amount') && benefit.amount?.toString() !== 'Guaranteed'));
        this.newelyAddedBenefits.push(benefitList.filter(e => e.isNewlyAdded == true));
      } else {
      const guaranteedBenefits = benefits.filter(benefit => benefit && benefit.hasOwnProperty('points') && benefit.points?.toString() === 'Guaranteed');
      benefitList = guaranteedBenefits.concat(benefits.filter(benefit => benefit && benefit.hasOwnProperty('points') && benefit.points?.toString() !== 'Guaranteed'));
      this.newelyAddedBenefits.push(benefitList.filter(e => e.isNewlyAdded == true));
      }
      if(!this.newelyAddedBenefits.includes(benefitList.filter(e => e.isNewlyAdded == true)))
      this.newelyAddedBenefits.push(benefitList.filter(e => e.isNewlyAdded == true));
      
      benefitList.forEach((item) => {
        !item.consultantBenefitInfo ? item.consultantBenefitInfo = { consultant: false } : null;
        let maxSelection = 0;
        if ((item.advCashOut || item.advCashOutV2) && item.hasOwnProperty('tierConfigs')) {
          item.tierConfigs.forEach((point) => maxSelection = point.maxSelection + maxSelection);
          item.maxSelection = maxSelection;
        }
        //item.scopes.valid_complex_rules = this.getNonDeletedComplex_Rules(item);
      });
      
     if(this.tiersValues != undefined){
      benefitList.map(element=>{
          if(element.displayName === this.displayNames){
            element['tierValues'] = this.tiersValues;
          }
      });
     }
     this.offeringsList = this.groupBenefits(benefitList);
      
    }

    addBenefitToUpdatedList(benefit) {
      const index = this.updatedBenefits.findIndex((data) => data.reference === benefit.reference);
      if (index !== -1) {
        this.updatedBenefits[index] = benefit;
      } else {
        this.updatedBenefits.push(benefit);
      }
    }
  
    getNonDeletedComplex_Rules(benefit: any) {
      let validComplexRule = [];
      validComplexRule = benefit.scopes? benefit.scopes.complex_rules.filter((item) => {
        if (item.action !== 'delete') {
          return item;
        }
      }):null;
      return validComplexRule;
    }

    loadRule(benefitList) {
      benefitList.forEach((benefit, key) => {
        if ((benefit.exclusions && benefit.exclusions.length > 0) || (benefit.inclusions && benefit.inclusions.length > 0) || (benefit.prerequisites && benefit.prerequisites.length > 0)) {
          benefitList[key].excludeRule = [];
          benefitList[key].includeRule = [];
          benefit.exclusions ? benefit.exclusions.forEach(exclude => {
            let index = benefitList.findIndex(item => exclude === item.reference)
            if(index !== -1) {
              const benefitName = benefitList[index].displayName;
              benefitList[key].excludeRule && benefitList[key].excludeRule.length > 0 && benefitList[key].excludeRule.length  ?
                benefitList[key].excludeRule.push(benefitName) :
                benefitList[key].excludeRule = [benefitName];
            }
          }) : benefitList[key].excludeRule = [];
          benefit.inclusions ? benefit.inclusions.forEach(exclude => {
            let index = benefitList.findIndex(item => exclude === item.reference);
            if(index !== -1) {
              const benefitName = benefitList[benefitList.findIndex(item => exclude === item.reference)].displayName;
              benefitList[key].includeRule && benefitList[key].includeRule.length > 0 && benefitList[key].includeRule.length  ?
                benefitList[key].includeRule.push(benefitName) :
                benefitList[key].includeRule = [benefitName];
            }
          }) : benefitList[key].includeRule = [];
          benefit.prerequisites ? benefit.prerequisites.forEach(prerequisite => {
            const parentIndex = benefitList.findIndex(item => prerequisite === item.reference);
            const benefitIndex = benefitList[parentIndex] && benefitList[parentIndex].prerequisiteRule ? benefitList[parentIndex].prerequisiteRule.findIndex(rule => rule.reference === benefitList[key].reference) : null;
            if (benefitList[parentIndex] && benefitList[parentIndex].prerequisiteRule && benefitList[parentIndex].prerequisiteRule.length > 0 &&
              benefitIndex === -1) {
              benefitList[parentIndex].prerequisiteRule.push(benefitList[key])
            } else {
              if (benefitList[parentIndex] && benefitList[parentIndex].prerequisiteRule) {
                benefitList[parentIndex].prerequisiteRule = [benefitList[key]];
              } else if (benefitList[parentIndex] && benefitList[key] && !benefitList[key].prerequisiteRule && !benefitList[parentIndex].prerequisiteRule) {
                benefitList[parentIndex].prerequisiteRule = [benefitList[key]];
              } else if (benefitList[parentIndex] && benefitList[key] && benefitList[key].prerequisiteRule && !benefitList[parentIndex].prerequisiteRule) {
                let keyBenefit = { ...benefitList[key] };
                delete keyBenefit.prerequisiteRule;
                benefitList[parentIndex].prerequisiteRule = [keyBenefit];
              }
            }
          }) : null;
        } else {
          benefit.exclusions && benefit.exclusions.length === 0 ? benefitList[key].excludeRule = [] : null;  
          benefit.inclusions && benefit.inclusions.length === 0 ? benefitList[key].includeRule = [] : null;
          benefit.prerequisites && benefit.prerequisites.length === 0 ? benefitList[key].prerequisiteRule = [] : null;
        }
  
        benefit.scopes? benefit.scopes.valid_complex_rules = this.getNonDeletedComplex_Rules(benefit) : null;
      });
      return benefitList;
    }
  
    public enabledChipToEdit(i,j?:any) {
      //this.maxSelection = this.benefit.rangeMax / this.rangeIncrementValue;
      this.isChipEditable[i] = true;
      if(j && this.offeringsList[j].items[i].hybridTempLiving ){
        this.offeringsList[j].items[i]['preRangeIncrementValue'] =this.offeringsList[j].items[i]['rangeIncrementValue'];
        this.offeringsList[j].items[i]['preRangeIncrementUnit'] =this.offeringsList[j].items[i]['rangeIncrementUnit'];
        this.offeringsList[j].items[i]['preCoreUnitsPerSelection'] =this.offeringsList[j].items[i]['coreUnitsPerSelection'];
      }
      this.offeringsList[j].items[i]['incremental'] ? this.offeringsList[j].items[i]['preMaxSelection'] = this.offeringsList[j].items[i].rangeMax/this.offeringsList[j].items[i]['rangeIncrementValue'] : null;
  
  
    }


    /** to enable backspace for input in mat-chip */
    onMatChipKeyPress(event, value, j, i) {
        if (value) {
          value = value.toString();
          value = value.slice(0, -1);
          this.rangeIncrementValue = value;
        }
    }
    onMatChipKeyPressCore(event, value, j , i) {
      if (value) {
        value = value.toString();
        value = value.slice(0, -1);
        this.offeringsList[j].items[i].coreUnitsPerSelection = value;
      }
    }

    closeRangeIncrementValueMenu(benefit, index) {
      //benefit.rangeMax = this.rangeIncrementValue *benefit.rangeMin;
      benefit.rangeMax = this.rangeIncrementValue * benefit.preMaxSelection;
      benefit.rangeIncrementValue = this.rangeIncrementValue;
      let indexOfBenefit = this.originalBenefitsList.findIndex(item => item.reference === benefit.reference);
      if ((Number(benefit.rangeIncrementValue) > 0) ||
        (Number(benefit.rangeIncrementValue) > 0 && benefit.incrementalTimespan && this.timeSpanValue.value)) {
          benefit.rangeIncrementValue = Number(benefit.rangeIncrementValue);
          benefit.rangeMin > 0 ? benefit.rangeMin = Number(benefit.rangeIncrementValue) : null;
          let maxSelection = benefit.rangeMax / Number(this.rangeIncrementValue);
          benefit.rangeMax = maxSelection * Number(benefit.rangeIncrementValue);
          benefit.incrementalTimespan? benefit.rangeIncrementUnit = this.timeSpanValue.value: null;
          this.originalBenefitsList[indexOfBenefit] = JSON.parse(JSON.stringify(benefit));
          this.addBenefitToUpdatedList(benefit);
        
      } else {
        benefit.rangeIncrementValue = (benefit.rangeMin > 0) ? benefit.rangeMin : this.originalBenefitsList[indexOfBenefit].rangeIncrementValue;
        const opt = JSON.parse(JSON.stringify(this.options))
        this.toastr.show(
          `Please enter value greater than 0`,
          'Warning',
          opt
        );
      }
      this.saveAsRetroDisable = this.originalBenefitsList.filter((item) => {
        return (item.multiplicity ? !(item.maxSelection) || (item.points !== 0 && !item.points) :
          (item.perPointCurrency ? (+item.maxSelection <= 0) : item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')));
      }).length > 0;
      benefit.preMaxSelection = 1;
      this.rangeIncrementValue = 0;
      this.isChipEditable[index] = false;
    }

    openRangeIncrementValueMenu() {
        this.saveAsRetroDisable = true;
    }

    closePerPointCurrencyMenu(benefit) {
      benefit.perPointCurrency = Number(benefit.perPointCurrency);
        if (Number(benefit.perPointCurrency) > 0) {
          this.originalBenefitsList.map(ele => {
            if (ele.reference === benefit.reference) {
              ele.perPointCurrency = benefit.perPointCurrency;
            }
            return ele;
          });
          this.addBenefitToUpdatedList(benefit);
        } else {
          this.originalBenefitsList.forEach(ele => {
            if (ele.reference === benefit.reference) {
              benefit.perPointCurrency = ele.perPointCurrency;
            }
          });
          const opt = JSON.parse(JSON.stringify(this.options))
          this.toastr.show(
            `Please enter value greater than 0`,
            'Warning',
            opt
          );
        }
        this.saveAsRetroDisable = this.originalBenefitsList.filter((item) => {
          return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
            (item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')) || item.clonedCopy);
        }).length > 0;
    }  

    /**
   * To update product object
   * @param benefit benefit
   * @param field modified field
   */
    updateProductDetails(benefit, field: 'title' | 'desc' | 'points' | 'consultantBenefitInfo' | 'rules' | 'restriction' | 'maxSelection' | 'suppress' | 'productSubDetails' | 'isNewlyAdded' | 'isNewlyAddedRef' | 'tasks' | 'isDEIEnabled' | 'isSustainabilityEnabled') {
      const index = this.originalBenefitsList.findIndex((item) => item.reference === benefit.reference);
      if(index != -1){
      if (field === 'title') {
        this.originalBenefitsList[index].displayName = benefit.displayName;
      } else if (field === 'desc') {
        this.originalBenefitsList[index].description = benefit.description;
      } else if (field === 'consultantBenefitInfo') {
        this.originalBenefitsList[index].consultantBenefitInfo = benefit.consultantBenefitInfo;
      } else if (field === 'rules') {
        benefit &&  benefit.exclusions ? this.originalBenefitsList[index].exclusions = [...benefit.exclusions] : null;
        benefit && benefit.inclusions ? this.originalBenefitsList[index].inclusions = [...benefit.inclusions] : null;
        benefit.prerequisites ? this.originalBenefitsList[index].prerequisites = [...benefit.prerequisites] : null;
        benefit.prerequisiteRule ? this.originalBenefitsList[index].prerequisiteRule = [...benefit.prerequisiteRule] : null;
        benefit.excludeRule ? this.originalBenefitsList[index].excludeRule = [...benefit.excludeRule] : null;
        benefit && benefit.includeRule ? this.originalBenefitsList[index].includeRule = [...benefit.includeRule] : null;
      } else if (field === 'restriction') {
        benefit && benefit.scopes ? (benefit.scopes.valid_complex_rules = this.getNonDeletedComplex_Rules(benefit)) : null;
        benefit && benefit.scopes ? this.originalBenefitsList[index].scopes = { ...benefit.scopes } : null;
      } else if (field === 'maxSelection' && (benefit.advCashOut || benefit.advCashOutV2)) {
        benefit.points = 0;
        this.originalBenefitsList[index].tierConfigs = benefit.tierConfigs;
        benefit && benefit.maxSelection ? this.originalBenefitsList[index].maxSelection = benefit.maxSelection : null;
        benefit && benefit.pointsPerSelection ? this.originalBenefitsList[index].pointsPerSelection = benefit.pointsPerSelection: null;
      } else if (field === 'suppress') {
        this.originalBenefitsList[index].suppress = benefit.suppress;
      } else if (field === 'productSubDetails') {
        this.originalBenefitsList[index].productSubDetails = benefit.productSubDetails;
      } else if (field === 'isNewlyAdded') {
        this.originalBenefitsList[index].isNewlyAdded = benefit.isNewlyAdded;
      } else if (field === 'isNewlyAddedRef') {
        this.originalBenefitsList[index].isNewlyAddedRef = benefit.isNewlyAddedRef;
      } else if(field === 'isDEIEnabled') {
        this.originalBenefitsList[index].isDEIEnabled = benefit.isDEIEnabled;
      } else if(field === 'isSustainabilityEnabled') {
        this.originalBenefitsList[index].isSustainabilityEnabled = benefit.isSustainabilityEnabled
      } else if (field === 'tasks') {
        this.originalBenefitsList[index].tasks = benefit.tasks;
      } else {
        this.originalBenefitsList[index].points = benefit.points;
      }
     }
    }

  editProgramTitle(benefit) {
    if (benefit && benefit.editTitle) {
      this.updateProductDetails(benefit, 'title');
      delete benefit.editTitle;
      this.addBenefitToUpdatedList(benefit);
      this.saveAsRetroDisable = false;
    } else {
      benefit.editTitle = true;
      this.saveAsRetroDisable = true;
    }
  }

  openPointMenu() {
    this.saveAsRetroDisable = true;
  }

  /**
 * To update rangeMax value of benefit object
 * @param benefit benefit
 * @param rangeMax modified field
 */
  editRangeMax(benefit) {
    if (benefit.incremental) {
      benefit.maxSelection = benefit.rangeMax / benefit.rangeIncrementValue;
      this.addBenefitToUpdatedList(benefit);
    }
  }

  /**
   * @param benefit benefit
   * @param flag editIncrementCurrency[i] value
   * called on icon click for Dollar Per Point card
   */
  editMaxSelection(benefit, flag) {
    if (benefit.incrementalCurrency) {
      isNaN(benefit.rangeMax) ? benefit.rangeMax = 0 : null;
      benefit.maxSelection = benefit.rangeMax / benefit.rangeIncrementValue;
      (benefit.rangeMax / benefit.rangeIncrementValue === 0) ? benefit.maxSelection = 0 :
        this.addBenefitToUpdatedList(benefit);

      let checkLength = this.originalBenefitsList.filter((item) => {
        return (item.multiplicity ? !(item.maxSelection) || (item.points !== 0 && !item.points) :
          (item.perPointCurrency ? (+item.maxSelection <= 0) : item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')));
      }) //.length > 0;
      !flag ? this.saveAsRetroDisable = checkLength.length > 0 : this.saveAsRetroDisable = true;
    }
  }
  
  /**
   * To be invoked on click of edit icon for description
   * @param benefit benefit
   */
   editProgramDescription(benefit) {
    if (benefit && benefit.editDescription) {
      this.updateProductDetails(benefit, 'desc');
      delete benefit.editDescription;
      this.addBenefitToUpdatedList(benefit);
      this.saveAsRetroDisable = false;
    } else {
      benefit.editDescription = true;
      this.saveAsRetroDisable = true;
    }
  }

   /**
   * This function is used to open list of tiervalue as per index selection
   * @param index
   */
  public onChoiceIconClick(index){
    if (this.visibleIndex === index) {
      this.visibleIndex = -1;
    } else {
      this.visibleIndex = index;
    }
  }

  closePointMenu(benefit) {
    const index = this.originalBenefitsList.findIndex((item) => item.reference === benefit.reference);
    this.originalBenefitsList[index].points = benefit.points !== 'Guaranteed' && benefit.points && benefit.points !== 0 ?
      Number(benefit.points) : benefit.points;
    if(benefit.maxSelection == null) {
      benefit.maxSelection = 0;
      this.saveAsRetroDisable = true;
    }
    this.originalBenefitsList[index].maxSelection = +benefit.maxSelection;
    this.saveAsRetroDisable = this.originalBenefitsList.filter((item) => {
      return (item.multiplicity ? !(item.maxSelection) || (item.points !== 0 && !item.points) :
        (item.perPointCurrency ? (+item.maxSelection <= 0) : item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')));
    }).length > 0;
    this.addBenefitToUpdatedList(benefit);
    if (benefit.incremental || benefit.incrementalCurrency) {
      if (benefit.maxSelection <= 0 || benefit.maxSelection == "") {
        benefit.maxSelection = 0;
        this.saveAsRetroDisable = true;
      }
      benefit.rangeMax = (benefit.maxSelection * benefit.rangeIncrementValue);
      this.originalBenefitsList.map(ele => {
        if (ele.reference === benefit.reference) {
          ele.rangeMax = benefit.rangeMax;
        }
        return ele;
      })
      this.addBenefitToUpdatedList(benefit);
    }
    if(this.isMMUser) {
      this.saveAsRetroDisable = false;
    }
  }

  groupBenefits(benefits: Array<Benefit>): Array<{ group: string, items: Array<Benefit> }> {
    const benefitsByGroup = this.BenefitCategories.map(category => {
      if(category){
        this.connectedTo.push(category);
        return {
          group: category,
          items: benefits.filter(benefit => benefit.category === category).sort((a, b) => a.displayName.localeCompare(b.displayName))
        };
      }
    });
    return benefitsByGroup;
  }

  
  /** Rearranges original benefit array with updated category of dragged item*/
  changeCategory(benefit, category) {
      this.originalBenefitsList.filter(ele => {
        if (ele.reference === benefit.reference && ele.category !== category) {
          ele.category = category;
        }
      })
      this.updatedBenefits = this.originalBenefitsList;
      setTimeout(()=>{
        this.offeringsList = this.groupBenefits(this.originalBenefitsList);
      },0)
  }

  
  textSearchBenefits(searchText) {
    if (searchText && searchText.length >= 3 || searchText.length === 0) {
      const benefitList = this.loadRule(this.originalBenefitsList);
      const searchBenefits = benefitList.filter(benefit =>
        benefit.displayName.toLowerCase().search(searchText.toLowerCase()) !== -1);
      this.offeringsList = this.groupBenefits(searchBenefits);
      this.searchValue = searchText;
    }
  }

  textSearch(event) {
    if (event.key === 'Backspace' && event.target.value.length === 0) {
      this.textSearchBenefits(event.target.value);
    }
  }

  clearTextSearch() {
    this.offeringsList = this.groupBenefits(this.originalBenefitsList);
    this.searchValue = '';
  }

  closeHybrid(i, j) {
    this.isChipEditable[i] = !this.isChipEditable[i];
    this.offeringsList[j].items[i].rangeIncrementValue = this.offeringsList[j].items[i].preRangeIncrementValue;
    this.offeringsList[j].items[i].rangeIncrementUnit = this.offeringsList[j].items[i].preRangeIncrementUnit;
    this.offeringsList[j].items[i].coreUnitsPerSelection = this.offeringsList[j].items[i].preCoreUnitsPerSelection;
    delete this.offeringsList[j].items[i].preRangeIncrementValue;
    delete this.offeringsList[j].items[i].preRangeIncrementUnit;
    delete this.offeringsList[j].items[i].preCoreUnitsPerSelection;
  }

  sortCategories() {
    this.categoriesList.sort();
  }

  deleteBenefit(benefit: Benefit) {
      benefit.suppress = !benefit.suppress;
      this.updateProductDetails(benefit, 'suppress');
      this.addBenefitToUpdatedList(benefit);
  }

  deleteisNewlyAdded(benefit: Benefit) {
      benefit.isNewlyAddedRef = !benefit.isNewlyAddedRef;
      this.addBenefitToUpdatedList(benefit);
      this.updateProductDetails(benefit, 'isNewlyAddedRef');
  }

  groupIndex: number = 0;
  itemIndex: number = 0;

  cloneBenefit(benefit: Benefit, el) {
    this.groupIndex = this.offeringsList.findIndex(xs => xs.group === benefit.category)
    this.itemIndex = this.groupIndex > -1 && this.offeringsList[this.groupIndex] &&  this.offeringsList[this.groupIndex].items ? this.offeringsList[this.groupIndex].items.findIndex((list: Benefit) => list.reference === benefit.reference) : null;
    if(this.groupIndex > -1 && this.itemIndex > -1) {
      if (benefit.clonedCopy) {
        delete this.offeringsList[this.groupIndex].items[this.itemIndex].clonedCopy;
        delete this.offeringsList[this.groupIndex].items[this.itemIndex - 1].parentClonedCopy;
        this.addBenefitToUpdatedList(this.offeringsList[this.groupIndex].items[this.itemIndex]);
        this.CloneCopyDisable = false;
        this.saveAsRetroDisable = this.originalBenefitsList.filter((item) => {
          return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
            (item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')) || item.clonedCopy);
        }).length > 0
      } else {
        var re = "_BBClone";
        let cloneBenefit = JSON.parse(JSON.stringify(benefit));
        this.offeringsList[this.groupIndex].items.splice(this.itemIndex + 1, 0, {
          ...cloneBenefit,
          displayName: "Clone of " + benefit.displayName,
          reference: benefit.reference.includes("_BBClone_") ? benefit.reference.slice(0, -1) + (+benefit.reference.slice(-1) + 1) : benefit.reference + "_BBClone_" + this.cloneBenefitCount,
          isCloned: true,
          clonedCopy: true,
          editTitle: true,
          clonedFrom: benefit.reference.includes("_BBClone_") ? benefit.reference.slice(0, benefit.reference.lastIndexOf(re)) : benefit.reference
        });
        this.cloneBenefitCount++;
  
        this.offeringsList[this.groupIndex].items[this.itemIndex].parentClonedCopy = true;
        this.originalBenefitsList.push(this.offeringsList[this.groupIndex].items[this.itemIndex + 1]);
        this.selectedProgramOfferings.push(this.offeringsList[this.groupIndex].items[this.itemIndex + 1]);

        this.saveAsRetroDisable = this.originalBenefitsList.filter((item) => {
          return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
            (item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')) || item.clonedCopy);
        }).length > 0
        this.CloneCopyDisable = this.saveAsRetroDisable;
  
        setTimeout(() => {
          this.focusable ? this.focusable?.last?._elementRef?.nativeElement?.focus() : null;
          console.log(this.title);
          this.title ? this.title?.last?.nativeElement?.focus(): null;
        }, 0);
      }
    }
   
    if(this.isMMUser) {
      this.saveAsRetroDisable = false;
    }
  }

  discardBenefit(benefit, j, i) {   
    this.cloneBenefitCount--;
    this.offeringsList[j].items.splice(i, 1);
    delete this.offeringsList[j].items[this.offeringsList[j].items.findIndex(item => benefit.clonedFrom == item.reference)].parentClonedCopy;
    this.originalBenefitsList.splice(this.originalBenefitsList.findIndex(item => benefit.reference === item.reference), 1); 
    this.selectedProgramOfferings.splice(this.selectedProgramOfferings.findIndex(item => benefit.reference === item.reference), 1); 
    this.saveAsRetroDisable = this.originalBenefitsList.filter((item) => {
      return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
        item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2') && item.clonedCopy);
    }).length > 0;
    this.saveAsRetroDisable = false;
    this.CloneCopyDisable = false;

  }

  editSustanabilityDEI(benefit, type) {
    this.updateProductDetails(benefit, type);
    this.addBenefitToUpdatedList(benefit);
    console.log(type +':' + benefit[type])
  }

  openConsultantModal(benefit: any) { 
    if ((benefit.consultantBenefitInfo && !benefit.consultantBenefitInfo?.consultant) || !benefit?.consultantBenefitInfo) {
      const dialogRef = this.dialog.open(ConsultantOnlyModalComponent, {
        panelClass: 'dialog-full-width',
        autoFocus: false,
        disableClose: true,
        data: {
          benefit: benefit
        }
      });
    

      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          benefit.consultantBenefitInfo = data;
            this.addBenefitToUpdatedList(benefit);
          this.updateProductDetails(benefit, 'consultantBenefitInfo');
        }
      });
    } else if (benefit.consultantBenefitInfo) {
      benefit.consultantBenefitInfo.qualifyingInput = 0;
      this.addBenefitToUpdatedList(benefit);
      this.updateProductDetails(benefit, 'consultantBenefitInfo');
    }
  }
  // open cashout/points logic modal
  openPointsLogicModal(benefit: any) {
      const dialogRef = this.dialog.open(PointsLogicModalComponent, {
        panelClass: 'dialog-full-width',
        autoFocus: false,
        data: {
          benefit: { ...benefit },
          program: this.selectedProgram
        }
      });
      dialogRef.afterClosed().subscribe(data => {
        if (data && data.benefit) {
          this.cd.detectChanges()
          benefit.maxSelection = data.benefit.maxSelection;
          benefit.tierConfigs = data.benefit.tierConfigs;
          benefit.tierValues = data.benefit.tierValues;
          this.tiersValues = data.benefit.tierValues;
          this.displayNames= data.benefit.displayName
          benefit.pointsPerSelection? benefit.pointsPerSelection = data.benefit.pointsPerSelection: null;
          this.addBenefitToUpdatedList(benefit);
          this.updateProductDetails(benefit, 'maxSelection');
         this.configureProgramBenefits(this.originalBenefitsList);
        }
        if(this.isMMUser) {
          this.saveAsRetroDisable = true;
        }
      });
    }
    

  
  benefitSetting(benefit: Benefit) {
    const dialogRef = this.dialog.open(BenefitSettingDialogComponent, {
      data: {
        description : benefit.description ,
        displayName : benefit.displayName,
        internationalProductName :benefit.productSubDetails && benefit.productSubDetails.internationalProduct.productName,
        usDomesticProductName :benefit.productSubDetails && benefit.productSubDetails.usDomesticProduct.productName,
        internationalSubProductName :benefit.productSubDetails && benefit.productSubDetails.internationalProduct.subProductName,
        usDomesticSubProductName  :benefit.productSubDetails && benefit.productSubDetails.usDomesticProduct.subProductName,
        internationlRevision :benefit.productSubDetails && benefit.productSubDetails.internationalProduct.requiresRevision,
        usDomesticRevision :benefit.productSubDetails && benefit.productSubDetails.usDomesticProduct.requiresRevision,
      },
      width: '35vw',
      height:'50vw',
      autoFocus: false,
      restoreFocus: false,
      disableClose: true,
      panelClass: 'product-settings-dialog-container'
    });
  
    dialogRef.afterClosed().subscribe(data =>{
      if (data && data.data && data.data.productSubDetails) {
        benefit.productSubDetails.internationalProduct.productName = data.data.productSubDetails.internationalProduct.productName;
        benefit.productSubDetails.internationalProduct.subProductName = data.data.productSubDetails.internationalProduct.subProductName;
        benefit.productSubDetails.internationalProduct.requiresRevision = data.data.productSubDetails.internationalProduct.requiresRevision;
        benefit.productSubDetails.usDomesticProduct.productName = data.data.productSubDetails.usDomesticProduct.productName
        benefit.productSubDetails.usDomesticProduct.subProductName = data.data.productSubDetails.usDomesticProduct.subProductName
        benefit.productSubDetails.usDomesticProduct.requiresRevision = data.data.productSubDetails.usDomesticProduct.requiresRevision;
        this.addBenefitToUpdatedList(benefit);
        this.updateProductDetails(benefit, 'productSubDetails');
        this.configureProgramBenefits(this.originalBenefitsList);
      }
    })
   }

  saveConfirmModel() {
    
    let dialogRef = this.dialog.open(WarningModelComponent, {
      data: {
        title: "Sync the offerings to the Program",
        content: "Are you sure you want to sync the offerings to the Program?",
        actions: [
          { name: 'Cancel', type: 'close' },
          { name: 'Confirm', type: 'confirm' },
        ]
      },
      width: '32.74em'
    });

    dialogRef.afterClosed().subscribe((action) => {
      if (action == 'confirm')
        this.saveAsRetroactive()
        
    })

  }

   
  updateBenefitForProgram(updatedBenefits = []) {
    const updatedlist = updatedBenefits.map((data) => {
      delete data.editTitle;
      delete data.editDescription;
      data.points = isNaN(data.points) ? data.points : parseFloat(data.points);
      delete data.editPoint;
      if (data.multiplicity || data.advCashOut || data.advCashOutV2) {
        data.maxSelection = isNaN(data.maxSelection) ? data.maxSelection : parseFloat(data.maxSelection);
      } else {
        delete data.maxSelection;
      }
      const index = this.originalBenefitsList.findIndex(benefit => benefit.reference === data.reference);
      if(data.consultantBenefitInfo && !data.consultantBenefitInfo.consultant && this.originalBenefitsList[index] &&
        (!this.originalBenefitsList[index].consultantBenefitInfo) || (this.originalBenefitsList[index].consultantBenefitInfo && !this.originalBenefitsList[index].consultantBenefitInfo.consultant) ) {
          delete data.consultantBenefitInfo;
      } else if(data.consultantBenefitInfo && !data.consultantBenefitInfo.consultant && this.originalBenefitsList[index] &&
          this.originalBenefitsList[index].consultantBenefitInfo && !this.originalBenefitsList[index].consultantBenefitInfo.consultant ) {
        delete data.consultantBenefitInfo.qualifyingInput;
      } 
      data.prerequisiteRule ? delete data.prerequisiteRule : null;
      data.excludeRule ? delete data.excludeRule : null;
      data.includeRule ? delete data.includeRule : null;
      data.parentClonedCopy? delete data.parentClonedCopy : null;
      data.scopes && data.scopes.valid_complex_rules ? delete data.scopes.valid_complex_rules : null;
      data.hasOwnProperty('currencyCode') ? delete data.currencyCode : null;
      return data;
    });
    let requestObj = {
      benefits: JSON.parse(JSON.stringify(updatedlist))
    };
    

    //removing maxSelection only for cash out card from request object
    requestObj.benefits = [...requestObj.benefits.map(benefit => {
      if (benefit.advCashOut || benefit.advCashOutV2) {
        delete benefit.maxSelection;
      }
      if (benefit.incrementalTimespan){
        delete benefit.incrementalTimespan;
      }
      if (benefit.isNewlyAddedRef) {
        delete benefit.isNewlyAddedRef;
      }
      if (benefit.preRangeIncrementValue) {
        delete benefit.preRangeIncrementValue;
      }
      if (benefit.preRangeIncrementUnit) {
        delete benefit.preRangeIncrementUnit;
      }
      if (benefit.preCoreUnitsPerSelection) {
        delete benefit.preCoreUnitsPerSelection;
      }
      if(benefit.parentClonedCopy) {
        delete benefit.parentClonedCopy;
      }
      benefit.preMaxSelection ? delete benefit.preMaxSelection : null;
      return benefit;
    })];

    this.prodTemplatesService.showSpinner();
    //for touched benefits
    requestObj.benefits.map(benefit => {
      if (benefit.hasOwnProperty('isNewlyAddedRef')) {
        delete benefit.isNewlyAddedRef;
      }
      if (benefit.hasOwnProperty('isNewlyAdded')) {
        benefit.isNewlyAdded = true;
      }
    });
    // for untouched benefits
    let unTouchedBenefits = [];
    
    if(this.newelyAddedBenefits && this.newelyAddedBenefits.length > 0) {
      this.newelyAddedBenefits[0].forEach(e => {
        if (e.incrementalTimespan) {
          delete e.incrementalTimespan;
        }
        if (e.isNewlyAddedRef) {
          unTouchedBenefits.push(e)
        }
      })
    }

    if(unTouchedBenefits && unTouchedBenefits.length > 0) {
      unTouchedBenefits.forEach(e => {
        if (e.incrementalTimespan) {
          delete e.incrementalTimespan;
        }
        if (e.hasOwnProperty('isNewlyAddedRef')) {
          e.isNewlyAdded = false;
          delete e.isNewlyAddedRef;
        }
        if (e.hasOwnProperty('scopes')) {
          delete e.scopes.valid_complex_rules ;
        }
        e.hasOwnProperty('excludeRule') ? delete e.excludeRule : null;
        e.hasOwnProperty('includeRule') ? delete e.includeRule : null;
        requestObj.benefits.push(e)
      });
    }

    this.prodTemplatesService.updateBenefitForProgram(requestObj, this.selectedProgram._id).subscribe({
      next: (data) => {
        if (data) {
          this.prodTemplatesService.hideSpinner();
          const toastmsg = 'Program synced successfully';
          const opt = JSON.parse(JSON.stringify(this.options));
          this.toastr.show(
            toastmsg,
            'Success',
            opt
          );
          setTimeout(() => {
            this.getTemplateInfo();
            this.saveAsRetroDisable = true;
            this.disableDone = false;
            this.showDone = true;
          }, 2000);
        } else {
          this.prodTemplatesService.hideSpinner();
          const toastmsg = 'Error while updating the benefits, please try again';
          const opt = JSON.parse(JSON.stringify(this.options));
          this.toastr.show(
            toastmsg,
            'Error',
            opt
          );
        }
      },
      error: (error) => {
        console.log(error);
        this.prodTemplatesService.hideSpinner();
        const httpErr = error.toString().split(':')[2];
          const opt = JSON.parse(JSON.stringify(this.options));
          this.toastr.show(
            httpErr,
            'Error',
            opt
          );
      }
    });
   
  }

  saveAsRetroactive() {
    const cashOutBenefit = this.originalBenefitsList.find(item => (item.advCashOut || item.advCashOutV2) && !item.suppress);
    const cashOutMMUBenefit = this.originalBenefitsList.find(item => (item.cashOut) && !item.suppress);
    const singleSelectmatch = this.originalBenefitsList.filter(item => (item.singleSelectTier && !item.suppress && (!item.hasOwnProperty('tierValues') || (item.tierValues && item.tierValues.length === 0))))
    .map(ele => {
      return ele.displayName
    });
    this.isTriggeredAccessAdded = true;
    const opt = JSON.parse(JSON.stringify(this.options));
    if (cashOutBenefit && (!cashOutBenefit.tierConfigs || cashOutBenefit.tierConfigs.length === 0)) {
      this.toastr.show(
        `Please configure the cash out logic for <br> Cash Out Card before Publishing for Use.`,
        'Error',
        opt
      );
    } //logic for cashout for MMClient with triggeredaccess info
    else if (cashOutMMUBenefit && (cashOutMMUBenefit.scopes && (!cashOutMMUBenefit.scopes.triggered_access || cashOutMMUBenefit.scopes.triggered_access.action))) {
      this.isTriggeredAccessAdded = false;
      this.toastr.show(
        `Please configure triggered access logic for ${cashOutMMUBenefit.displayName} card before Publishing for Use`,
        'Error',
        opt
      );
    }
    else if (singleSelectmatch && singleSelectmatch.length > 0) {
      this.toastr.show(
        `Please configure the Variable Benefit Choices logic for ${singleSelectmatch.join(" , ")} card before Publishing for Use`,
        'Error',
        opt
      );
    } else {

      const benefitList: Array<Benefit> = Array.prototype.concat.apply([], [...this.offeringsList
        .map(group => group.items.map(benefit => benefit))
      ]);

      const suppressList = benefitList.filter(benefit => benefit.suppress).map(benefit => benefit.reference);
      let originalBenefitList = JSON.parse(JSON.stringify(this.originalBenefitsList));
      originalBenefitList = originalBenefitList.map(benefit => {
        if (suppressList.includes(benefit.reference)) {
          benefit.suppress = true;
        }
        benefit.points = isNaN(benefit.points) ? benefit.points : benefit && benefit.points ? parseFloat(benefit.points.toString()) : benefit.points;
        delete benefit.icon;
        if (benefit.multiplicity || benefit.advCashOut || benefit.advCashOutV2) {
          benefit.maxSelection = isNaN(benefit.maxSelection) ? benefit.maxSelection : benefit && benefit.maxSelection ?  parseFloat(benefit.maxSelection.toString()) : benefit.maxSelection;
        } else {
          delete benefit.maxSelection;
        }
        if (benefit.incrementalTimespan) {
          delete benefit.incrementalTimespan;
        }
        if (benefit.isNewlyAddedRef) {
          delete benefit.isNewlyAddedRef;
        }
        if (benefit.consultantBenefitInfo && !benefit.consultantBenefitInfo.consultant) {
          delete benefit.consultantBenefitInfo;
        }
        if(benefit.parentClonedCopy) {
          delete benefit.parentClonedCopy;
        }
        if(benefit.parentClonedCopy) {
          delete benefit.parentClonedCopy;
        }
        if (benefit.preRangeIncrementValue) {
          delete benefit.preRangeIncrementValue;
        }
        if (benefit.preRangeIncrementUnit) {
          delete benefit.preRangeIncrementUnit;
        }
        if (benefit.preCoreUnitsPerSelection) {
          delete benefit.preCoreUnitsPerSelection;
        }
        (benefit && benefit.preMaxSelection) ? delete benefit.preMaxSelection : null;
        benefit.editDescription || benefit.editDescription == false ? delete benefit.editDescription : null;
        benefit.editTitle || benefit.editTitle == false ? delete benefit.editTitle : null;
        (benefit && benefit.prerequisiteRule) ? delete benefit.prerequisiteRule : null;
        (benefit && benefit.excludeRule) ? delete benefit.excludeRule : null;
        (benefit && benefit.includeRule) ? delete benefit.includeRule : null;
        (benefit && benefit.scopes && benefit.scopes.valid_complex_rules) ? delete benefit.scopes.valid_complex_rules : null;
        benefit.hasOwnProperty('currencyCode') ? delete benefit.currencyCode : null;
        return benefit;
      });

      this.updateBenefitForProgram(originalBenefitList);
    }
  }

}

