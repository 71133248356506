<div class="dialog-container" role="region">
    <!-- Title of pop-up -->
    <div class="product-title" mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between"
      fxLayoutGap="10px" fxLayoutGap.xs="0em" role="heading">
      <h2 class="divisions-title">Product Settings</h2>
      <a class="close-icon" (click)="close()" title="close" role="button" aria-label="Close Program Modal"
        title="Close Program Modal">
        <mat-icon>close</mat-icon>
      </a>
    </div>
    <!--dialog-title start  -->
    <div class="dialog-title-container">
      <div class="dialog-wrapper">
        <div class="dialog-title-card">
          <mat-card class="mat-elevation-z8">{{data.displayName}}</mat-card>
        </div>
        <div class="dialog-title-desc">
          <p *ngIf=!showMore>
            {{(showMore) ? data.description : data.description | slice:0:470}} <span *ngIf="!showMore">...</span>
            <a href="javascript:;" *ngIf="!showMore" (click)="showMore=true">[Show Next]</a>
          </p>
          <p *ngIf=showMore>
            {{(!showMore) ? data.description : data.description | slice:471:940}} <span *ngIf="showMore">...</span>
            <a href="javascript:;" *ngIf="showMore" (click)="showMore=false">[Show Previous]</a>
          </p>
        </div>
      </div>
    </div>
  
    <form [formGroup]="productSettingsForm">
      <div class="international-products">
        <mat-card  class="product-setting-card">
          <div style="padding-top: 10px;">
            <mat-icon class="internation-products-icon">public</mat-icon>
            <mat-card-header>
              <mat-card-title>International Products Details</mat-card-title>
            </mat-card-header>
          </div>
          <div class="product-setting-form">
            <mat-form-field class="product-setting-full-width">
              <mat-select
                placeholder="Select international product" [(value)]="internationalProductName" formControlName="intProductName"
                (selectionChange)="changeInternationalProduct($event.value)">
                <mat-form-field class="product-setting-full-width">
                  <input matInput placeholer="Search" formControlName="intProductNameSearch" (keyup)="internationalUpdateFilter($event)" />
                  <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" class="close-btn"
                    (click)="internationalClearSearch()" role="button">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-option *ngFor="let option of productList;let i=index" [value]="option.productName">
                  {{option.productName }}
                </mat-option>
              </mat-select>
              <button mat-button matSuffix aria-label="search" (click)="onInternationalClear()" class="close-button">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <span>
              <mat-icon class="arrow-icon">subdirectory_arrow_right</mat-icon>
            </span>
            <mat-form-field class="product-setting-full-width2">
              <mat-select
                placeholder="Select international subproduct" name="internationalSubproduct " formControlName="intSubproductName">
                <!-- <ng-container *ngIf="InternationalsubProduct != ''"> -->
                <ng-container *ngIf="InternationalsubProduct && InternationalsubProduct.length > 0">
                  <mat-option *ngFor="let option of InternationalsubProduct" [value]="option">
                    {{option}}
                  </mat-option>
                </ng-container>
              </mat-select>
              <!-- <button mat-button matSuffix aria-label="search" (click)='internationalSubproduct=""' class="close-button"> -->
              <button mat-button matSuffix aria-label="search" class="close-button">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="international-toggle">
            <span>
              <mat-label>Requires Revision &nbsp;&nbsp;&nbsp;</mat-label>
              <mat-label>No&nbsp;</mat-label>
              <mat-slide-toggle
                formControlName="internationalToggle"></mat-slide-toggle>
              <mat-label>&nbsp;Yes</mat-label>
            </span>
          </div>
        </mat-card>
      </div>
      <div class="international-products">
        <mat-card class="product-setting-card">
          <div style="padding-top: 10px;">
            <mat-icon class="internation-products-icon">local_shipping</mat-icon>
            <mat-card-header>
              <mat-card-title>US Domestic Product Details</mat-card-title>
            </mat-card-header>
          </div>
          <div class="product-setting-form">
            <mat-form-field class="product-setting-full-width">
              <mat-select
                placeholder="Select Domestic product" [(value)]="domesticProduct" formControlName="domesticProductName"
                (selectionChange)="changeDomesticProduct($event.value)">
                <mat-form-field class="product-setting-full-width">
                  <input matInput placeholer="Search" formControlName="domesticProductNameSearch" (keyup)="domesticUpdateFilter($event)" />
                  <button tabindex="0" (click)="domesticClearSearch()" mat-button matSuffix mat-icon-button
                    aria-label="Clear" class="close-btn" role="button">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-option *ngFor="let option of domesticProductList;let i=index" [value]="option.productName">
                  {{option.productName}}
                </mat-option>
              </mat-select>
              <button mat-button matSuffix aria-label="search" (click)="onDomesticClear()" class="close-button">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <span>
              <mat-icon class="arrow-icon">subdirectory_arrow_right</mat-icon>
            </span>
            <mat-form-field class="product-setting-full-width2">
              <mat-select
                name="domesticSubproduct" placeholder="Select Domestic subproduct (optional)"
                formControlName="domesticSubproductName">
                <!-- <ng-container *ngIf="domesticsubProduct != ''"> -->
                <ng-container *ngIf="domesticsubProduct && domesticsubProduct.length > 0">
                  <mat-option *ngFor="let option of domesticsubProduct" [value]="option">
                    {{option}}
                  </mat-option>
                </ng-container>
              </mat-select>
              <!-- <button mat-button matSuffix aria-label="search" (click)='clearInput() ' class="close-button"> -->
              <button mat-button matSuffix aria-label="search" class="close-button">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="international-toggle">
            <span>
              <mat-label>Requires Revision &nbsp;&nbsp;&nbsp;</mat-label>
              <mat-label>No&nbsp;</mat-label>
              <mat-slide-toggle
                formControlName="domesticToggle"></mat-slide-toggle>
              <mat-label>&nbsp;Yes</mat-label>
            </span>
          </div>
        </mat-card>
      </div>
    </form>
    <mat-dialog-actions class="button-action" fxLayout="row" fxLayoutAlign="end end">
      <button mat-button class="text-button rest-btn" (click)="close()">CANCEL</button>
      <button
        [disabled]="!checkIsValid()"
        *ngIf="productSettingsForm.pristine" mat-button (click)="onSaveClick()"
        class="contained-button width-button">OK</button>
      <button
        [disabled]="!checkIsValid()"
        *ngIf="productSettingsForm.dirty" mat-button (click)="onSaveClick()"
        class="contained-button width-button">Update</button>
    </mat-dialog-actions>
  </div>