import { Component, OnInit } from '@angular/core';
import { ProdOfferingsService } from 'src/app/core/services/product-offerings.service';

import { RestrictionBaseComponent } from '../../common/restriction-base.component';


@Component({
  selector: 'app-toggle-uitype-restriction',
  templateUrl: './toggle-uitype-restriction.component.html',
  styleUrls: ['./toggle-uitype-restriction.component.scss']
})
export class ToggleUitypeRestrictionComponent extends RestrictionBaseComponent implements OnInit {

  constructor(public prodOfferingsService : ProdOfferingsService) { 
    super(prodOfferingsService);
  }

  ngOnInit () {
    if(this.parentForm){
      this.parentForm.get('values').get(0+'').clearValidators();
      this.parentForm.updateValueAndValidity();
    }
  }
  
}
