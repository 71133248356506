import { Inject } from '@angular/core';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-warning-model',
  templateUrl: './warning-model.component.html',
  styleUrls: ['./warning-model.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WarningModelComponent implements OnInit {

  constructor(public openDialogs: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public dialogData:any) { }

  ngOnInit(): void {
    console.log(this.dialogData);
  }

  closeDialog(action){
    this.openDialogs.close(action);
  }
}
