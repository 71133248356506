import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProdOfferingsService } from '../../services/product-offerings.service';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit {

  selectedFilters: Array<any> = [];
  applicationNameValues = [];
  categories = [];
  productNameValues = [];
  createdByValues = [];
  filterTypeValues;
  filterTypes = [
    {displayName: 'Categories', id: 'categories', count: 0, selected: true }, 
    {displayName : 'Application Name', id: 'applicationNameValues', count: 0, selected: false},
    {displayName : 'Product Name', id: 'productNameValues', count: 0, selected: false},
    {displayName : 'Created By', id: 'createdByValues', count: 0, selected: false},
  ];
  totalSelectionCount: number = 0;
  searchPlaceHolder: string = "Search Categories...";
  searchText: string = '';
  preSearchData = [];
  selectedFilterType = 'categories';

  constructor(
    private dialogRef: MatDialogRef<FilterModalComponent>,
    public prodOfferingsService : ProdOfferingsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    if(JSON.parse(sessionStorage.getItem('colorCodes'))) {
      const colorCode = JSON.parse(sessionStorage.getItem('colorCodes'));
      Object.values(colorCode).forEach(ele => {
        this.categories.push({ displayName: Object(ele).displayName , checked: false});
      });
    }
    this.filterTypeValues = {
      values: this.categories,
      type: 'categories'
    }
    this.getApplicationsList();
    this.getProductNameList();
    this.getCreatedByList();
    this.dataInit();
    
  }

  dataInit() {
    if(this.data && this.data.filteredCount > 0) {
      Object.values(this.data.filterData).map((val, index) => {
        console.log(val, index);
        this.getPreFiltersData(Object.keys(this.data.filterData)[index], val, index);
      });
    }
  }

  getPreFiltersData(type, values, index) {
    this.filterTypes[index].count = values? values.length: 0;
    values ? values.forEach(val => {
      this[type].find(ele => {
        if ((type === 'categories' && ele.displayName === val) || ( ele.value === val )) {
          ele['checked'] = true;
          this.selectedFilters.push({ 'displayName': ele.displayName, 'type': type });
        } 
      });
      this.totalSelectionCount ++
    }) : null;
  }

  /**to get applicationName filter type */
  getApplicationsList () {
    let appNameValues = [];
    if(sessionStorage.getItem('OfferingApplications')){
      appNameValues = JSON.parse(sessionStorage.getItem('OfferingApplications'));
    }
    if(!appNameValues.length){
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getvalueLists('OfferingApplications').subscribe({
        next: (response) => {
          this.prodOfferingsService.hideSpinner();
          if (response && response[0]) {
            sessionStorage.setItem('OfferingApplications', JSON.stringify(response[0]['values']));
            this.getApplicationsList();
          }
        },
        error: (error) => {
          this.prodOfferingsService.hideSpinner();
        }
      });
    }
    if(appNameValues) {
      Object.values(appNameValues).forEach(ele => {
        this.applicationNameValues.push({ displayName : Object(ele).displayValue, checked: false, value: Object(ele).value });
      });
    } 
  }

  getProductNameList() {
    let productList = []
    if(!sessionStorage.getItem('products-list')) {
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getAllProductsList().subscribe({
        next: (res) => {
          if (res) {
            productList = res;
            sessionStorage.setItem('products-list', JSON.stringify(productList));
            this.getProductNameList();
          }
        },
        error: (error) => {
          this.prodOfferingsService.hideSpinner();
        }
      });
    } else {
      productList = JSON.parse(sessionStorage.getItem('products-list'));
      this.prodOfferingsService.hideSpinner();
    }
    if(productList) {
      Object.values(productList).forEach(ele => {
        this.productNameValues.push({displayName : Object(ele).displayName, checked: false, value: Object(ele).reference})
      });
      this.productNameValues.sort((a,b) => a.displayName.localeCompare(b.displayName));
    }    
  }

  getCreatedByList() {
    let createdList = [];
    if(!sessionStorage.getItem('createdByList')) {
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getCreatedByList().subscribe({
        next: (res) => {
          if (res) {
            createdList = res;
            sessionStorage.setItem('createdByList', JSON.stringify(createdList));
            this.getCreatedByList();
          }
        },
        error: (error) => {
          this.prodOfferingsService.hideSpinner();
        }
      });
    } else {
      createdList = JSON.parse(sessionStorage.getItem('createdByList'));
      this.prodOfferingsService.hideSpinner();
    }
    if(createdList) {
      Object.values(createdList).forEach(ele => {
        this.createdByValues.push({displayName : `${ele.currentName.names[0]} ${ele.currentName.names[1]}`, value : ele._id , checked : false})
      })
      this.createdByValues.sort((a,b) => a.displayName.localeCompare(b.displayName));
    }
  }

  onClickFilterType(filterType) {
    this.prodOfferingsService.showSpinner();
    this.selectedFilterType = filterType.id;
    this.searchText = '';
    this.preSearchData = [];
    // to update selected left filtertype values
    this.filterTypes.forEach((ele , index) => {
      if (ele.id == filterType.id) {
        this.filterTypes[index].selected = true;
      } else {
        this.filterTypes[index].selected = false;
      }
    });

    this.filterTypeValues = {
      values: this[filterType.id],
      type: filterType.id
    }
    this.searchPlaceHolder = `Search ${filterType.displayName}...`;
    this.prodOfferingsService.hideSpinner();
  }

  /**on click of check box of right div filter type value */
  onCheckBoxClick(value, filterType) {
    if(!value.checked) {
      this.filterTypes.find(type => type.id === filterType).count++;
      this.selectedFilters.push( {'displayName':value.displayName, 'type': filterType} );
      this.totalSelectionCount ++;
    } else {
      this.filterTypes.find(type => type.id === filterType).count > 0 
        ? this.filterTypes.find(type => type.id === filterType).count-- : null;
      this.selectedFilters = this.selectedFilters.filter(ele => ele.displayName !== value.displayName);
      this.totalSelectionCount --;
    }
    this[filterType].find(ele => ele.displayName === value.displayName).checked = !value.checked;
  }

  /**on click of filter button */
  onFilterClick() {
    let selectedCategories = this.findSelected(this.categories);
    let selectedAppName = this.findSelected(this.applicationNameValues);
    let selectedProducts = this.findSelected(this.productNameValues);
    let selectedCreatedBy = this.findSelected(this.createdByValues);

    // const isFilterCleared = (selectedCategories.length === 0 && selectedAppName.length === 0 && selectedProducts.length ===0
    //                         && selectedCreatedBy.length ===0) ? true : false;
    this.dialogRef.close({
      'categories': selectedCategories,
      'applicationNameValues': selectedAppName,
      'productNameValues': selectedProducts,
      'createdByValues': selectedCreatedBy,
      'count': this.totalSelectionCount,
      // 'isFilterCleared' : isFilterCleared
    });
  }

  findSelected(valueList) {
    let selected = []
    valueList.forEach(ele => {
      if(ele.checked)  {
        selected.push(ele.value? ele.value :ele.displayName);
      }
    });
    return selected;
  }

  onSearch() {
    this.prodOfferingsService.showSpinner();
    // making preSearchData items unique (removing duplicate entried while concatination)
    this.preSearchData = this.preSearchData.concat(this.filterTypeValues.values.filter(val => this.preSearchData.indexOf(val) < 0));    
    this.filterTypeValues.values = this.preSearchData.filter(val => val.displayName.toLowerCase().includes(this.searchText.toLowerCase()))
    this.prodOfferingsService.hideSpinner();
  }

  onRemoveChip(filter) {
    const data = {
      'checked': true,
      'displayName': filter.displayName,
    }
    this.onCheckBoxClick(data, filter.type);
  }

  onDismiss(event) {
    event.preventDefault();
    this.dialogRef.close();
  }

}
