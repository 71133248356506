import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Observable, of, empty } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserContext } from '../models/userContext';
import { HttpParams } from '@angular/common/http';

/** user context service to fetch user context details */
@Injectable({
  providedIn: 'root'
})
export class UserContextService {
  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(
    private readonly baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService
  ) { }

  private _windowRef: any = [];

  public get windowRef() {
    return this._windowRef;
  }

  public set windowRef(value: any) {
    if (value) {
      this._windowRef.push(value);
    }
  }
  /**
   * get contexts method to get the user context List
  */
   getContexts(): Observable<UserContext[]> {
    let url = 'user/context'
    return this.baseClient
      .get<UserContext>(url, 'get the contexts List')
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }

  getContext(userId): Observable<UserContext> {
    const url = `user/context/${userId}`
    return this.baseClient
      .get<UserContext>(url, 'get the contexts List')
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }


  /**
   * get service method to get the user context details
   */
  getUserContext(): Observable<UserContext> {
    return of(JSON.parse(sessionStorage.getItem('UserContext')));
  }

  /**
   * get service method to get the user context details
   */
  setUserContext(userContext: UserContext): void {
    sessionStorage.setItem('UserContext', JSON.stringify(userContext));
  }
}
