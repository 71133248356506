<div fxLayout="row" >
    <div fxFlex="95">
        <span fxLayout="row" class="resction_info_div_title">{{restriction.type}}</span>
    </div>
    <div fxFlex="5">
        <div fxLayoutAlign="end start">
            <mat-icon  class="offering_delete_icon" *ngIf="!parentForm?.disabled" (click)="removeRestriction(restriction.type)">remove</mat-icon>
        </div>
    </div>
</div>
<div fxLayout="row wrap" class="resction_details_div" *ngIf="parentForm" [formGroup]="parentForm">
    <ng-container formArrayName="values">
        <div fxFlex="100" fxLayoutGap="1.25em" *ngFor="let item of parentForm?.get('values')['controls']; let i = index;let firstItem = first; let lastItem = last;" [formGroupName]="i">
            <div fxFlex="40" class="resction_details_row">
                <mat-form-field class="auto_Complte_input locations_flag_form_field" appearance="outline">
                    <mat-label>{{parentForm?.get('type') && parentForm?.get('type').value ? parentForm?.get('type').value.split(' ')[0] : ''}} Country</mat-label>
                    <input type="text"
                           placeholder="Select country"
                           matInput
                           id="{{restriction.type.replace(' ','')+'countryInput'+ i}}"
                           (focus)="countryFocus(i)"
                           (blur)="countryFocusOut(i)"
                           autocomplete="none"
                           (ngModelChange)="locationOnChange(i)"
                           formControlName="country"
                           [matAutocomplete]="auto" (keyup)="getCountrtyDetails($event,i)" />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="locationSelected($event,i)">
                        <mat-option *ngIf="!availableCountries.length && noCountriesFound" disabled>
                            No results found
                        </mat-option>
                        <mat-option *ngFor="let country of availableCountries" [disabled]="country.isDisabled" [value]="country">
                        {{country.name}}
                      </mat-option>
                    </mat-autocomplete>
                    <img *ngIf="parentForm?.get('values').get(''+i).get('value').value" matPrefix src="{{'https://www.countryflags.io/'+parentForm?.get('values').get(''+i).get('value').value+'/shiny/64.png'}}" class="country_flag">
                </mat-form-field>
                <!-- <span fxLayout="row" class="resction_info_div_subtitle"></span> -->
            </div>
            <div  fxFlex="40">
                <div class="rules_field resction_details_row">
                    <mat-form-field appearance="outline" class="" [ngClass]="{'multi_display_drodown mat_auto_complete_chip' :( parentForm?.get('values').get(''+i) && parentForm?.get('values').get(''+i).get('cityStPr') && parentForm?.get('values').get(''+i).get('cityStPr')['controls'].length)}" >
                        <mat-label>City/State</mat-label>
                        <mat-chip-list #citychipList fxLayout="row wrap">
                            <mat-chip fxFlex="auto"
                            *ngFor="let item of parentForm?.get('values').get(''+i).get('cityStPr')['controls'];let cityIndex = index;"
                            fxLayoutAlign="start center"
                            [removable]="true"
                            >
                            <div  fxLayoutAlign="start center" class="mat_chip_div">
                                <span>{{parentForm?.get('values').get(''+i).get('cityStPr').get(''+cityIndex).get('city').value}} <span *ngIf="parentForm?.get('values').get(''+i).get('cityStPr').get(''+cityIndex).get('state').value">/ {{parentForm?.get('values').get(''+i).get('cityStPr').get(''+cityIndex).get('state').value}}</span> </span>
                            <mat-icon matChipRemove *ngIf="!parentForm?.disabled" (click)="removeSelectedCity(city,i,cityIndex)">cancel</mat-icon>
                            </div>
                            </mat-chip>
                            <input
                            id="{{parentForm?.get('type').value.replace(' ','')+'cityInput'+ i}}"
                            matInput
                            (keyup)="searchCities($event,i)"
                            (focus)="cityFocus(i)"
                            autocomplete="none"
                            [disabled]="parentForm?.disabled"
                            (blur)="clearInputValue(parentForm?.get('type').value.replace(' ','')+'cityInput'+ i)"
                            #linkedOfferingInput
                            [matAutocomplete]="autoCities"
                            [matChipInputFor]="citychipList"
                            />
                        </mat-chip-list>
                        <mat-autocomplete #autoCities="matAutocomplete" (optionSelected)="citySelected($event,i,parentForm?.get('type').value.replace(' ','')+'cityInput'+ i)">
                            <mat-option *ngIf="!availableCities.length" disabled>
                                No results found
                            </mat-option>
                            <mat-option *ngFor="let city of availableCities" [value]="city" [disabled]="city.isDisabled">
                            {{city.city}} <span *ngIf="city.state">/ {{city.state}}</span>
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    
                </div>
            </div>
            <div fxFlex="20" fxLayoutAlign="start start" class="rules_field resction_details_row">
                <div fxFlex="25" *ngIf="parentForm?.get('values') && parentForm?.get('values').value.length > 1 && !parentForm?.disabled">
                    <div  class="row_delete_icon" fxLayoutAlign="center center">
                        <img src="../assets/icons/arrow_delete.svg" (click)="removeLocation(i)"  alt=""/>
                    </div>
                </div>
                <div fxFlex="25">
                    <div *ngIf="lastItem && !parentForm?.disabled" class="row_delete_icon" fxLayoutAlign="center center">
                        <mat-icon (click)="addNewLocation()" >add</mat-icon>
                    </div>
                </div>
            </div>
            
        </div>
    </ng-container>
</div>
