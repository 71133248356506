<div fxLayout="row" >
    <div fxFlex="95">
        <span fxLayout="row" class="resction_info_div_title">{{restriction.type}}</span>
    </div>
    <div fxFlex="5">
        <div fxLayoutAlign="end start">
            <mat-icon  class="offering_delete_icon" *ngIf="!disableEditOffering" (click)="removeRestriction(parentForm?.get('type').value)">remove</mat-icon>
        </div>
    </div>
</div>

<div fxLayout="row wrap"  *ngIf="parentForm" [formGroup]="parentForm" class="resction_details_div">
    <ng-container formArrayName="values">
        <div fxFlex="100" *ngFor="let item of parentForm?.get('values')['controls']; let i = index; let fisrtItem = first; let lastItem = last;" >
            <mat-radio-group  [formControlName]="i" aria-label="Select an option">
                <mat-radio-button class="restriction_radio_btn" *ngFor="let item of dropdownList" [value]="item.value">{{item.displayValue}}</mat-radio-button>
            </mat-radio-group>
        </div>
    </ng-container>
</div>