import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { response } from 'express';
import { ProdTemplatesService } from '../../services/product-templates.service';
import {MatDialog} from '@angular/material/dialog';
import { WarningModelComponent } from '../common/warning-model/warning-model.component';
import { ToastrService ,GlobalConfig , ToastContainerDirective,} from 'ngx-toastr';
import { FeatureFlag, FeatureFlagService } from '../../services/feature-flag.service';

@Component({
  selector: 'app-product-template',
  templateUrl: './product-template.component.html',
  styleUrls: ['./product-template.component.scss'],
})
export class ProdTemplatesComponent implements OnInit {
    options: GlobalConfig;
    selectedTemplateName :string;
    templatesFilterVal =  'allTemplates';
    showSearchCloseIcon = false;
    isSerchFocused = false;
    templatesCount:any = {
      Active: {
        total: 0,
        user: 0,
        displayName:'Existing Templates'
      },
      Draft: {
        total: 0,
        user: 0,
        displayName:'Draft Templates'
      },
      Archived: {
        total: 0,
        user: 0,
        displayName:'Archived Templates'
      }
    };
    templatesData = {
      selectedTemplates:[],
      ASC:{
        Draft:{
          allTemplates:[],
          myTemplates:[],
          allTemplatesPageInfo :1,
          myTemplatesPageInfo : 1,
        },
        Active:{
          allTemplates:[],
          myTemplates:[],
          allTemplatesPageInfo :1,
          myTemplatesPageInfo : 1,
        },
        Archived:{
          allTemplates:[],
          myTemplates:[],
          allTemplatesPageInfo :1,
          myTemplatesPageInfo : 1,
        },
      },
      DESC:{
        Draft:{
          allTemplates:[],
          myTemplates:[],
          allTemplatesPageInfo :1,
          myTemplatesPageInfo : 1,
        },
        Active:{
          allTemplates:[],
          myTemplates:[],
          allTemplatesPageInfo :1,
          myTemplatesPageInfo : 1,
        },
        Archived:{
          allTemplates:[],
          myTemplates:[],
          allTemplatesPageInfo :1,
          myTemplatesPageInfo : 1,
        },
      }
    };
    templatesFilterOptions = [{
        name:'All Templates',value:'allTemplates', count:0
      },
      {
        name:'My Templates',value:'myTemplates', count:0
      }
    ];
    searchOptions = {
      skip:1,
      limit:10,
      status:'Active',
      searchText:'',
      onlyShowMine:false,
      sortDir:'ASC',
      expandParties:true
    };
  timeOut: any;
  searchCount: string;
  toastRef: any;
  matSelectFocused: any;
  disableScroll: boolean = false;
  isSyncProgramEnabled: boolean = false;

    constructor(private route: ActivatedRoute,
      private router: Router,
      private prodTemplatesService : ProdTemplatesService,
      public dialog : MatDialog,
      public toastr: ToastrService,
      private featureFlagService: FeatureFlagService ){
        this.options = this.toastr.toastrConfig;
    }

    ngOnInit(){ 
      console.log("product template loads");
      let isChangeInTemps = this.prodTemplatesService.changeInTemplates();
      this.verifyPublishedTempStatus();
      this.getFeatureFlag();
      if(isChangeInTemps){
        this.prodTemplatesService.resetTemplateStatus();
        sessionStorage.removeItem('templatesData');
        sessionStorage.removeItem('templatesCount')
        this.getAllTemplatesCount();
      }else if(sessionStorage.getItem('templatesData') && sessionStorage.getItem('templatesCount')){
          this.templatesData = JSON.parse(sessionStorage.getItem('templatesData'));
          this.templatesCount = JSON.parse(sessionStorage.getItem('templatesCount'));
          this.loadSelectedTemplates(sessionStorage.getItem("tempStatus") ? sessionStorage.getItem("tempStatus") : 'Active');
          this.verifyPublishedTempStatus();  
        }else{
          this.getAllTemplatesCount();
      }
    }

    goToTemplate(templateId) {
      sessionStorage.removeItem('isDuplicate');
      sessionStorage.removeItem('templateInfo');
      this.router.navigate(['/view-template',templateId]);
    }

    createTemplate(){
      sessionStorage.removeItem('templateInfo');
      sessionStorage.removeItem('isDuplicate');
      this.router.navigate(['/create-template']);
    }

    getAllTemplatesCount(tempName?) {
      this.prodTemplatesService.showSpinner();
      this.prodTemplatesService.getAllTemplatesCount().subscribe({
        next: (response) => {
          this.prodTemplatesService.hideSpinner();
          if (response && response[0]) {
            for (let key in this.templatesCount) {
              if (response[0][key]) {
                this.templatesCount[key] = Object.assign(this.templatesCount[key], response[0][key]);
              }
            }
            sessionStorage.setItem('templatesCount', JSON.stringify(this.templatesCount));
            //if tempName is not available by default we have to load active templates
            this.loadSelectedTemplates(tempName ? tempName : 'Active');
          }
        },
        error: (error) => {
          this.prodTemplatesService.hideSpinner();
        }
      });
    }

    getAllTemplates (search?:boolean) {
      this.disableScroll = true;
      this.prodTemplatesService.showSpinner();
      this.prodTemplatesService.getAllTemplates(this.prepareTemplateSearchCriteria()).subscribe({
        next: (response) => {
          console.log(response);
          this.prodTemplatesService.hideSpinner();
          if (response && response.body.length) {
            if (!search) {
              this.templatesData[this.searchOptions.sortDir][this.searchOptions.status][this.templatesFilterVal + 'PageInfo'] = this.searchOptions.skip;
              // eslint-disable-next-line max-len
              this.templatesData[this.searchOptions.sortDir][this.searchOptions.status][this.templatesFilterVal] = [...this.templatesData[this.searchOptions.sortDir][this.searchOptions.status][this.templatesFilterVal], ...response.body];
              this.templatesData.selectedTemplates = this.templatesData[this.searchOptions.sortDir][this.searchOptions.status][this.templatesFilterVal];
            } else {
              this.templatesData.selectedTemplates = [...this.templatesData.selectedTemplates, ...response.body];
            }
            if (response.headers.get('x-order-count')) {
              this.searchCount = response.headers.get('x-order-count');
            }
            sessionStorage.setItem('templatesData', JSON.stringify(this.templatesData));
            this.verifyPublishedTempStatus();
          }
          this.disableScroll = false;
        },
        error: (error) => {
          this.disableScroll = false;
          this.prodTemplatesService.hideSpinner();
        }
      });
    }

    loadSelectedTemplates (templateName:string) {
      sessionStorage.setItem("tempStatus",templateName);
      this.searchOptions.status = templateName; // Template name should be 'Active' or 'Draft' or 'Archived'
      this.searchOptions.searchText = null; 
      this.showSearchCloseIcon = false;
      this.templatesFilterOptions[0].count = this.templatesCount[templateName].total;
      this.templatesFilterOptions[1].count = this.templatesCount[templateName].user;
      this.selectedTemplateName = this.templatesCount[templateName].displayName;
      this.templatesData.selectedTemplates = this.templatesData[this.searchOptions.sortDir][templateName][this.templatesFilterVal];
      this.searchOptions.skip = this.templatesData[this.searchOptions.sortDir][templateName][this.templatesFilterVal+'PageInfo'];
      window.scroll(0,0); // Scroll to top when the user is switching template tabs
      if(!this.templatesData.selectedTemplates.length){
        this.searchOptions.skip = 1;
        this.getAllTemplates();
      }
    }

    searchTemplates(){
      clearTimeout(this.timeOut);
      if(this.searchOptions.searchText && this.searchOptions.searchText.length > 2){
        this.timeOut = setTimeout(() => {
          this.showSearchCloseIcon = true;
          this.searchOptions.skip =1;
          this.templatesData.selectedTemplates = [];
          this.searchCount='0';
          this.getAllTemplates(true);
        }, 1000);
      }else if(!this.searchOptions.searchText){
        this.showSearchCloseIcon = false;
        this.loadSelectedTemplates(this.searchOptions.status);
      }
    }

    matFocusedEvent(event){
      this.matSelectFocused = event;
    }
  

    filterOrSortTemplates () {
      this.showSearchCloseIcon = false;
      this.loadSelectedTemplates(this.searchOptions.status); 
    }

    prepareTemplateSearchCriteria () {
      let params = this.searchOptions;
      if (!this.showSearchCloseIcon){
        delete params.searchText;
      }
      params.onlyShowMine = this.templatesFilterVal == 'allTemplates' ? false : true ;
      return  Object.keys(params).map(key => `${key}=${params[key]}`).join("&");
    }
    
    @HostListener('window:scroll', ['$event'])
    addTemplatesToList (event) {
      console.log(event);
      const element = event.target;
      let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight +100;
      let max = document.documentElement.scrollHeight;
      let maxCount = this.showSearchCloseIcon ? parseInt(this.searchCount) : this.templatesFilterVal == 'allTemplates'? this.templatesFilterOptions[0].count : this.templatesFilterOptions[1].count;

      // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
      if(!this.disableScroll && (pos >= max) &&  (maxCount > this.templatesData.selectedTemplates.length))   {
        this.searchOptions.skip ++;
        this.disableScroll = true;
        this.getAllTemplates(this.showSearchCloseIcon ? true : false);
      }
    }

    restoreArchivedTempToDraft (templateId) {
      let dialogRef =this.dialog.open(WarningModelComponent,{
        data:{
          title:"Restore Confirmation",
          type:'restore',
          content:"Are you sure you want to restore this Template? This template will be moved to ‘Draft Template’ .",
          actions:[
            {name:'Close',type:'close'},
            {name:'Restore',type:'restore'},
          ]
        },
        width:'32.74em'
      });

      dialogRef.afterClosed().subscribe((action) =>{
       
        if(action == 'restore'){
          this.prodTemplatesService.showSpinner();
          let id= templateId;
          let tempData = {
            "fromDate": new Date(new Date().setFullYear(new Date().getFullYear()+50)).toJSON(),
             "toDate": new Date(new Date().setFullYear(new Date().getFullYear()+80)).toJSON()
          }
          this.prodTemplatesService.moveTemplateToDraft(id, tempData).subscribe({
            next: (response) => {
              this.prodTemplatesService.hideSpinner();
              this.templatesData[this.searchOptions.sortDir][this.searchOptions.status][this.templatesFilterVal + 'PageInfo'] = 1;
              this.templatesData[this.searchOptions.sortDir][this.searchOptions.status][this.templatesFilterVal] = [];
              this.templatesData[this.searchOptions.sortDir]['Draft'][this.templatesFilterVal + 'PageInfo'] = 1;
              this.templatesData[this.searchOptions.sortDir]['Draft'][this.templatesFilterVal] = [];
              this.templatesData.selectedTemplates = [];
              this.getAllTemplatesCount(this.searchOptions.status);
            },
            error: (error) => {
              this.prodTemplatesService.hideSpinner();
            }
          });
        }
      })
    }
    
    verifyPublishedTempStatus(){
      if(this.prodTemplatesService.getPublishedTemplateStatus()){
        let title = this.prodTemplatesService.getPublishedTemplateStatus() == 'true' ? 'Success' : 'Error';
        // eslint-disable-next-line max-len
        let message = this.prodTemplatesService.getPublishedTemplateStatus() == 'true' ? 'Template published successfully' : `Operation couldn't be completed this time. Please try again later.(err0789)`;
        this.prodTemplatesService.setPublishedTemplateStatus(null);
        const opt = JSON.parse(JSON.stringify(this.options));
        const inserted = this.toastr.show(
          message,
          title,
          opt
        );
      }
    }

    getCreatedBy(createdByObj){
      let value = null;
      if(createdByObj && createdByObj[0] && createdByObj[0].currentName
         && createdByObj[0].currentName.names && createdByObj[0].currentName.names.length) {
          value = createdByObj[0].currentName.names.toString().replace(',',' ');
      }

      return value;
    }

    goToTemplatePrograms(templateId){
      this.router.navigate(['/sync-programs-to-template',templateId]);
    }

    syncToPrograms (templateId) {
        this.prodTemplatesService.showSpinner();
        this.prodTemplatesService.getTemplateById(this.prepareRequestPayload(templateId)).subscribe({
          next: (response) => {
            console.log(response);
            if (response && response[0]) {
              sessionStorage.setItem('templateInfo', JSON.stringify(response[0]));
              this.goToTemplatePrograms(templateId);
            }
            this.prodTemplatesService.hideSpinner();
          },
          error: (error) => {
            this.prodTemplatesService.hideSpinner();
          }
        });
      }
    
      prepareRequestPayload(templateId) {
        const searchOptions = {
          expandOfferings:true,
          expandParties:true
        }
        return `/${templateId}?${Object.keys(searchOptions).map(key => `${key}=${searchOptions[key]}`).join("&")}`
      }

      getFeatureFlag() {
        this.featureFlagService.getFeatureFlagsBBUrl().subscribe(
          (res) => {
            const featureFlag: FeatureFlag[] = res.items;
            this.isSyncProgramEnabled =  featureFlag && featureFlag.find( ele => ele.key === 'sync_programs' ).on;
          }
        )
      }
}