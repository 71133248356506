<app-base-prod-template [availableOfferingsList]="availableOfferingsList"
[addedOfferingsList]="addedOfferingsList"
[tempType]="tempType"
[templateName] = "templateName"
[templateDescription] = "templateDescription"
[isDuplicateTempName] = "isDuplicateTempName"
[btnConfig]="btnConfig"
(cancelEvent)="cancel($event)"
(saveDraftEvent)="saveDraft($event)"
(publishEvent)="publish($event)"
(delTempEvent)="deleteTemplateEvent($event)"
(backEvent)="goBack($event)"
></app-base-prod-template>