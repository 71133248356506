import { Component, OnInit } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';
import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls :['./toaster.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      transition('inactive => active', animate('2000ms ease-out', keyframes([
        style({
          opacity: 0,
        }),
        style({
          display: 'contents',
          opacity: 1,
        })
      ]))),
      transition('active => removed', animate('2000ms ease-out', keyframes([
        style({
          display: 'contents',
          opacity: 1,
        }),
        style({
          transform: 'translate3d(10%, 0, 0) skewX(10deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})
export class ToasterComponent extends Toast  {

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) { 
    super(toastrService, toastPackage);
  }

  

}
