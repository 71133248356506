/* eslint-disable */
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

/**
 * Gets the global API services configuration
 */
@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {

  benefitsBuilderURL: string;

  /** Root URL for API operations */
  rootUrl: string;

  /** Root URL for Cost Model API operations */
  accessManagementRootUrl: string;

  /** Root URL for Integration API operations */
  apiIntegrationRootUrl: string;
  /** Root URL for BB API operations */
  apiBBRootUrl: string;

  /**
   * Initialize the rootURL
   */
  constructor(private config: AppConfigService) {
    const apiConfig: {[key: string]: string | number }  = this.config.getConfig('api');
    this.rootUrl = apiConfig.protocol + '://' + apiConfig.host + ':' + apiConfig.port + apiConfig.base_url;

    const apiConfig1: { [key: string]: string | number } = this.config.getConfig('accessManagement');
    this.accessManagementRootUrl = `${apiConfig1.protocol}://${apiConfig1.host}`;

    const apiConfig2: { [key: string]: string | number } = this.config.getConfig('apiIntegration');
    this.apiIntegrationRootUrl = `${apiConfig2.protocol}://${apiConfig2.host}`;
    
    const bbApiConfig: { [key: string]: string | number } = this.config.getConfig('benefitsBuilderapi');
    this.apiBBRootUrl = bbApiConfig.protocol + '://' + bbApiConfig.host + ':' + bbApiConfig.port + bbApiConfig.base_url;
  }
}
