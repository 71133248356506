<div class="row" class="header-toolbar">
    <div fxFlex="5%">
        <a class="back-btn-icon" aria-label="Example icon-button with menu icon" (click)="goBack()">
            <mat-icon>arrow_back</mat-icon>
        </a>
    </div>
    <div fxFlex="85%">
        <div fxFlex="100%">
            <span class="temp-header">{{tempType === 'create' ? 'Create Template' : tempType === 'duplicate' ? 'Duplicate Template : ' : 'Edit Template :'}}
            </span>
            <span class="temp-name-span">&nbsp;{{templateName}}</span>
        </div>
        <!-- <div fxFlex="80%" *ngIf="tempType != 'create'">
            <span class="temp-name-span">
                {{templateName}}
            </span>
        </div> -->
    </div>
    <div fxFlex="5%" fxFlexOffset="5%">
        <button (click)="deleteTemplate()" *ngIf="dispalyDelIcon" mat-icon-button class="toolbar-right-icon" aria-label="Example icon-button with share icon">
            <mat-icon class="">delete</mat-icon>
        </button>
    </div>
</div>
<div class="template-content">
        <div fxLayout="row">
            <div fxFlex="58%" >
                <div class="left_div">
                    <form [formGroup]="templateForm">
                        <div fxLayout="row">
                            <div fxFlex="100%">
                                <span class="template-heading">Template Details</span>
                            </div>
                        </div>
                        <div  fxLayout="row" class="temp-name-input">
                            <div fxFlex="100%">
                                <mat-form-field appearance="outline">
                                    <mat-label>Template Name</mat-label>
                                    <input matInput formControlName="name" maxlength="50" [ngClass]="{'disable-template-name':isActive}" #tempNameField class="input_text" placeholder="Template Name">
                                  </mat-form-field>
                                  <mat-error *ngIf="templateForm.get('name').hasError('invalid')">Please enter valid File ID</mat-error>
                                  <mat-error *ngIf="isDuplicateTempName">*Template name must be unique, Please try again with another name.</mat-error>
                            </div>
                        </div>
                        <div  fxLayout="row" class="temp-name-desc">
                            <mat-form-field class="mat-text-area-field" appearance="outline">
                                <mat-label>Description</mat-label>
                                <textarea matInput formControlName="description" class="input_text" placeholder="Description"></textarea>
                            </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="100%" fxLayout="row"  class="added_search_row">
                                <div fxFlex="50%" *ngIf="!showAddedSearchInput" fxLayoutAlign="start center">
                                    <span class="avlbl-offering-count-span">Added Offering(s) - {{addedOfferingsList.length}}</span>
                                </div>
                                <div *ngIf="addedOfferingsList.length" fxFlex="{{!showAddedSearchInput ? '10%' : '100%'}}" fxFlexOffset="{{!showAddedSearchInput ? '40%' : '0%'}}" fxLayout="row" class="" [ngClass]="{'serch_input_row_selected' : showAddedSearchInput}">
                                    <div fxFlex="{{!showAddedSearchInput ? '100%' : '10%'}}"  fxLayout="column" (click)="showAddedSearchInput = !showAddedSearchInput" fxLayoutAlign="center center">
                                        <mat-icon  class="search_icon" [ngClass]="{'serch_input_row' : !showAddedSearchInput}">search</mat-icon>
                                    </div>
                                    <div *ngIf="showAddedSearchInput" fxFlex="80%" fxLayoutAlign="start center">
                                        <input class="search_input" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" matInput autocomplete="none" placeholder="Search Offerings..."    (ngModelChange)="showAddedSearchCloseIcon=true;searchAddedOfferings()">
                                    </div>
                                    <div *ngIf="showAddedSearchInput" fxFlex="10%" fxLayoutAlign="center center">
                                        <mat-icon *ngIf="showAddedSearchCloseIcon" class="close_icon" (click)="showAddedSearchCloseIcon=false;searchText=null;searchAddedOfferings();">close</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="showAddedSearchCloseIcon && searchText && searchText.length >2"  fxLayout="row" class="added_results_row" fxLayoutAlign="start center">
                            <div fxFlex="50%" >
                                <span class="results_count_span"> {{(addedOfferingsSearchCount && addedOfferingsSearchCount !== '0') ? addedOfferingsSearchCount : 'No'}} Results</span>
                                <span> found</span>
                            </div>
                        </div>
                        <!-- <div  fxLayout="row">

                           <span class="avlbl-offering-count-span">Added Offering(s) - {{addedOfferingsList.length}}</span>
                        </div> -->
                        <div  fxLayout="row wrap" cdkDropList #addedList="cdkDropList" [cdkDropListData]="addedOfferingsList" (cdkDropListDropped)="drop($event)" class="added_offerings_list">
                            <div fxFlex="100%"  *ngIf="!addedOfferingsList.length" class="empty_added_offerings_list">
                                <div fxLayout="row">
                                    <p> Offering list is empty</p>
                                </div>
                                <div fxLayout="row">
                                    <span fxFlex="70">Select offerings from right panel, click on ‘add icon’ or drag the offering to add into the list</span>
                                </div>
                            </div>
                            <div fxFlex="100%" class="added_offerings_mat__list">
                                <ng-container *ngFor="let offering of addedOfferingsList" >
                                    <mat-card  *ngIf="(!showAddedSearchInput || !searchText|| (searchText && searchText.length < 3) || offering.displayName.toLowerCase().includes(searchText.toLowerCase()))">
                                        <div fxLayout="row" class="" fxLayoutAlign="center center">
                                            <!-- <div fxFlex="1%"fxFlexOffset="1%" fxLayoutAlign="start center">
                                                <img src="../assets/icons/mat_drag_icon.png" alt="" />
                                            </div> -->
                                            <div fxFlex="19%" fxLayoutAlign="center center" *ngIf ="offering.iconDetails">
                                                <img class="template-icon" src={{offering.iconDetails.iconName}} alt=""/>
                                            </div>
                                            <div fxFlex="19%" fxLayoutAlign="center center" *ngIf ="!offering.iconDetails">
                                                <img src="../assets/images/offering_sample_img.png" alt=""/>
                                            </div>
                                            <div fxFlex="75%" class="offering_content_div">
                                                <div fxLayout="row wrap" class="offering_info" fxLayoutAlign="start center">
                                                    <div fxFlex="100%">
                                                       <span class="offering-name"> {{offering.displayName}} </span>   
                                                    </div>
                                                    <div fxFlex="100%">
                                                        <span class="offering_desc"> {{offering.description}} </span>   
                                                     </div>
                                                </div>
                                                <div fxLayout="row" class="offering_info offering_status_info"  fxLayoutAlign="start center">
                                                    <div fxFlex="46%">
                                                        <div class="prod-name"  *ngIf="offering.productSpecificationReference ? offering.productSpecificationReference[0] ? offering.productSpecificationReference[0].displayName : offering.productSpecificationReference.displayName : false">
                                                            <span >
                                                                {{offering.productSpecificationReference ? offering.productSpecificationReference[0] ? offering.productSpecificationReference[0].displayName: offering.productSpecificationReference.displayName : ""}}
                                                            </span>
                                                        </div>
                                                        
                                                     </div>
                                                     <div fxFlex="54%">
                                                         <div fxLayot="row" fxLayoutAlign="start center">
                                                            <div  #tooltip="matTooltip" [matTooltipDisabled]="(!dispalyCategory(offering).count || dispalyCategory(offering).count <= 1)" matTooltip="{{dispalyCategory(offering).list.join('\n')}}" matTooltipClass="tooltip-list" [matTooltipPosition]="'below'" fxLayoutAlign="start center">
                                                                <div class="prod-status" *ngIf="dispalyCategory(offering).displayName" [ngStyle]="{'border-color':dispalyCategory(offering).colorCode}" >
                                                                    <span class="prod-status-color" [ngStyle]="{'background-color':dispalyCategory(offering).colorCode}"></span>
                                                                    <span class="prod-status-type">{{dispalyCategory(offering).displayName}}</span>
                                                                 </div>
                                                                 <span *ngIf="dispalyCategory(offering).count > 1" class="prod-status-count"  [ngStyle]="{'border-color':dispalyCategory(offering).colorCode}">+{{dispalyCategory(offering).count-1}}</span>
                                                            </div>
                                                         </div>
                                                     </div>
                                                </div>
                                            </div>
                                            <div fxFlex="10%" fxLayout="row" fxLayoutAlign="center center">
                                                <mat-icon class="offering_delete_icon" (click)="delAddedOffering(offering)">remove</mat-icon>
                                            </div>
                                        </div>
                                    </mat-card>
                                </ng-container>
                            </div>
                             
                        </div>
                    </form>
                </div>
                <app-footer></app-footer>
            </div>
            <div fxFlex="42%" class="right_div">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <div fxFlex="100%" fxLayout="row"  class="search_row">
                        <div fxFlex="50%" *ngIf="!showSearchInput" fxLayoutAlign="start center">
                            <span class="avlbl-offering-count-span">Available Offerings - {{searchCount - removedAvlblOfferingsLength }}</span>
                        </div>
                        <div fxFlex="{{!showSearchInput ? '10%' : '100%'}}" fxFlexOffset="{{!showSearchInput ? '40%' : '0%'}}" fxLayout="row" class="" [ngClass]="{'serch_input_row_selected' : showSearchInput}">
                            <div fxFlex="{{!showSearchInput ? '100%' : '10%'}}"  fxLayout="column" (click)="showSearchInput = !showSearchInput" fxLayoutAlign="center center">
                                <mat-icon  class="search_icon" [ngClass]="{'serch_input_row' : !showSearchInput}">search</mat-icon>
                            </div>
                            <div *ngIf="showSearchInput" fxFlex="80%" fxLayoutAlign="start center">
                                <input class="search_input" [(ngModel)]="searchOptions.searchText" matInput placeholder="Search Offerings..."  autocomplete="none" #searchInput  (ngModelChange)="showSearchCloseIcon=true;searchOfferings()">
                            </div>
                            <div *ngIf="showSearchInput" fxFlex="10%" fxLayoutAlign="center center">
                                <mat-icon *ngIf="showSearchCloseIcon" class="close_icon" (click)="showSearchCloseIcon=false;searchOptions.searchText=null;searchOfferings();">close</mat-icon>
                            </div>
                        </div>
                        <!--filter offerings-->
                        <div class="filte_icon">
                            <span class="selected-count" *ngIf="filterCount > 0">{{filterCount}}</span>
                            <img src="../assets/icons/filter_icon.svg" alt="" class="filter-icon-style" (click)="openFilterModal()"/>
                        </div>
                    </div>
                </div>
                <div *ngIf="showSearchInput || filterCount > 0"  fxLayout="row" class="results_row" fxLayoutAlign="start center">
                    <div fxFlex="55%">
                        <span class="results_count_span"> {{(searchCount && searchCount !== '0') ? searchCount - removedAvlblOfferingsLength : 'No'}} Results</span>
                        <span> found</span>
                    </div>
                </div>
                <div fxLayout="row" class="available_offerings_lists_section" (scroll)="addOfferingsToList($event)">
                    <div  cdkDropList
                    #availableList="cdkDropList"
                    [cdkDropListData]="availableOfferingsList"
                    [cdkDropListConnectedTo]="[addedList]"
                    (cdkDropListDropped)="drop($event)"   class="offerings_list">
                    <mat-card cdkDrag [cdkDragData]="offering" *ngFor="let offering of availableOfferingsList; let i = index">
                        <div fxLayout="row" class="" fxLayoutAlign="center center">
                            <div fxFlex="3%"fxFlexOffset="1%" fxLayoutAlign="center center">
                                <div class="drag_img">
                                    <img src="../assets/icons/mat_drag_icon.png" alt="" style="cursor: pointer;" (click)="viewOfferingDetails(offering.reference)" />
                                </div>
                            </div>
                            <div fxFlex="12%" class="off-icon" fxLayoutAlign="center center" *ngIf ="offering.iconDetails">
                                <img class="template-icon" src={{offering.iconDetails.iconName}} alt=""/>
                            </div>
                            <div fxFlex="12%" class="off-icon-default" fxLayoutAlign="center center" *ngIf ="!offering.iconDetails">
                                <img src="../assets/images/offering_sample_img.png" alt=""/>
                            </div>
                            <div fxFlex="74%" class="offering_content_div">
                                <div fxLayout="row wrap" class="offering_info" fxLayoutAlign="start center">
                                    <div fxFlex="100%">
                                       <span class="offering-name"> {{offering.displayName}} </span>   
                                    </div>
                                    <div fxFlex="100%">
                                        <span class="offering_desc"> {{offering.description}} </span>   
                                     </div>
                                </div>
                                <div fxLayout="row" class="offering_info offering_status_info"  fxLayoutAlign="start center">
                                    <div fxFlex="2%">
                                        <div class="identify-offering" *ngIf="offering?.workOrder">
                                            <img class="offering-icon" matTooltip="Supplier Order" matTooltipPosition="above"
                                             alt="edit" src="../../../../assets/images/icon_supplierOrders.svg" />
                                        </div>
                                        <div class="identify-offering" *ngIf="checkCartusInsured(i)">
                                              <img class="offering-icon-cartusInsured" matTooltip="Cartus Insured" matTooltipPosition="above"
                                              alt="edit" src="../../../../assets/images/icon_cartusInsured.svg" />
                                      </div>
                                     </div>
                                    <div fxFlex="46%">
                                        <div class="prod-name">
                                            <span >{{offering.productSpecificationReference ? offering.productSpecificationReference[0] ? offering.productSpecificationReference[0].displayName: offering.productSpecificationReference.displayName : ""}}</span>
                                        </div>
                                     </div>
                                     <div fxFlex="52%" fxFlexOffset="2%">
                                         <div fxLayot="row" #tooltip="matTooltip" [matTooltipDisabled]="(!dispalyCategory(offering).count || dispalyCategory(offering).count <= 1)" matTooltip="{{dispalyCategory(offering).list.join('\n')}}" matTooltipClass="tooltip-list" [matTooltipPosition]="'below'"  fxLayoutAlign="start center">
                                             <div class="prod-status" *ngIf="dispalyCategory(offering).displayName" [ngStyle]="{'border-color':dispalyCategory(offering).colorCode}" >
                                                <span class="prod-status-color" [ngStyle]="{'background-color':dispalyCategory(offering).colorCode}"></span>
                                                <span class="prod-status-type">{{dispalyCategory(offering).displayName}}</span>
                                             </div>
                                             <span *ngIf="dispalyCategory(offering).count > 1" [ngStyle]="{'border-color':dispalyCategory(offering).colorCode}" class="prod-status-count">+{{dispalyCategory(offering).count-1}}</span>
                                         </div>
                                     </div>
                                </div>
                            </div>
                            <div fxFlex="10%" fxLayout="row" fxLayoutAlign="center center">
                                <mat-icon class="offering_add_icon" (click)="addOffering(offering)">add</mat-icon>
                            </div>
                        </div>
                    </mat-card>
                </div>
                </div>
                
            </div>
        </div>
</div>
<div class="footer_content">
    <div fxLayout="row" fxLayoutAlign="end center" class="footer_row">
        <div fxFlex="40%">
            <div fxLayout="row"  fxLayoutAlign="end center">
                <div fxFlex="20%" *ngIf="btnConfig.showCancelBtn" fxLayoutAlign="center center">
                    <button  mat-button color="primary" class="cancel-btn"  (click)="cancel()">Cancel</button>
                </div>
                <div fxFlex="40%" *ngIf="btnConfig.showSaveDraftBtn" fxLayoutAlign="center center">
                    <button mat-stroked-button [disabled]="isbtnDisabled('save')"  class="save-btn" [ngClass]="{'disable-save-btn': isbtnDisabled('save')}" (click)="saveDraft()">Save as Draft</button>
                </div>
                <div fxFlex="40%" *ngIf="btnConfig.showPublishBtn" fxLayoutAlign="center center">
                    <button  mat-raised-button [disabled]="isbtnDisabled('publish')" class="publish-btn" [ngClass]="{'disable-publish-btn': isbtnDisabled('publish')}" (click)="publish()">Save and Publish</button>
                </div>
            </div>
        </div>
    </div>
</div>