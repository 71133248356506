import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ProdOfferingsService } from '../../services/product-offerings.service';
import { AddTemplateModelComponent } from '../common/add-template-model/add-template-model.component';
import { WarningModelComponent } from '../common/warning-model/warning-model.component';
import { FilterModalComponent } from '../filter-modal/filter-modal.component';
import { FeatureFlagService } from '../../services/feature-flag.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-product-offering',
  templateUrl: './product-offering.component.html',
  styleUrls: ['./product-offering.component.scss'],
})
export class ProdOfferingsComponent implements OnInit, OnDestroy {

    selectedOfferingsCount:any;
    selectedOfferingsData = {
      totalCount:0,
      Offerings:[]
    };

    allProdOfferings = {};
    lumpSumEnabled:boolean = false;
    selectedOfferingName :string;
    offeringsFilterVal =  'allOfferings';
    showSearchCloseIcon = false;
    isSerchFocused = false;
    matSelectFocused = false;
    disableScroll : boolean = false;
    selectedOfferingData : any;
    offeringsCount:any = {
      Active: {
        total: 0,
        user: 0,
        displayName:'Existing Offerings'
      },
      Draft: {
        total: 0,
        user: 0,
        displayName:'Draft Offerings'
      },
      Archived: {
        total: 0,
        user: 0,
        displayName:'Archived Offerings'
      }
    };
    offeringsData = {
        selectedOfferings:[],
        ASC:{
          Draft:{
            allOfferings:[],
            myOfferings:[],
            allOfferingsPageInfo :1,
            myOfferingsPageInfo : 1,
          },
          Active:{
            allOfferings:[],
            myOfferings:[],
            allOfferingsPageInfo :1,
            myOfferingsPageInfo : 1,
          },
          Archived:{
            allOfferings:[],
            myOfferings:[],
            allOfferingsPageInfo :1,
            myOfferingsPageInfo : 1,
          },
        },
        DESC:{
          Draft:{
            allOfferings:[],
            myOfferings:[],
            allOfferingsPageInfo :1,
            myOfferingsPageInfo : 1,
          },
          Active:{
            allOfferings:[],
            myOfferings:[],
            allOfferingsPageInfo :1,
            myOfferingsPageInfo : 1,
          },
          Archived:{
            allOfferings:[],
            myOfferings:[],
            allOfferingsPageInfo :1,
            myOfferingsPageInfo : 1,
          },
        }
      };
    offeringsFilterOptions = [{
        name:'All Offerings',value:'allOfferings', count:0
      },
      {
        name:'My Offerings',value:'myOfferings', count:0
      }
    ];
    searchOptions = {
      skip:1,
      limit:10,
      status:'Active',
      searchText:'',
      onlyShowMine:false,
      sortDir:'ASC',
      expandParties:true
    };
    timeOut: any;
    searchCount: string;
    categoryCodes: any = {};
    associatedTemplates: any = [];
    checkIsBB: any[];

    /**filter */
    filterCount = 0;
    filterPayload = {
      'categories': [],
      'applicationNames': [],
      'productNames': [],
      'createdBy': []
    }
    // isFilterCleared :boolean = false;
    flagSubscription:Subscription;

    constructor(private readonly route: ActivatedRoute,
        private readonly router: Router,
        public dialog : MatDialog,
        private prodOfferingsService : ProdOfferingsService,
        public featureFlagService:FeatureFlagService,
        ){
    }

    ngOnInit(){ 
      this.flagSubscription = this.featureFlagService.isLumpSumEnabled.subscribe(res => this.lumpSumEnabled = res ? res : false);
      let isChangeInTemps = this.prodOfferingsService.changeInOfferings();
      if(isChangeInTemps){
        this.prodOfferingsService.resetOfferingStatus();
        this.getAllOfferingsCount();
      }else if(sessionStorage.getItem('offeringsData') && sessionStorage.getItem('offeringsCount')){
          this.offeringsData = JSON.parse(sessionStorage.getItem('offeringsData'));
          this.offeringsCount = JSON.parse(sessionStorage.getItem('offeringsCount'));
          this.loadSelectedOfferings(sessionStorage.getItem("OfferingStatus") ? sessionStorage.getItem("OfferingStatus") : 'Active');
        }else{
          this.getAllOfferingsCount();
      }
      if(sessionStorage.getItem('colorCodes')){
        this.categoryCodes = JSON.parse(sessionStorage.getItem('colorCodes'));
      }
    }

    checkForBBRole() {
      this.checkIsBB = this.prodOfferingsService.bbRole.getValue();
      if (this.checkIsBB[0] && this.checkIsBB[0] == 'BenefitsBuilder') {
        return true;
      } else {
        return false;
      }
    }

    goToOffering (referenceId) {
      this.router.navigate(['/view-offering', referenceId]);
    }

    editOffering (referenceId) {
      this.selectedOfferingData = this.offeringsData.selectedOfferings.filter(ele => ele.reference == referenceId);
      sessionStorage.setItem('offeringAction', 'edit');
      this.router.navigate(['/edit-offering',referenceId], { state: { selectedIcon: this.selectedOfferingData } });
    }

    createOffering() {
      sessionStorage.setItem('offeringAction','create');
      this.router.navigate(['/create-offering']);
    }

    getAllOfferingsCount(selectedOfferings?) {
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getAllOfferingsCount().subscribe({
        next: (response) => {
          this.prodOfferingsService.hideSpinner();
          if (response && response[0]) {
            this.offeringsCount = Object.assign(this.offeringsCount, response);
            for (let key in this.offeringsCount) {
              if (response[0][key]) {
                this.offeringsCount[key] = Object.assign(this.offeringsCount[key], response[0][key]);
              }
            }
            sessionStorage.setItem('offeringsCount', JSON.stringify(this.offeringsCount));
            //by default we have to load active offerings
            this.loadSelectedOfferings(selectedOfferings ? selectedOfferings : 'Active');
          }
        },
        error: (error) => {
          this.prodOfferingsService.hideSpinner();
        }
      });
    }


filterLumpSumofferings(response){
  const productOfferings = response.filter(ele => (ele?.productSpecificationReference[0]?.reference !== 'PRD-LmpSm'));
  return productOfferings
}
    getAllOfferings (search?:boolean) {
      this.disableScroll = true;
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getAllOfferings(this.prepareOfferingSearchCriteria(), this.filterPayload).subscribe({
        next: (response) => {
          let filteredOfferingList = [];
          console.log("response == ", response.body);
          response.body.forEach(ele => {
          })
          if(response && response.body.length){
            filteredOfferingList =  this.lumpSumEnabled ?  response.body  : this.filterLumpSumofferings(response.body);
          }
          this.prodOfferingsService.hideSpinner();
          if (response && response.body.length === 0 && this.searchOptions.skip === 1 && this.filterCount > 0) {
            this.offeringsData.selectedOfferings = [];
            this.searchCount = response.headers.get('x-order-count');
          }
          if (filteredOfferingList && filteredOfferingList.length) {
            if (!search) {
              this.offeringsData[this.searchOptions.sortDir][this.searchOptions.status][this.offeringsFilterVal + 'PageInfo'] = this.searchOptions.skip;
              // eslint-disable-next-line max-len
              this.offeringsData[this.searchOptions.sortDir][this.searchOptions.status][this.offeringsFilterVal] = [...this.offeringsData[this.searchOptions.sortDir][this.searchOptions.status][this.offeringsFilterVal], ...filteredOfferingList];
              this.offeringsData.selectedOfferings = this.offeringsData[this.searchOptions.sortDir][this.searchOptions.status][this.offeringsFilterVal];
            } else {
              this.offeringsData.selectedOfferings = [...this.offeringsData.selectedOfferings, ...filteredOfferingList];
            }
            if (response.headers.get('x-order-count')) {
              this.searchCount = response.headers.get('x-order-count');
            }

            sessionStorage.setItem('offeringsData', JSON.stringify(this.offeringsData));
          }

          this.disableScroll = false;
        },
        error: (error) => {
          this.disableScroll = false;
          this.prodOfferingsService.hideSpinner();
        }
      });
    }
  
    loadSelectedOfferings (offeringName:string) {
      sessionStorage.setItem("OfferingStatus",offeringName);
        this.searchOptions.status = offeringName; // Offering name should be 'Active' or 'Draft' or 'Archived'
        this.searchOptions.searchText = null; 
        this.showSearchCloseIcon = false;
        this.clearFilter();
        this.offeringsFilterOptions[0].count = this.offeringsCount[offeringName].total;
        this.offeringsFilterOptions[1].count = this.offeringsCount[offeringName].user;
        this.selectedOfferingName = this.offeringsCount[offeringName].displayName;
        this.offeringsData.selectedOfferings = this.offeringsData[this.searchOptions.sortDir][offeringName][this.offeringsFilterVal];
        this.searchOptions.skip = this.offeringsData[this.searchOptions.sortDir][offeringName][this.offeringsFilterVal+'PageInfo'];
        window.scroll(0,0); // Scroll to top when the user is switching offerings tabs
        if(!this.offeringsData.selectedOfferings.length){
            this.searchOptions.skip = 1;
            this.getAllOfferings();
        }
    }
  
    searchOfferings(){
        clearTimeout(this.timeOut);
        if(this.searchOptions.searchText){
          this.timeOut = setTimeout(() => {
            this.showSearchCloseIcon = true;
            this.searchOptions.skip =1;
            this.offeringsData.selectedOfferings = [];
            this.searchCount='0';
            this.getAllOfferings(true);
          }, 1000);
        }else{
          this.showSearchCloseIcon = false;
          this.loadSelectedOfferings(this.searchOptions.status);
        }
    }

    matFocusedEvent(event){
      this.matSelectFocused = event;
    }
  
    filterOrSortOfferings () {
        this.showSearchCloseIcon = false;
        this.loadSelectedOfferings(this.searchOptions.status); 
    }
  
    prepareOfferingSearchCriteria () {
        let params = this.searchOptions;
        if (!this.showSearchCloseIcon){
            delete params.searchText;
        }
        params.onlyShowMine = this.offeringsFilterVal == 'allOfferings' ? false : true ;
        return  Object.keys(params).map(key => `${key}=${params[key]}`).join("&");
    }

    @HostListener('window:scroll', ['$event'])
    addOfferingsToList (event) {
      // console.log(event);
      const element = event.target;
      let pos = (document.documentElement.scrollTop || document.body.scrollTop) + document.documentElement.offsetHeight +100;
      let max = document.documentElement.scrollHeight;
      let maxCount = this.showSearchCloseIcon ? parseInt(this.searchCount) : this.offeringsFilterVal == 'allOfferings'? this.offeringsFilterOptions[0].count : this.offeringsFilterOptions[1].count;

      // pos/max will give you the distance between scroll bottom and and bottom of screen in percentage.
      if(!this.disableScroll && (pos >= max) &&  (maxCount > this.offeringsData.selectedOfferings.length))   {
          this.disableScroll = true;
          this.searchOptions.skip ++;
          this.getAllOfferings((this.showSearchCloseIcon || this.filterCount > 0) ? true : false);
      }
    }

    dispalyCategory (offering){
      let category = {
        displayName:null,
        colorCode:null,
        count:0,
        list:[]
      }
      if(offering.category && Array.isArray(offering.category)){
        category.list = offering.category;
        category.displayName = offering.category[0];
        category.count =  category.list.length;
        category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
      }else if(offering.category && typeof offering.category == 'string'){
        category.list = offering.category.split(',');
        category.displayName = category.list[0];
        category.count = category.list.length;
        category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
      }else if(!offering.category && offering.productSpecificationReference[0]){
        if(offering.productSpecificationReference[0].category && Array.isArray(offering.productSpecificationReference[0].category)){
          category.list = offering.productSpecificationReference[0].category;
          category.displayName = offering.productSpecificationReference[0].category[0];
          category.count = category.list.length;
          category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
        }else if(offering.productSpecificationReference[0].category && typeof offering.productSpecificationReference[0].category == 'string'){
          category.list = offering.productSpecificationReference[0].category.split(',');
          category.displayName =  category.list[0];
          category.count = category.list.length;
          category.colorCode = this.categoryCodes && this.categoryCodes[category.displayName.replaceAll(' ','')] && this.categoryCodes[category.displayName.replaceAll(' ','')].color
        } 
      }
      return category;
    }


    getCreatedBy(createdByObj){
      let value = null;
      if(createdByObj && createdByObj.currentName
         && createdByObj.currentName.names && createdByObj.currentName.names.length) {
          value = createdByObj.currentName.names.toString().replace(',',' ');
      }

      return value;
    }

    restoreArchivedOffering (offeringReferenceId) {
      let dialogRef =this.dialog.open(WarningModelComponent,{
        data:{
          title:"Restore Confirmation",
          type:'restore',
          content:"Are you sure you want to restore this Offering? This offering will be moved to ‘Draft Offering’ .",
          actions:[
            {name:'Close',type:'close'},
            {name:'Restore',type:'restore'},
          ]
        },
        width:'32.74em'
      });

      dialogRef.afterClosed().subscribe((action) =>{
       
        if(action == 'restore' && offeringReferenceId){
          this.restoreOfferingCall(offeringReferenceId)
        }
      })
    }

  addToTemplate (referenceVal) {
    this.associatedTemplates = [];
    this.prodOfferingsService.showSpinner();
    this.prodOfferingsService.getTemplatesByReference(referenceVal).subscribe({
      next: (response) => {
        this.prodOfferingsService.hideSpinner();
        if (response) {
          this.associatedTemplates = response;
          let templateData = this.associatedTemplates && this.associatedTemplates.length ? [...this.associatedTemplates] : [];
          this.openTemplatesModel(templateData, referenceVal);
        }
        // console.log(response);
      },
      error: (error) => {
        this.prodOfferingsService.hideSpinner();
        this.openTemplatesModel([], referenceVal);
      }
    });
  }

  openTemplatesModel (templateData,referenceVal) {
    let dialogRef =this.dialog.open(AddTemplateModelComponent,{
      data:{
        title:"Select Template",
        actions:[
          {name:'Close',type:'close'},
          {name:'Add to Template',type:'add'},
        ],
        templates: templateData
      },
      width: '832px',
      height: '877px',
      panelClass: 'add-template-modalbox'
    });

    dialogRef.afterClosed().subscribe((action) =>{
      if(action.type == 'add'){
        this.associatedTemplates = action.data;
        this.updateAssociatedTemplates(referenceVal);
      }
    })
  }

  updateAssociatedTemplates (referenceVal) {
    this.prodOfferingsService.showSpinner();
    this.prodOfferingsService.updateTemplatesToOffering(referenceVal, this.prepareTemplates()).subscribe({
      next: (response) => {
        this.prodOfferingsService.hideSpinner();
        if (response) {
          console.log('templates updated successfully!');
        }
      },
      error: (error) => {
        this.prodOfferingsService.hideSpinner();
      }
    });
  }
  
  prepareTemplates () {
    let obj = {
      "draftTemplateIds": [],
      "existingTemplateIds": []
    }
    if(this.associatedTemplates && this.associatedTemplates.length){
      this.associatedTemplates.forEach(item =>{
        if(item.status == "Existing" || item.status == "Active"){
          obj.existingTemplateIds.push(item._id)
        }
        if(item.status == "Draft"){
          obj.draftTemplateIds.push(item._id)
        }
      })
    }
    return obj;
  }

  restoreOfferingCall(offeringReferenceId){
    this.prodOfferingsService.showSpinner();
    this.prodOfferingsService.restoreArchvedOffering(offeringReferenceId).subscribe({
      next: (response) => {
        this.prodOfferingsService.hideSpinner();
        if (response) {
          this.offeringsData[this.searchOptions.sortDir][this.searchOptions.status][this.offeringsFilterVal + 'PageInfo'] = 1;
          this.offeringsData[this.searchOptions.sortDir][this.searchOptions.status][this.offeringsFilterVal] = [];
          this.offeringsData[this.searchOptions.sortDir]['Draft'][this.offeringsFilterVal + 'PageInfo'] = 1;
          this.offeringsData[this.searchOptions.sortDir]['Draft'][this.offeringsFilterVal] = [];
          this.offeringsData.selectedOfferings = [];
          this.getAllOfferingsCount(this.searchOptions.status);
        }
      },
      error: (error) => {
        this.prodOfferingsService.hideSpinner();
      }
    });
  }

    /**to open Filter modal */
  openFilterModal() {
    let data = {};
    if(this.filterCount > 0) {
      const filterData = {
        'categories' : this.filterPayload.categories,
        'applicationNameValues' : this.filterPayload.applicationNames,
        'productNameValues' : this.filterPayload.productNames,
        'createdByValues' : this.filterPayload.createdBy
      }
      data['filterData'] = filterData;
      data['filteredCount'] = this.filterCount;
    }
    let dialogRef = this.dialog.open(FilterModalComponent, {
      width: '50%',
      height: '95%',
      data: data
    });
    dialogRef.afterClosed().subscribe(data => {
      if(data) {
        if (data.count) {
          this.searchOptions.skip =1;
        }
        this.filterCount = data.count;
        this.filterPayload.categories = data.categories;
        this.filterPayload.applicationNames = data.applicationNameValues;
        this.filterPayload.productNames = data.productNameValues;
        this.filterPayload.createdBy = data.createdByValues;
        // this.isFilterCleared = data.isFilterCleared;
        this.offeringsData.selectedOfferings = [];
        this.searchCount='0';
        this.getAllOfferings(true);
      }
    });
  }

  clearFilter() {
    this.filterCount = 0;
    this.filterPayload = {
      'categories': [],
      'applicationNames': [],
      'productNames': [],
      'createdBy': []
    }
  }

  ngOnDestroy() {
      this.flagSubscription?.unsubscribe();  
  }
}