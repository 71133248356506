import { Component, OnInit ,Input, Inject, Output,EventEmitter, ViewEncapsulation, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray, UntypedFormControl, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { RemoteLoggingService } from '../../services/remote-logging.service';
import { ToastrService } from 'ngx-toastr';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSelectionList, MatListOption } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import {​​ LocationsService }​​ from '../../services/locations.service';
import dialogProgramData from '../../data/mock/programDetails.json'
import { ProdOfferingsService ,SimpleRule} from '../../services/product-offerings.service';


@Component({
  selector: 'app-edit-benefit-logic-dialog',
  templateUrl: './edit-benefit-logic-dialog.component.html',
  styleUrls: ['./edit-benefit-logic-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})


export class EditBenefitLogicDialogComponent implements OnInit {
  
  rule = new UntypedFormControl();
  value = 'Clear me';
  ruleForm: UntypedFormGroup;
  restrictionForm : UntypedFormGroup;
  clientDivisions: Array<any>;
  countryList: Array<any>;
  
  ruleList: Array<any> =  [{name: "Enable Linked Benefit", isDisable: false}, {name: "Disable Linked Benefit", isDisable: false}, {name: "Select Linked Benefit", isDisable: false}];
  disableSave: boolean = true;
  disableAddRule: boolean = true;
  addedDivision: any;
  addRule: any;
  @ViewChild('divisionSelect', {static: true}) divisionSelect: MatListOption;
  @ViewChild('allSelect', {static: true}) allSelect: MatListOption;
  @Output() publishDivisions = new EventEmitter<String>();

  selectedValue: string;
  selectedCar: string;
  allBenefitList: Array<any>; //filtered benefit list
  originalBenefitList: Array<any>; //original benefit list
  allRuleList: Array<any>;
  allRestrictionList: Array<any> = [];
  countryListForEachRestrictionType = [
    { name: 'Departure Location Includes…', countryList: [] },
    { name: 'Departure Location Excludes…', countryList: [] },
    { name: 'Destination Location Includes…', countryList: [] },
    { name: 'Destination Location Excludes…', countryList: [] }
  ];
  restrictionList: Array<any> = [];
  allCountryList: Array<any> = [];
  deletedComplexRestrictions: Array<any> =[];
  restrictDisableFlag:Boolean= false;
  /* Managemove client */
  isMMUser :boolean = false;
  cashOutMMUserRestrictionForm: UntypedFormGroup; //cashOutRestionForm
  checked:boolean = true;
  configurations:any = {};
  milestoneValues:any = [];
  percentageCounter:number = 0;
  daysCounter:number = 0;
  isArrowVisible: boolean = true;
  tasksForm: UntypedFormGroup; //tasksForm
  operatorsList:Array<any> = []; //operators list
  constructor(
    public dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    public spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<EditBenefitLogicDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly locationService: LocationsService,
    public prodOfferingsService : ProdOfferingsService
  ) { 
    dialogRef.disableClose = true;
    this.isMMUser = JSON.parse(sessionStorage.getItem("car-ses-mmu"));
  }

  ngOnInit() {
    this.allRestrictionList = this.data.allRestrictionList;
    this.restrictionList = this.data.allRestrictionList;
    this.getCountries();
    this.getOperatorValues();
    this.allBenefitList = [...this.data.benefitList];
    this.originalBenefitList = [...this.data.allBenefitList];
    this.allRuleList = [...this.ruleList];
    this.milestoneValues = ['Job Start Date'];
    this.cashOutMMUserRestrictionForm = this.formBuilder.group({
      percentAllowed: ['percentAllowed', Validators.required],
      days: ['days', Validators.required],
      milestone: ['milestone', Validators.required]
    });
    this.cashOutMMUserRestrictionForm.patchValue({
      percentAllowed: 0,
      days: 0,
      milestone: 'Job Start Date'
    });
    this.generateForm();
  }
  /**increment arrow of cashout percent input */
  increasePercentValue(){
    this.percentageCounter = (this.cashOutMMUserRestrictionForm.value.percentAllowed < 100) ? (this.cashOutMMUserRestrictionForm.value.percentAllowed + 1)  : 100;
    this.cashOutMMUserRestrictionForm.patchValue({
      percentAllowed: this.percentageCounter
    })
  }
  decreasePercentValue(){
    this.percentageCounter = (this.cashOutMMUserRestrictionForm.value.percentAllowed !== 0 ) ? (this.cashOutMMUserRestrictionForm.value.percentAllowed - 1) : 0;
    this.cashOutMMUserRestrictionForm.patchValue({
      percentAllowed: this.percentageCounter
    })
  }
  increaseDaysValue(e){
    this.daysCounter = this.cashOutMMUserRestrictionForm.value.days + 1;
    this.cashOutMMUserRestrictionForm.patchValue({
      days: this.daysCounter
    })
  }
  decreaseDaysValue(){
    this.daysCounter = (this.cashOutMMUserRestrictionForm.value.days !== 0 ) ? (this.cashOutMMUserRestrictionForm.value.days - 1) : 0;
    this.cashOutMMUserRestrictionForm.patchValue({
      days: this.daysCounter
    })
  }
  getCountries() {
    this.locationService.countryList.subscribe(countryList => {
      if (countryList.length > 0) {
        this.allCountryList = [...countryList];
        this.allCountryList.sort(function (a, b) {
          return a.countryName.localeCompare(b.countryName);
        });
        this.countryList = [...this.allCountryList];
        this.countryListForEachRestrictionType.forEach(ele=>ele.countryList=[...this.allCountryList]);
      }
    });
  }
  generateForm() {
    if (this.data.benefit.scopes) {
      if (this.data.benefit.scopes.complex_rules) {
        this.data.benefit.scopes.complex_rules.forEach(ele => {
          if (ele.hasOwnProperty('action')) {
            this.deletedComplexRestrictions.push(ele);
          }
        });
        this.data.benefit.scopes.complex_rules = this.data.benefit.scopes.complex_rules.filter(ele => !ele.hasOwnProperty('action'));
      }
    }

    this.ruleForm = new UntypedFormGroup({
      ruleArray: new UntypedFormArray([
        this.initRuleSection()
      ]),
    });

    this.restrictionForm = new UntypedFormGroup({
      restrictionArray: new UntypedFormArray([])
    });
    // tasks form
    this.tasksForm = new UntypedFormGroup({
      tasksArray: new UntypedFormArray([])
    });
    // this.addEmptyRestrictions();
    const prerequisites = [];
    let prerequisitesIndex;
    const exclusions = [];
    let exclusionIndex;
    const inclusions = [];
    let inclusionIndex;
 
    if (this.data.prerequisites && this.data.prerequisites.length > 0) {
      this.data.prerequisites.forEach((item) => {
        //prerequisitesIndex = this.originalBenefitList.findIndex(benefit => benefit.reference === item.reference);
        prerequisites.push({ind: item});
        if(this.data.prerequisites.length > this.ruleForm.get(['ruleArray', 0, 'addedRule']).value.length) {
        const formArrayControl = <UntypedFormArray>this.ruleForm.get(['ruleArray', 0, 'addedRule']);
        formArrayControl.push(this.initBenefitSection());
        }
      }); 
      //prerequisites.push({ind: ''});
      this.ruleForm.get('ruleArray').setValue([{
        rule: 'Enable Linked Benefit',
        addedRule : prerequisites
      }]);
      this.checkRule('Enable Linked Benefit');
    }
    if (this.data.benefit.exclusions && this.data.benefit.exclusions.length > 0) {
      this.data.benefit.exclusions.forEach((item) => {
        exclusionIndex = this.originalBenefitList.findIndex(benefit => benefit.reference === item);
        if(exclusionIndex !== -1){ exclusions.push({ind: this.originalBenefitList[exclusionIndex]})};
        if (this.ruleForm.get('ruleArray').value.length < 3
          && ((this.data.prerequisites && this.data.prerequisites.length > 0))) {
          const control = <UntypedFormArray>this.ruleForm.get('ruleArray');
          control.push(this.initRuleSection());
        }
      });
      const formArrayControl = <UntypedFormArray>this.ruleForm.get(['ruleArray', this.ruleForm.get(['ruleArray']).value.length - 1, 'addedRule']);
      for (let i = 0; i < exclusions.length -1 ; i++) {
        formArrayControl.push(this.initBenefitSection()) ;
      }
      this.ruleForm.get(['ruleArray', this.ruleForm.get(['ruleArray']).value.length - 1]).setValue({
        rule: 'Disable Linked Benefit',
        addedRule : exclusions
      });
      this.checkRule('Disable Linked Benefit');
    }
    /**BB-5412 inclusion and new rule - Select Linked Benefit Starts**/
    if (this.data.benefit.inclusions && this.data.benefit.inclusions.length > 0) {
      this.data.benefit.inclusions.forEach((item) => {
        inclusionIndex = this.originalBenefitList.findIndex(benefit => benefit.reference === item);
        if(inclusionIndex !== -1){
          inclusions.push({ind: this.originalBenefitList[inclusionIndex]});
        }
        if (this.ruleForm.get('ruleArray').value.length < 3
          && ((this.data.prerequisites && this.data.prerequisites.length > 0) || 
          (this.data.benefit.exclusions && this.data.benefit.exclusions.length > 0))) {
          const control = <UntypedFormArray>this.ruleForm.get('ruleArray');
          control.push(this.initRuleSection());
        }
      });
      const formArrayControl = <UntypedFormArray>this.ruleForm.get(['ruleArray', this.ruleForm.get(['ruleArray']).value.length - 1, 'addedRule']);
      for (let i = 0; i < inclusions.length -1 ; i++) {
        formArrayControl.push(this.initBenefitSection()) ;
      }
      this.ruleForm.get(['ruleArray', this.ruleForm.get(['ruleArray']).value.length - 1]).setValue({
        rule: 'Select Linked Benefit',
        addedRule : inclusions
      });
      this.checkRule('Select Linked Benefit');
    }
    if (this.data.benefit.scopes) { 
      if (this.data.benefit.scopes.departure_locations) {
        if (this.data.benefit.scopes.departure_locations.hasOwnProperty('includes') && this.data.benefit.scopes.departure_locations.includes.length>0) {
          this.restrictionArray.push(
            new UntypedFormGroup({
              restriction: new UntypedFormControl('Departure Location Includes…'),
              addedRestrictionArray: new UntypedFormArray(this.data.benefit.scopes.departure_locations.includes.map(ele=>new UntypedFormControl(ele)))
            })
          );
          this.restrictionList = this.restrictionList.filter(ele => ((ele.name !== 'Departure Location Excludes…') && (ele.name !== 'Departure Location Includes…')));
        }
        if (this.data.benefit.scopes.departure_locations.hasOwnProperty('excludes') && this.data.benefit.scopes.departure_locations.excludes.length>0) {
          this.restrictionArray.push(
            new UntypedFormGroup({
              restriction: new UntypedFormControl('Departure Location Excludes…'),
              addedRestrictionArray: new UntypedFormArray(this.data.benefit.scopes.departure_locations.excludes.map(ele=>new UntypedFormControl(ele)))
            })
          );
          this.restrictionList = this.restrictionList.filter(ele => ((ele.name !== 'Departure Location Excludes…') && (ele.name !== 'Departure Location Includes…')));
        }
      }
      if (this.data.benefit.scopes.destination_locations) { 
        if (this.data.benefit.scopes.destination_locations.hasOwnProperty('includes') && this.data.benefit.scopes.destination_locations.includes.length>0) {
          this.restrictionArray.push(
            new UntypedFormGroup({
              restriction: new UntypedFormControl('Destination Location Includes…'),
              addedRestrictionArray: new UntypedFormArray(this.data.benefit.scopes.destination_locations.includes.map(ele=>new UntypedFormControl(ele)))
            })
          );
          this.restrictionList = this.restrictionList.filter(ele => ((ele.name !== 'Destination Location Excludes…') && (ele.name !== 'Destination Location Includes…')));
        }
        if (this.data.benefit.scopes.destination_locations.hasOwnProperty('excludes') && this.data.benefit.scopes.destination_locations.excludes.length>0) {
          this.restrictionArray.push(
            new UntypedFormGroup({
              restriction: new UntypedFormControl('Destination Location Excludes…'),
              addedRestrictionArray: new UntypedFormArray(this.data.benefit.scopes.destination_locations.excludes.map(ele=>new UntypedFormControl(ele)))
            })
          );
          this.restrictionList = this.restrictionList.filter(ele => ((ele.name !== 'Destination Location Excludes…') && (ele.name !== 'Destination Location Includes…')));
        }
      }
      if (this.data.benefit.scopes.hasOwnProperty('spouse_partner')) {
        if (this.data.benefit.scopes.spouse_partner.spousePartnerRelocating) {
          this.restrictionArray.push(
            new UntypedFormGroup({
              restriction: new UntypedFormControl('Transferee with Spouse'),
              addedRestrictionArray: new UntypedFormArray([])
            })
          );
          this.restrictionList = this.restrictionList.filter(ele => (ele.name !== 'Transferee with Spouse'));
        }
      }
      if (this.data.benefit.scopes.hasOwnProperty('complex_rules')) {
        
          this.data.benefit.scopes.complex_rules.forEach(ele => {
            this.restrictionArray.push(
              new UntypedFormGroup({
                restriction: new UntypedFormControl(ele.name),
                addedRestrictionArray: new UntypedFormArray([])
              })
            );
            this.restrictionList = this.restrictionList.filter(curr => (curr.name !== ele.name));
          })
        
      }
      /**cashout MMClient logic */
      if (this.data.benefit.scopes.hasOwnProperty('triggered_access')) {
        if (this.data.benefit.scopes.triggered_access) {
          this.restrictionArray.push(
            new UntypedFormGroup({
              restriction: new UntypedFormControl('Triggered Access'),
              addedRestrictionArray: new UntypedFormArray([])
            })
          );
          this.cashOutMMUserRestrictionForm = this.formBuilder.group({
            percentAllowed: ['percentAllowed', Validators.required],
            days: ['days', Validators.required],
            milestone: ['milestone', Validators.required]
          });
          this.isArrowVisible = false;
          this.cashOutMMUserRestrictionForm.get('percentAllowed').setValue(this.data.benefit.scopes.triggered_access.percentage);
          this.cashOutMMUserRestrictionForm.get('days').setValue(this.data.benefit.scopes.triggered_access.noOfDays);
          this.cashOutMMUserRestrictionForm.get('milestone').setValue(this.data.benefit.scopes.triggered_access.milestone);
          this.cashOutMMUserRestrictionForm.controls['percentAllowed'].disable();
          this.cashOutMMUserRestrictionForm.controls['days'].disable();
          this.cashOutMMUserRestrictionForm.controls['milestone'].disable();
          this.restrictionList = this.restrictionList.filter(ele => (ele.name !== 'Triggered Access'));
        }
      }
      /**simple_rules type */
      if (this.data.benefit.scopes.hasOwnProperty('simple_rules')) { 
        
        this.data.benefit.scopes.simple_rules.forEach(ele => {
          this.restrictionArray.push(
            new UntypedFormGroup({
              restriction: new UntypedFormControl(ele.type),
              addedRestrictionArray: new UntypedFormArray([
                new UntypedFormGroup({
                  values: new UntypedFormArray(ele.values.map(element=>new UntypedFormGroup({
                    operator: new UntypedFormControl(element.operator),
                    value: new UntypedFormControl(element.value),
                   })))
                })
              ])
            })
          );
          this.restrictionList = this.restrictionList.filter(curr => (curr.name !== ele.type));
        })
      }

    }
    if ((<UntypedFormArray>this.restrictionForm.get('restrictionArray')).value.length === 8) {
      this.restrictDisableFlag = true;
    } else {
    this.addEmptyRestrictions();
    }
    this.ruleForm.get('ruleArray').value.forEach((rule, key) => {
      this.ruleForm.get('ruleArray').value[key].addedRule.forEach((element, index) => {
        index < rule.addedRule.length - 1 ? 
          this.data.benefitList = this.data.benefitList.filter(benefit => benefit.reference !== element.ind.reference): null;
      });
    });
    // Tasks pushed to tasks form
    if(this.data.benefit.tasks && this.data.benefit.tasks.length) {
      this.data.benefit.tasks.forEach(element => {   
        this.tasksArray.push(new UntypedFormControl(element, Validators.required))
      });
    }
}

disableRestriction(){
  if (this.restrictionForm.get('restrictionArray').value.length > 0 && this.restrictionForm.get('restrictionArray').value[this.restrictionForm.get('restrictionArray').value.length - 1].restriction === ''
    || (this.restrictionList.length <= 1)
    || !this.restrictionForm.get('restrictionArray').valid) {
    return true;
  }
  return false;
}

  addBenefitFormField(ruleIndex) {
    const formArrayControl = <UntypedFormArray>this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']);
    if(formArrayControl.value.length === 0){formArrayControl.push(this.initBenefitSection())};
  }

  get addedRule(): UntypedFormArray {
    return this.ruleForm.get('addedRule') as UntypedFormArray;
  }

  checkRemoveButtonVisibility2(index: number, form) {
    
    if (index === 0 && form.value.addedRule.length === 1 && form.value.addedRule[0].ind == '') {
      return false;
    }
    return true;
  }

  checkAddButtonVisibility2(index: number, form) {
    if (index === form.value.addedRule.length - 1) {
      return true;
    }
    return false;
  }

  pushBackToListCountry(ind, restrictItem, restrictSectionIndex, restrictSection) {
    if (restrictItem.value !== '') {
      let countryList = this.countryListForEachRestrictionType[this.countryListForEachRestrictionType.findIndex(ele => ele.name === restrictSection.get('restriction').value)].countryList;
      countryList.push(this.allCountryList.find((ele => ele.countryName === restrictItem.value)));
      countryList.sort(function (a, b) {
        return a.countryName.localeCompare(b.countryName);
      });
    }
    if (ind === restrictSection.get('addedRestrictionArray').controls.length - 1) {
      restrictSection.get('addedRestrictionArray').controls[ind].setValue('');
    }else if ((restrictSection.get('addedRestrictionArray').controls[ind].value !== '') && restrictSection.get('addedRestrictionArray').controls.length > 1) {
      (<UntypedFormArray>restrictSection.get('addedRestrictionArray')).removeAt(ind);
    }else if (restrictSection.get('addedRestrictionArray').controls.length <= 1 && ind == 0 && restrictSection.get('addedRestrictionArray').controls[0] ) {
      restrictSection.get('addedRestrictionArray').controls[0].setValue('');
    }
  }

  
  pushBackToList(index: number, ruleIndex) {
    const control = this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule', index]);
    const findIndex = this.data.benefitList.findIndex(x => 
      x.reference === control.value.ind.reference);
    let removeValue;
      if (this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']).value.length > 0) {
        removeValue = control.value.ind;
        if (this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']).value.length === 1) {
          this.checkRuleDisable(this.ruleForm.get(['ruleArray', ruleIndex]).value.rule); //to enable the rule in drop down
          this.ruleForm.get(['ruleArray', ruleIndex]).value.rule === 'Enable Linked Benefit' ? this.data.prerequisites = [] : null;
          this.ruleForm.get(['ruleArray', ruleIndex]).value.rule === 'Disable Linked Benefit' ? this.data.benefit.exclusions = [] : null;
          this.ruleForm.get(['ruleArray', ruleIndex]).value.rule === 'Select Linked Benefit' ? this.data.benefit.inclusions = [] : null;
        }
        const formArrayControl = <UntypedFormArray>this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']);
        formArrayControl.value.length === 1? this.ruleForm.get(['ruleArray', ruleIndex, 'rule']).setValue(''): null;
        formArrayControl.value.forEach(item => {
          this.data.benefitList.findIndex(ele => ele.reference === item.ind.reference) === -1 ? this.data.benefitList.push(item.ind) : null;
        });
        this.data.benefitList.sort((a, b) => a.displayName ? a.displayName.localeCompare(b.displayName) : null);
        formArrayControl.removeAt(index);
        this.disableSave = false;
      }
  }

  associateBenefit(index, ruleIndex) {
    const formArrayControl = <UntypedFormArray>this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']);
    //const control = formArrayControl.controls[index].get('addedRule');
    formArrayControl.push(this.initBenefitSection());
    this.ruleForm.get('ruleArray').value.forEach((rule) => { 
      rule.addedRule.forEach(element => {
        this.data.benefitList = this.data.benefitList.filter(benefit => benefit.reference !== element.ind.reference);
      });
    });
    this.disableSave = false;
  }

  compareBenefits(o1: any, o2: any): boolean {
    return o1.reference === o2.reference;
  }

  close() {
    this.dialogRef.close();
  }

  clearRestrictions(restrictSectionIndex, restrictSection) {
    if (restrictSection.get('restriction').value !=='' &&(<UntypedFormArray>this.restrictionForm.get('restrictionArray')).length > 0 && (<UntypedFormArray>this.restrictionForm.get('restrictionArray')).length - 1 !== restrictSectionIndex) {
      if (this.data.benefit.scopes) {
        let restrictSectionValue = restrictSection.get('restriction').value;
        if (this.data.benefit.scopes.departure_locations) {
          restrictSectionValue === 'Departure Location Includes…' ? this.data.benefit.scopes.departure_locations.includes = [] : null;
          restrictSectionValue === 'Departure Location Excludes…' ? this.data.benefit.scopes.departure_locations.excludes = [] : null;
        }
        if (this.data.benefit.scopes.destination_locations) {
          restrictSectionValue === 'Destination Location Includes…' ? this.data.benefit.scopes.destination_locations.includes = [] : null;
          restrictSectionValue === 'Destination Location Excludes…' ? this.data.benefit.scopes.destination_locations.excludes = [] : null;
        }
        if (this.data.benefit.scopes.complex_rules) { 
          let complexIndex = this.data.benefit.scopes.complex_rules.findIndex(ele => ele.name === restrictSectionValue);
          if ( complexIndex !== -1 && this.data.benefit.scopes.complex_rules[complexIndex].hasOwnProperty('id')) {
            this.deletedComplexRestrictions.push(Object.assign({}, { ...this.data.benefit.scopes.complex_rules[complexIndex], action: 'delete' }));
          }
          complexIndex !== -1 ? this.data.benefit.scopes.complex_rules.splice(complexIndex, 1) : null;
        }
        if (this.data.benefit.scopes.spouse_partner) { 
          this.allRestrictionList.find(obj => obj.name === restrictSectionValue).type === 'transferee_related' ? this.data.benefit.scopes.spouse_partner.spousePartnerRelocating = false : null;
        }
        if (this.data.benefit.scopes.triggered_access) { 
          this.allRestrictionList.find(obj => obj.name === restrictSectionValue).type === 'triggered_access' ? this.data.benefit.scopes.triggered_access = {action: 'delete'} : null;
        }
        if (this.data.benefit.scopes.simple_rules) { 
          let simpleIndex = this.data.benefit.scopes.simple_rules.findIndex(ele => ele.type === restrictSectionValue);
          simpleIndex !== -1 ? this.data.benefit.scopes.simple_rules.splice(simpleIndex, 1) : null;
        }
      }
      if(restrictSection.value.restriction === 'Triggered Access'){
          /**clearing the cashout restriction card only for MM client */
          this.cashOutMMUserRestrictionForm.patchValue({
            percentAllowed: 0,
            days: 0,
            milestone: 'Job Start Date'
          });
          this.isArrowVisible = true;
          this.cashOutMMUserRestrictionForm.controls['percentAllowed'].enable();
          this.cashOutMMUserRestrictionForm.controls['days'].enable();
          this.cashOutMMUserRestrictionForm.controls['milestone'].enable();
      }
      if (this.restrictDisableFlag) {
        this.addEmptyRestrictions();
        this.restrictDisableFlag = false;
      }
      (<UntypedFormArray>this.restrictionForm.get('restrictionArray')).removeAt(restrictSectionIndex);
      this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === restrictSection.value.restriction));
      restrictSection.value.restriction === 'Departure Location Includes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Departure Location Excludes…')) : null;
      restrictSection.value.restriction === 'Departure Location Excludes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Departure Location Includes…')) : null;
      restrictSection.value.restriction === 'Destination Location Includes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Destination Location Excludes…')) : null;
      restrictSection.value.restriction === 'Destination Location Excludes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Destination Location Includes…')) : null
    }else if (restrictSection.get('restriction').value !=='' && (<UntypedFormArray>this.restrictionForm.get('restrictionArray')).length - 1 === restrictSectionIndex) {​​​​​​​
      if (this.data.benefit.scopes) {
        let restrictSectionValue = restrictSection.get('restriction').value;
        if (this.data.benefit.scopes.departure_locations) {
          restrictSectionValue === 'Departure Location Includes…' ? this.data.benefit.scopes.departure_locations.includes = [] : null;
          restrictSectionValue === 'Departure Location Excludes…' ? this.data.benefit.scopes.departure_locations.excludes = [] : null;
        }
        if (this.data.benefit.scopes.destination_locations) {
          restrictSectionValue === 'Destination Location Includes…' ? this.data.benefit.scopes.destination_locations.includes = [] : null;
          restrictSectionValue === 'Destination Location Excludes…' ? this.data.benefit.scopes.destination_locations.excludes = [] : null;
        }
        if (this.data.benefit.scopes.complex_rules) { 
          let complexIndex = this.data.benefit.scopes.complex_rules.findIndex(ele => ele.name === restrictSectionValue);
          if ( complexIndex !== -1 && this.data.benefit.scopes.complex_rules[complexIndex].hasOwnProperty('id')) {
            this.deletedComplexRestrictions.push(Object.assign({}, { ...this.data.benefit.scopes.complex_rules[complexIndex], action: 'delete' }));
          }
          complexIndex !== -1 ? this.data.benefit.scopes.complex_rules.splice(complexIndex, 1) : null;
        }
        if (this.data.benefit.scopes.spouse_partner) { 
          this.allRestrictionList.find(obj => obj.name === restrictSectionValue).type === 'transferee_related' ? this.data.benefit.scopes.spouse_partner.spousePartnerRelocating = false : null;
        }
        if (this.data.benefit.scopes.triggered_access) { 
          this.allRestrictionList.find(obj => obj.name === restrictSectionValue).type === 'triggered_access' ? this.data.benefit.scopes.triggered_access = {action: 'delete'} : null;
        }
        if (this.data.benefit.scopes.simple_rules) { 
          let simpleIndex = this.data.benefit.scopes.simple_rules.findIndex(ele => ele.type === restrictSectionValue);
          simpleIndex !== -1 ? this.data.benefit.scopes.simple_rules.splice(simpleIndex, 1) : null;
        }
      }
      if(restrictSection.value.restriction === 'Triggered Access'){
        /**clearing the cashout restriction card only for MM client */
        this.cashOutMMUserRestrictionForm.patchValue({
          percentAllowed: 0,
          days: 0,
          milestone: 'Job Start Date'
        });
        this.cashOutMMUserRestrictionForm.controls['percentAllowed'].enable();
        this.cashOutMMUserRestrictionForm.controls['days'].enable();
        this.cashOutMMUserRestrictionForm.controls['milestone'].enable();
    }
      this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === restrictSection.value.restriction));
      restrictSection.value.restriction === 'Departure Location Includes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Departure Location Excludes…')) : null;
      restrictSection.value.restriction === 'Departure Location Excludes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Departure Location Includes…')) : null;
      restrictSection.value.restriction === 'Destination Location Includes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Destination Location Excludes…')) : null;
      restrictSection.value.restriction === 'Destination Location Excludes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Destination Location Includes…')) : null;
      (<UntypedFormArray>this.restrictionForm.get('restrictionArray')).at(restrictSectionIndex).patchValue({​​​​​​​
        restriction: '',
        addedRestrictionArray: []
      });
      this.restrictDisableFlag = false;
    }
    this.restrictionList = this.restrictionList.filter((ele, index, self) =>
      index === self.findIndex((t) => (
        t?.name === ele?.name
      ))
    )​​​​​​​
  }

  clearRestrictions2(ruleIndex){
    const control = this.ruleForm.get(['ruleArray', ruleIndex]);
    //const findIndex = this.data.benefitList.findIndex(x => x.reference === control.value.ind.reference);
    let removeValue;
      if (this.ruleForm.get('ruleArray').value.length > 0) {
        removeValue = control.value;
        this.ruleList.findIndex(rule => rule.name === removeValue.rule) > -1 ? this.checkRuleDisable(removeValue.rule) : null;
        removeValue.rule === 'Enable Linked Benefit' ? this.data.prerequisites = [] : null;
        removeValue.rule === 'Disable Linked Benefit' ? this.data.benefit.exclusions = [] : null;
        removeValue.rule === 'Select Linked Benefit' ? this.data.benefit.inclusions = [] : null;
        if (this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']).value.length > 0) {
          const benefitformArrayControl = <UntypedFormArray>this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']);
          benefitformArrayControl.value.forEach(item => {
            this.data.benefitList.findIndex(ele => 
              ele.reference === item.ind.reference) === -1 ? this.data.benefitList.push(item.ind) : null;
          });
          this.data.benefitList.sort((a, b) => a.displayName.localeCompare(b.displayName));
        }
        const formArrayControl = <UntypedFormArray>this.ruleForm.get(['ruleArray']);
        formArrayControl.removeAt(ruleIndex);
        this.disableSave = false;
        formArrayControl.value.length === 0 ? this.ruleForm = new UntypedFormGroup({
          ruleArray: new UntypedFormArray([
            this.initRuleSection()
          ]),
        }) : null;
      }
     
  }

  addRuleSection() {
    const control = <UntypedFormArray>this.ruleForm.get('ruleArray');
    control.push(this.initRuleSection());
    this.ruleForm.get('ruleArray').value.forEach((rule) => {
      rule.addedRule.forEach(element => {
        this.data.benefitList = this.data.benefitList.filter(benefit => benefit.reference !== element.ind.reference);
      });
    });
    this.disableSave = false;
  }
  checkRule(rule){
    let rulesVal = this.allRuleList; //this.ruleForm.get('ruleArray').value;
    if (rulesVal && rulesVal.length) {
      rulesVal.forEach((element) => {
        if (element.name == rule) {
          element.isDisable = true;
        }
      });
    }
  }
  checkRuleDisable(rule){
    let rulesVal = this.allRuleList; //this.ruleForm.get('ruleArray').value;
    if (rulesVal && rulesVal.length) {
      rulesVal.forEach((element) => {
        if (element.name == rule) {
          element.isDisable = false;
        }
      });
    }
  }
  addRestrictionSection() {
    let restrictionType = this.getRestrictionType((<UntypedFormArray>this.restrictionForm.get('restrictionArray')).controls.length-1).value;
    if ((this.restrictionList[this.restrictionList.findIndex(obj => obj.name === restrictionType)].type === 'departure_locations' ||
      this.restrictionList[this.restrictionList.findIndex(obj => obj.name === restrictionType)].type === 'destination_locations'
    )) {
      restrictionType === 'Departure Location Includes…' ? this.restrictionList = this.restrictionList.filter(ele => ele.name !== 'Departure Location Excludes…') : null;
      restrictionType === 'Departure Location Excludes…' ? this.restrictionList = this.restrictionList.filter(ele => ele.name !== 'Departure Location Includes…') : null;
      restrictionType === 'Destination Location Includes…' ? this.restrictionList = this.restrictionList.filter(ele => ele.name !== 'Destination Location Excludes…') : null;
      restrictionType === 'Destination Location Excludes…' ? this.restrictionList = this.restrictionList.filter(ele => ele.name !== 'Destination Location Includes…') : null;
    }
    if ((this.restrictionList[this.restrictionList.findIndex(obj => obj.name === restrictionType)].type === 'triggered_access') && this.isMMUser){
      this.configurations = {
        percentage: this.cashOutMMUserRestrictionForm.value.percentAllowed,
        noOfDays: this.cashOutMMUserRestrictionForm.value.days,
        milestone: this.cashOutMMUserRestrictionForm.value.milestone
      }
      this.cashOutMMUserRestrictionForm.controls['percentAllowed'].disable();
      this.cashOutMMUserRestrictionForm.controls['days'].disable();
      this.cashOutMMUserRestrictionForm.controls['milestone'].disable();
    }
    this.addEmptyRestrictions();
    this.restrictionForm.get('restrictionArray').value.forEach(element => {
      this.restrictionList = this.restrictionList.filter(restriction => restriction.name !== element.restriction);
    });
    this.addedRestrictionArray((<UntypedFormArray>this.restrictionForm.get('restrictionArray')).controls.length-1).clear();
    this.countryList = [...this.allCountryList];
    
  }
  get restrictionArray(): UntypedFormArray {
    return this.restrictionForm.controls.restrictionArray as UntypedFormArray;
  }
  get tasksArray(): UntypedFormArray {
    return this.tasksForm.controls.tasksArray as UntypedFormArray;
  }
  
  addedRestrictionArray(index): UntypedFormArray {
    return this.restrictionArray.at(index).get('addedRestrictionArray') as UntypedFormArray;
  }

  getRestrictionType(index):UntypedFormControl {
      return this.restrictionArray.at(index).get('restriction') as UntypedFormControl;
  }

  addEmptyRestrictions() {
    this.restrictionArray.push(
      new UntypedFormGroup({
        restriction: new UntypedFormControl(''),
        addedRestrictionArray: new UntypedFormArray([])
      })
    );
  }
  addEmptyaddedRestriction(index,restrictSection) {
    let restrictionType = this.getRestrictionType(index).value;
    if ( (index ===  (<UntypedFormArray>this.restrictionForm.get('restrictionArray')).controls.length-1 ) && (this.restrictionList[this.restrictionList.findIndex(obj => obj.name === restrictionType)].type === 'departure_locations' || 
        this.restrictionList[this.restrictionList.findIndex(obj => obj.name === restrictionType)].type === 'destination_locations' || this.restrictionList[this.restrictionList.findIndex(obj => obj.name === restrictionType)].type === 'simple_rules'
    )) {
         if(this.restrictionList[this.restrictionList.findIndex(obj => obj.name === restrictionType)].type === 'simple_rules') {
            this.addedRestrictionArray(index).clear();
            this.addedRestrictionArray(index).push(
              new UntypedFormGroup({
                values: new UntypedFormArray([new UntypedFormGroup({
                  operator: new UntypedFormControl('not equals'),
                  value: new UntypedFormControl(0),
                  })])
              })
            );
          } else {
            this.addedRestrictionArray(index).clear();
            this.addedRestrictionArray(index).push(
              new UntypedFormControl('')
            );
          }
    }
  }

  checkDescriptionAvailability(restrictSectionIndex) {
    if (this.getRestrictionType(restrictSectionIndex).value !== "" && this.allRestrictionList[this.allRestrictionList.findIndex(obj => obj.name === this.getRestrictionType(restrictSectionIndex).value)].type === "complex_rules") {
      return true;
    }
    return false;
  }

  checkCountrySelectAvailability(restrictSectionIndex) {
    if (this.getRestrictionType(restrictSectionIndex).value !== "" && this.allRestrictionList[this.allRestrictionList.findIndex(obj => obj.name === this.getRestrictionType(restrictSectionIndex).value)].type === "departure_locations"
        || this.getRestrictionType(restrictSectionIndex).value !== "" && this.allRestrictionList[this.allRestrictionList.findIndex(obj => obj.name === this.getRestrictionType(restrictSectionIndex).value)].type === "destination_locations") {
      return true;
    }
    return false;
  }

  checkOperatorSelectAvailability(restrictSectionIndex) {
    if (this.getRestrictionType(restrictSectionIndex).value !== "" && this.allRestrictionList[this.allRestrictionList.findIndex(obj => obj.name === this.getRestrictionType(restrictSectionIndex).value)].type === "simple_rules") {
      return true;
    }
    return false;
  }

  checkCashOutRestrictionAvailability(restrictSectionIndex) {
    if (this.getRestrictionType(restrictSectionIndex).value !== "" && this.allRestrictionList[this.allRestrictionList.findIndex(obj => obj.name === this.getRestrictionType(restrictSectionIndex).value)].type === "triggered_access") {
      return true;
    }
    return false;
  }
  getDescription(restrictSectionIndex) {
    return this.allRestrictionList[this.allRestrictionList.findIndex(obj => obj.name === this.getRestrictionType(restrictSectionIndex).value)].description
  }
  
  associateCountry(index, restrictItem, restrictSection) {
    this.countryListForEachRestrictionType[this.countryListForEachRestrictionType.findIndex(ele => ele.name === restrictSection.get('restriction').value)].countryList;
    if (restrictItem.value !== '' && (restrictSection.get('addedRestrictionArray').value.findIndex(ele=>ele===restrictItem.value) !== -1)) {
      this.countryListForEachRestrictionType[this.countryListForEachRestrictionType.findIndex(ele => ele.name === restrictSection.get('restriction').value)].countryList = this.countryListForEachRestrictionType[this.countryListForEachRestrictionType.findIndex(ele => ele.name === restrictSection.get('restriction').value)].countryList.filter(ele => ele.countryName !== restrictItem.value);
      this.addedRestrictionArray(index).push(
        new UntypedFormControl('')
      );
    }
  }

  getCountryListWRTType(restrictSection) {
    this.countryListForEachRestrictionType[this.countryListForEachRestrictionType.findIndex(ele => ele.name === restrictSection.get('restriction').value)].countryList.sort(function (a, b) {
      return a.countryName.localeCompare(b.countryName);
    });
    return this.countryListForEachRestrictionType[this.countryListForEachRestrictionType.findIndex(ele => ele.name === restrictSection.get('restriction').value)].countryList; 
  }
  
  addRestrictionFormField(index) {

  }
  initRuleSection() {
    return new UntypedFormGroup({
      rule: new UntypedFormControl(''),
      addedRule: new UntypedFormArray([
        this.initBenefitSection()
        ])
    });
  }

  initBenefitSection() {
    return new UntypedFormGroup({
      ind: new UntypedFormControl('', Validators.required)
    });
  }

  getaddedRule(form) {
    return form.controls.addedRule.controls;

  }
  getRuleSections(form) {
    return form.controls.ruleArray.controls;
  }

  addBenefit(e) {
    e.value.reference !== '' ? this.disableSave = false : null;
  }
  cashoutMilestone(e) {
  }
  save() {
    const prerequisites = [];
    const exclusions = [];
    const inclusions = [];
    this.ruleForm.get('ruleArray').value.forEach((item) => {
      if (item.rule === 'Enable Linked Benefit') {
        item.addedRule.forEach((benefit) => {
          benefit.ind.reference ? prerequisites.push(benefit.ind.reference) : null;
        });
        this.data.prerequisites = prerequisites;
      } else if (this.ruleForm.get('ruleArray').value.length === 1 && 
          (!this.data.prerequisites || (this.data.prerequisites && this.data.prerequisites.length === 0))) {
        this.data.prerequisites = [];
      }
      if (item.rule === 'Disable Linked Benefit') {
        item.addedRule.forEach((benefit) => {
          benefit.ind.reference ? exclusions.push(benefit.ind.reference) : null;
        });
        this.data.benefit.exclusions = exclusions;
        this.data.benefit.excludeRule = [];
        this.data.benefit.exclusions.forEach(exclusion => {
          const index = this.originalBenefitList.findIndex( itemBenefit => itemBenefit.reference === exclusion);
          this.data.benefit.excludeRule.push(this.originalBenefitList[index].displayName);
        });
        
      } else if (this.ruleForm.get('ruleArray').value.length === 1 && 
          (!this.data.benefit.exclusions || (this.data.benefit.exclusions && this.data.benefit.exclusions.length === 0))) {
        this.data.benefit.exclusions = [];
      }
      /**BB-5412 inclusion and new rule - Select Linked Benefit Starts**/
      if (item.rule === 'Select Linked Benefit') {
        item.addedRule.forEach((benefit) => {
          benefit.ind.reference ? inclusions.push(benefit.ind.reference) : null;
        });
        this.data.benefit.inclusions = inclusions;
        this.data.benefit.includeRule = [];
        if(this.data && this.data.benefit && this.data.benefit.inclusions.length){
          this.data.benefit.inclusions.forEach(inclusion => {
            const index = this.originalBenefitList.findIndex( itemBenefit => itemBenefit.reference === inclusion);
            this.data.benefit.includeRule.push(this.originalBenefitList[index].displayName);
          });        
        }
      }else if (this.ruleForm.get('ruleArray').value.length === 1 && 
        (!this.data.benefit.inclusions || (this.data.benefit.inclusions && this.data.benefit.inclusions.length === 0))) {
        this.data.benefit.inclusions = [];
      }
      /**BB-5412 inclusion and new rule - Select Linked Benefit Ends**/
    });
    let sanitizedRestrictionForm = this.restrictionForm.value.restrictionArray.filter(ele => ele.restriction !== '');
    sanitizedRestrictionForm = sanitizedRestrictionForm.map(ele => {
      if (!(this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'departure_locations' ||
        this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'destination_locations' ||
        this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'simple_rules'
      )) {
        return { restriction: ele.restriction }
      }
      return ele;
    });
    sanitizedRestrictionForm.forEach(ele => {
      if (ele.addedRestrictionArray) {
        ele.addedRestrictionArray = ele.addedRestrictionArray.filter(curr => curr !== '');
      }
    })
    sanitizedRestrictionForm.forEach(ele => {
      if (this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'departure_locations') {
        if (this.data.benefit.scopes.departure_locations) {
          ele.restriction === 'Departure Location Includes…' ? this.data.benefit.scopes.departure_locations.includes = [...ele.addedRestrictionArray] : null;
          ele.restriction === 'Departure Location Excludes…' ? this.data.benefit.scopes.departure_locations.excludes = [...ele.addedRestrictionArray] : null;
        } else if (ele.restriction === 'Departure Location Includes…' || ele.restriction === 'Departure Location Excludes…' ) {
          this.data.benefit.scopes['departure_locations'] = { includes: [], excludes: [] };
          ele.restriction === 'Departure Location Includes…' ? this.data.benefit.scopes.departure_locations.includes = [...ele.addedRestrictionArray] : null;
          ele.restriction === 'Departure Location Excludes…' ? this.data.benefit.scopes.departure_locations.excludes = [...ele.addedRestrictionArray] : null;
        }
      }
      if (this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'destination_locations') {
        if (this.data.benefit.scopes.destination_locations) {
          ele.restriction === 'Destination Location Includes…' ? this.data.benefit.scopes.destination_locations.includes = [...ele.addedRestrictionArray] : null;
          ele.restriction === 'Destination Location Excludes…' ? this.data.benefit.scopes.destination_locations.excludes = [...ele.addedRestrictionArray] : null;
        } else if (ele.restriction === 'Destination Location Includes…' || ele.restriction === 'Destination Location Excludes…' ) {
          this.data.benefit.scopes['destination_locations'] = { includes: [], excludes: [] };
          ele.restriction === 'Destination Location Includes…' ? this.data.benefit.scopes.destination_locations.includes = [...ele.addedRestrictionArray] : null;
          ele.restriction === 'Destination Location Excludes…' ? this.data.benefit.scopes.destination_locations.excludes = [...ele.addedRestrictionArray] : null;
        }
      }
      if (this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'complex_rules') {
        if (this.data.benefit.scopes.complex_rules) {
          if (this.data.benefit.scopes.complex_rules.findIndex(curr => curr.name === ele.restriction) === -1) {
            this.data.benefit.scopes.complex_rules.push({ name: ele.restriction, description: this.allRestrictionList.find(curr => curr.name === ele.restriction).description });
          }
        } else {
          this.data.benefit.scopes['complex_rules'] = [];
          this.data.benefit.scopes.complex_rules.push(this.data.benefit.scopes.complex_rules.push({ name: ele.restriction, description: this.allRestrictionList.find(curr => curr.name === ele.restriction).description }))
        }
      }
      if (this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'transferee_related') {
        if (this.data.benefit.scopes.spouse_partner) {
          ele.restriction === 'Transferee with Spouse' ? this.data.benefit.scopes.spouse_partner.spousePartnerRelocating = true : null;
        } else {
          this.data.benefit.scopes['spouse_partner'] = { spousePartnerRelocating: false }
          ele.restriction === 'Transferee with Spouse' ? this.data.benefit.scopes.spouse_partner.spousePartnerRelocating = true : null;
        }
      }
      if (this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'triggered_access') {
        if (this.data.benefit.scopes.triggered_access &&
          this.data.benefit.scopes.triggered_access.percentage &&
          this.data.benefit.scopes.triggered_access.noOfDays &&
          this.data.benefit.scopes.triggered_access.milestone) {
          ele.restriction === 'Triggered Access' ? this.data.benefit.scopes.triggered_access = {
            percentage: this.data.benefit.scopes.triggered_access.percentage,
            noOfDays: this.data.benefit.scopes.triggered_access.noOfDays,
            milestone: this.data.benefit.scopes.triggered_access.milestone
          } : null;
        } else {
          this.data.benefit.scopes['triggered_access'] = {}
          ele.restriction === 'Triggered Access' ? this.data.benefit.scopes.triggered_access = {
            percentage: this.cashOutMMUserRestrictionForm.value.percentAllowed,
            noOfDays: this.cashOutMMUserRestrictionForm.value.days,
            milestone: this.cashOutMMUserRestrictionForm.value.milestone
          } : null;
        }
      }
      if (this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'simple_rules') {
        let simple_ruleObject: SimpleRule = {
          type: ele.restriction,
          _scopingCategory:"rule",
          scopingType: "rule",
          fact: ele.restriction === 'Number of Relocating Children' ? "numberOfRelocatingChildren" : "",
          uiType: "operator",
          values: ele.addedRestrictionArray.length ? ele.addedRestrictionArray[0].values : [{operator: null, value: null}]
        }
        if (this.data.benefit.scopes.simple_rules) {
          let index = this.data.benefit.scopes.simple_rules.findIndex(curr => curr.type === ele.restriction);
          if (index === -1) {
            this.data.benefit.scopes.simple_rules.push(simple_ruleObject);
          } else {
            this.data.benefit.scopes.simple_rules[index].values = ele.addedRestrictionArray.length ? ele.addedRestrictionArray[0].values : [{operator: null, value: null}]
          }
        } else {
          this.data.benefit.scopes['simple_rules'] = [];
          this.data.benefit.scopes.simple_rules.push(simple_ruleObject);
        }
      }
    });
    if (this.deletedComplexRestrictions.length > 0) {
      this.deletedComplexRestrictions.forEach(ele => this.data.benefit.scopes.complex_rules.push(ele));
    }
    this.data.benefit.tasks = [];
    this.tasksForm.get('tasksArray').value.forEach((element: any) => {
      this.data.benefit.tasks.push(element);
    });
    this.dialogRef.close(this.data);
  }

  /** Tasks - add new Task */
  addNewTask() {
    let task = '';
    (this.tasksForm.get('tasksArray') as UntypedFormArray).push(new UntypedFormControl(task, Validators.required));
  }
  /** Tasks - remove Task */
  removeTask(index) {
    (this.tasksForm.get('tasksArray') as UntypedFormArray).removeAt(index);
  }
  /** Tasks - clear Tasks */
  clearTasks() {
    (this.tasksForm.get('tasksArray') as UntypedFormArray).clear();
  }

  /** Get operator values */
  getOperatorValues(){
    if(!this.operatorsList.length){
      this.spinner.show();
      this.prodOfferingsService.getvalueLists('ScopingNumberOperators').subscribe((response)=>{
        this.spinner.hide();
        if(response){
    this.operatorsList = response[0]['values'];
        }
      },(error)=>{
        this.spinner.hide();
      })
    }
    
  }

}
