<div fxLayout="row" >
    <div fxFlex="95">
        <span fxLayout="row" class="resction_info_div_title">{{restriction.type}}</span>
    </div>
    <div fxFlex="5">
        <div fxLayoutAlign="end start">
            <mat-icon  class="offering_delete_icon" *ngIf="!disableEditOffering" (click)="removeRestriction(restriction.type)">remove</mat-icon>
        </div>
    </div>
</div>
<div fxLayout="row wrap" *ngIf="parentForm" [formGroup]="parentForm" >
    <ng-container formArrayName="values">
        <div fxFlex="100" class="resction_details_div" fxLayoutGap="1.25em" *ngFor="let item of parentForm?.get('values')['controls']; let i = index; let fisrtItem = first; let lastItem = last;" >
            <div fxFlex="25">
                <mat-form-field appearance="outline" class="" #appField>
                    <mat-label *ngIf="appField._control.focused || parentForm.get('values').get(''+i).value">Application</mat-label>
                    <mat-label *ngIf="!appField._control.focused && !parentForm.get('values').get(''+i).value">Select Application</mat-label>
                    <mat-select disableOptionCentering="true"   [formControlName]="i" (openedChange)="matFocusedEvent($event,i,'application')" (ngModelChange)="valueChangeEvent()">
                    <mat-option *ngFor="let application of applicationsList" [value]="application.value" [disabled]="isSelcetedApplication(application.value ,i)">{{application.displayValue}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix *ngIf="!matFocused[i] || !matFocused[i].application">keyboard_arrow_down</mat-icon>
                    <mat-icon matSuffix *ngIf="matFocused[i] && matFocused[i].application">keyboard_arrow_up </mat-icon>
                </mat-form-field>
                <mat-error *ngIf="isAppNotSelected && lastItem">*Application should not be empty</mat-error>
            </div>
            <div fxFlex="25" *ngIf="parentForm?.get('values').value && parentForm?.get('values').value[i] && parentForm?.get('values').value[i].toLowerCase().replace(' ', '') === 'benefitsbuilder' && offeringType !== 'Variable'">
                <mat-form-field appearance="outline" class="" >
                    <mat-label>Points</mat-label>
                    <mat-select disableOptionCentering="true"  [(ngModel)]="points" [ngModelOptions]="{standalone: true}" [disabled]="disablePoints || this.parentForm.disabled" (ngModelChange)="pointsValChanged($evnt,i,'points')" >
                    <mat-option *ngFor="let type of pointsList" [value]="type.value" >{{type.displayValue}}</mat-option>
                    </mat-select>
                    <mat-icon matSuffix *ngIf="!matFocused[i] || !matFocused[i].points">keyboard_arrow_down</mat-icon>
                    <mat-icon matSuffix *ngIf="matFocused[i] && matFocused[i].points">keyboard_arrow_up </mat-icon>
                </mat-form-field>
            </div>
            <div fxFlex="20" >
                <div fxFlex="25" *ngIf="parentForm?.get('values') && parentForm?.get('values').value && parentForm?.get('values').value.length > 1 && !disableEditOffering">
                    <div  class="row_delete_icon" fxLayoutAlign="center center">
                        <img src="../assets/icons/arrow_delete.svg" (click)="removeApplication(i)"  alt=""/>
                    </div>
                </div>
                <div fxFlex="25" *ngIf="isAllowedToAddNewApplication()">
                    <div *ngIf="lastItem && !disableEditOffering" class="row_delete_icon" fxLayoutAlign="center center">
                        <mat-icon (click)="addApplication()" >add</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
