import { Component, OnInit, HostBinding, ViewEncapsulation } from '@angular/core';
import { AppConfigService } from '../../../services/app-config.service';
import * as constants from '../../../models/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  /**property to print copyright string */
  readonly copyright = constants.copyright;
  termsOfUseUrl: string
  privacyNotice: string
  contactUs: string
  @HostBinding('class') class = 'app-footer';

  constructor(private readonly appConfig: AppConfigService) { }

  ngOnInit() {
    this.termsOfUseUrl = this.appConfig.getConfig('login') + '#/termsOfUse';
    this.privacyNotice = this.appConfig.getConfig('login') + '#/privacyNotice';
    this.contactUs = this.appConfig.getConfig('login') + '#/contact';
  }

}
