import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-menu-list',
  templateUrl: './product-menu-list.component.html',
  styleUrls: ['./product-menu-list.component.scss'],
})
export class ProdMenuListComponent implements OnInit {

  activeButton:String = 'template';

  constructor(private route: ActivatedRoute,
    private router: Router ){

  }

  ngOnInit(){ 
      console.log("menu-list=loads");
      console.log(this.route.snapshot['_routerState'].url);

      this.activeButton = (!this.route.snapshot['_routerState'] || this.route.snapshot['_routerState'].url == '/prod-menu-list/product-templates' ) ? 'template' : 'offering';
  }

  onSlection (page) {
    console.log(this.route);
    if( page == 'templates')
      this.router.navigate(['/prod-menu-list/product-templates']);
    else
      this.router.navigate(['/prod-menu-list/product-offerings']);
  }

}