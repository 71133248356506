import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, flatMap } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';

export interface FeatureFlag{
	/** feature flag key */
	key: string;
	/** kind of response */
	kind: boolean;
	/** to indicate if feature flag is enabled */
	on: true | false;
	/**environment name */
	_environmentName: string;
}

export interface FeatureFlags{
	items: Array<FeatureFlag>
}

/** FeatureFlagService to fetch feature flags from Api */
@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

/** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(
    private readonly baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService
  ) { 
    this.setLumpSumFeatureFlag()
  }

  teamsFeatureFlag$ = new BehaviorSubject<string>(null);
  isLumpSumEnabled = new BehaviorSubject<boolean>(false);

  getFeatureFlagsBBUrl(): Observable<FeatureFlags> {
    let httpParam = new HttpParams();
    httpParam = httpParam.append('project_key', 'product-admin')
    return this.baseClient
    .getById<FeatureFlags>(`v1/featureFlag?`+ httpParam.toString(), 'get the feature flag', 'apiBBUrl')
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          console.log( 'Failed to get feature flag details', err );
          const empty: FeatureFlags = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }

  setLumpSumFeatureFlag() {
       let featureFlagData:FeatureFlag[]  = []
        this.getFeatureFlagsBBUrl().subscribe(res => {
          featureFlagData = res?.items;
          const lumpSumFlagDetails =  featureFlagData && featureFlagData.find( ele => ele.key === 'lump-sum-product' )?.on;
          this.isLumpSumEnabled.next(lumpSumFlagDetails);  
        });    
  }
}
