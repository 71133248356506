import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ProdOfferingsService } from 'src/app/core/services/product-offerings.service';

import { RestrictionBaseComponent } from '../../common/restriction-base.component';

@Component({
  selector: 'app-location-uitype-restriction',
  templateUrl: './location-uitype-restriction.component.html',
  styleUrls: ['./location-uitype-restriction.component.scss']
})
export class LocationUitypeRestrictionComponent extends RestrictionBaseComponent implements OnInit {
  timeOut:any;
  lastFocusedCountryName: any;
  noCountriesFound:boolean = false;

  constructor(public prodOfferingsService : ProdOfferingsService) { 
    super(prodOfferingsService);
  }

  ngOnInit(): void {
    console.log(this.parentForm)
    this.updateValidationRules();
  }

  getCountrtyDetails (event,Index) {
    clearTimeout(this.timeOut);
    this.availableCities = [];
    if(event.currentTarget.value && event.currentTarget.value.length >2){
      let searchText = event.currentTarget.value;
      this.timeOut = setTimeout(() => {
        this.doContrySearch(searchText,Index);
      }, 1000);
    }
  }

  searchCities (event,index) {
    clearTimeout(this.timeOut);
    if(event.currentTarget.value && event.currentTarget.value.length >2){
      let searchText = event.currentTarget.value;
      this.timeOut = setTimeout(() => {
        this.doCitySearch(index,searchText);
      }, 1000);
    }
  }

  doContrySearch(searchText,Index){
    this.noCountriesFound = false;
    this.availableCountries = [];
    this.prodOfferingsService.showSpinner();
    this.prodOfferingsService.getCoutryLists(searchText).subscribe({
      next: (response) => {
        console.log(response);
        this.prodOfferingsService.hideSpinner();
        if (response) {
          this.availableCountries = response;
          this.isCountryAlredySelected(Index);
        } else {
          this.noCountriesFound = true;
          this.parentForm.get('values').get('' + Index).get('value').setValue('');
        }
      },
      error: (error) => {
        this.noCountriesFound = true;
        this.parentForm.get('values').get('' + Index).get('value').setValue('');
        this.prodOfferingsService.hideSpinner();
      }
    });
  }

  doCitySearch (index,searchText) {
    this.availableCities = [];
    let formValues = this.parentForm.get('values').value;
    let countyCode = formValues && formValues[index] && formValues[index].value ? formValues[index].value.replace('/','').toLowerCase() : null; 
    if(countyCode) {
      this.prodOfferingsService.showSpinner();
      this.prodOfferingsService.getCityLists(searchText, countyCode).subscribe({
        next: (response) => {
          console.log(response);
          this.prodOfferingsService.hideSpinner();
          if (response) {
            this.availableCities = response;
            this.isCityAlreadySelcted(index);
          }
        },
        error: (error) => {
          this.prodOfferingsService.hideSpinner();
        }
      });
    }
  }

  locationSelected ($event,index) {
    console.log($event)
    let values = this.parentForm.get('values').value;
    if(values[index] && $event.option.value){
      let obj = {
        country : $event.option.value.name,
        value : "/"+ $event.option.value.iso2,
        cityStPr:[{
          city:null,
          state:null,
          value:null
        }]
      }
      this.lastFocusedCountryName = $event.option.value.name;
      this.parentForm.get('values').get(''+index).patchValue(obj);
      this.isCountryAlredySelected();
    }
  }

  isCountryAlredySelected(currentIndex?){    
    let values = this.parentForm.get('values').value;
    if(this.availableCountries && this.availableCountries.length){
      this.availableCountries.forEach((item)=> {
        item.isDisabled = false;
        values.forEach((element,index) => {
          let code = "/"+ item.iso2;
          if(code == element.value){
            item.isDisabled = true;
          }
        });
      })
    }
  }

  isCityAlreadySelcted(currentIndex){
    let values  = this.parentForm.get('values').value;
    if(currentIndex > -1 && this.availableCities && this.availableCities.length){
      this.availableCities.forEach((item)=> {
        item.isDisabled = false;
        values[currentIndex].cityStPr.forEach((element) => {
          if(item.value == element.value){
            item.isDisabled = true;
          }
        });
      })
    }
  }

  countryFocus(index){
    this.isCountryAlredySelected();
  }

  cityFocus(index) {
    this.isCityAlreadySelcted(index);
  }

  countryFocusOut(index) {
    setTimeout(() => {
      if(this.parentForm.get('values').get(''+index).get('country').value && !this.parentForm.get('values').get(''+index).get('value').value){
        this.parentForm.get('values').get(''+index).get('value').setValue('');
        this.parentForm.get('values').get(''+index).get('country').setValue('');
        (this.parentForm.get('values').get(''+index).get('cityStPr') as UntypedFormArray).clear();
      }
    }, 100);
  }

  clearInputValue (id){
    document.getElementById(id)['value'] = null;
  }

  locationOnChange(index){
    this.parentForm.get('values').get(''+index).get('value').setValue('');
    (this.parentForm.get('values').get(''+index).get('cityStPr') as UntypedFormArray).clear();
  }


  citySelected(event,index, id): void {
    (this.parentForm.get('values').get(''+index).get('cityStPr') as UntypedFormArray).push(this.addCityControl(event.option.value));
    document.getElementById(id)['value'] = null;
    this.isCityAlreadySelcted(index);
  }

  removeSelectedCity (city,parentIndex,cityIndex) {
    (this.parentForm.get('values').get(''+parentIndex).get('cityStPr') as UntypedFormArray).removeAt(cityIndex);
  }

  addNewLocation () {
    let deafaultLocationDetailsControl = new UntypedFormGroup ({
      "value": new UntypedFormControl('',Validators.required),
      "country": new UntypedFormControl('',Validators.required),
      "cityStPr":new UntypedFormArray([])
    });
    (this.parentForm.get('values') as UntypedFormArray).push(deafaultLocationDetailsControl);
    this.availableCities = [];
  }

  removeLocation(index){
    (this.parentForm.get('values') as UntypedFormArray).removeAt(index);
  }

  addCityControl (value?) {
    let formControl = new UntypedFormGroup({
      city: new UntypedFormControl(''),
      state:new UntypedFormControl(''),
      value:new UntypedFormControl('')
    })
    if(value){
      formControl.patchValue(value);
    }
    return formControl;
  }

  //Clearing the validation rules of city and state as state is not available for other than US.
  updateValidationRules() {
    // eslint-disable-next-line max-len
    if(this.parentForm && this.parentForm.get('values') && this.parentForm.get('values') && this.parentForm.get('values') && this.parentForm.get('values')['controls'] && this.parentForm.get('values')['controls'].length ){
      this.parentForm.get('values')['controls'].forEach((element,index) => {
        // eslint-disable-next-line max-len
        if(this.parentForm.get('values').get(''+index).get('cityStPr') && this.parentForm.get('values').get(''+index).get('cityStPr')['controls'] && this.parentForm.get('values').get(''+index).get('cityStPr')['controls'].length){
          Object.keys(this.parentForm.get('values').get(''+index).get('cityStPr')['controls']).forEach((key)=>{
            this.parentForm.get('values').get(''+index).get('cityStPr').get(''+key).get('city').clearValidators();
            this.parentForm.get('values').get(''+index).get('cityStPr').get(''+key).get('state').clearValidators();
          })
        } else {
          ((this.parentForm.get('values').get(''+index) as UntypedFormGroup).addControl('cityStPr', new UntypedFormArray([])));
          (this.parentForm.get('values').get(''+index) as UntypedFormGroup).get('cityStPr').clearValidators()
        }
      });
    }
  }

}
