<div fxLayout="row" class="content-header-desktop">
  <div fxFlex="100">
    <mat-toolbar>
      <span class="logo-span"><img class="header-logo-img" src="../assets/images/cartus_logo.png" alt="" /></span>
      <span class="toolbar-spacer"></span>
      <mat-icon class="header-help-icon">help</mat-icon>
      <div class="header-user-short-name">
        <span>{{userDetails.userName[0][0]}}{{userDetails.userName[userDetails.userName.length-1][0]}}</span>
      </div>
      <div class="header-user-name" [matMenuTriggerFor]="settingMenu">
        <p class="user-name-info">{{userDetails.userName[0]}}</p>
        <span class="user-info-icon expandMore"><mat-icon>keyboard_arrow_down</mat-icon></span>
        <span class="user-info-icon expandLess"><mat-icon>keyboard_arrow_up</mat-icon></span>
      </div>
    </mat-toolbar>
  </div>
  <mat-menu class="settingMenu" #settingMenu="matMenu">
        <!-- <button mat-menu-item (click)="settings()"><mat-icon>settings</mat-icon> Settings</button> -->
        <!-- <button mat-menu-item *ngIf="isEnableContext" (click)="changeContext()"><mat-icon>people</mat-icon>Select Account</button> -->
        <button mat-menu-item (click)="logout()"><mat-icon>exit_to_app</mat-icon> Log Out</button>
</mat-menu>
</div>
<div class="main-content" *ngIf="loadChildren">
  <router-outlet ></router-outlet>
</div>
<!-- <div fxLayout="row" class="footer-row">
  <div fxFlex="64%">
      <span class="footer-cartus-span">©2021 Cartus Corporation</span>
  </div>
  <div fxFlex="12%" fxLayoutAlign="center">
      <span>Terms of Use</span>
  </div>
  <div fxFlex="12%" class="privacy-st-span" fxLayoutAlign="center">
      <span >Privacy of Statment</span>
  </div>
  <div fxFlex="12%" fxLayoutAlign="center">
      <span>Contact Us</span>
  </div >
</div> -->
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "default" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true">
  <p style="color: white" >
   Please wait... </p>
</ngx-spinner>
