<div mat-dialog-title className="" fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="10px" fxLayoutGap.xs="0em">
    <span class="title-wrapper">
        <img class="warning-icon" src="../assets/icons/caution-sign.svg" alt="caution-sign">
        <span class="title-text">Attention</span>
    </span>
    <span class="close-icon" (click)="onClose()" title="close">
        <mat-icon>close</mat-icon>
    </span>
</div>
<hr>

<div class="modal-content" fxLayoutAlign="space-between none" fxLayout="row wrap" fxLayoutGap="32px">
    <p>
        There are Active Programs associated with this template.
        <br>
        Would you like to view or update these programs?
    </p>
</div>

<div fxLayoutAlign="end end" class="mt-10">
    <button class="cancel-btn mr-5" mat-button (click)="onCancel()">Not Now</button>
    <button class="view-btn" (click)="view()" mat-button>View</button>
</div>