<div class="dialog-container" role="region">
  <!-- Title of pop-up -->
  <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px"
    fxLayoutGap.xs="0em" role="heading">
    <h2 class="divisions-title">Edit Benefit Logic</h2>
    <a class="close-icon" (click)="close()" title="close" role="button" aria-label="Close Program Modal"
      title="Close Program Modal">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <!-- Title of pop-up end here -->

  <div class="pop-up-conent">
    <!--dialog-title start  -->
    <div class="dialog-title-container">
      <div class="dialog-wrapper">
        <div class="dialog-title-card">
          <mat-card class="mat-elevation-z8">{{data.benefit.displayName}}</mat-card>
        </div>
        <div class="dialog-title-desc">
          <p>{{data.benefit.description}}</p>
        </div>
      </div>
    </div>
    <!--dialog-title end here  -->

    <!--Restrict box start  temporary comment -->
    <div class="restrict-card">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Restrict this benefit to...</mat-card-title>
        </mat-card-header>
        <form class="middle-section" [formGroup]="restrictionForm">
          <div formArrayName="restrictionArray">
            <div *ngFor="let restrictSection of restrictionArray?.controls let restrictSectionIndex = index"
              [formGroupName]="restrictSectionIndex">
              <mat-card-content style="background:#f8f8f8; ">
                <!-- first select input start -->
                <div class="middle-section">
                  <div class="division-input">
                    <mat-form-field class="example-form-field">
                      <mat-label class="set-label-rest">Restrictions</mat-label>
                      <mat-select
                        *ngIf="restrictionArray?.controls.length-1 === restrictSectionIndex && !restrictDisableFlag"
                        formControlName="restriction"
                        [disabled]="restrictionArray?.controls.length-1 !== restrictSectionIndex"
                        (selectionChange)='addEmptyaddedRestriction(restrictSectionIndex,restrictSection,restriction)'>
                        <mat-option [value]="division.name" *ngFor="let division of restrictionList"
                          style="font-size: 14px;">
                          {{ division.name}}
                        </mat-option>
                      </mat-select>
                      <input matInput
                        *ngIf="restrictionArray?.controls.length-1 !== restrictSectionIndex || restrictDisableFlag"
                        [value]="restrictSection.get('restriction').value" type="text" [readonly]="true">
                    </mat-form-field>
                    <button class="minus restrict-minus"
                      (click)="clearRestrictions(restrictSectionIndex,restrictSection)"></button>
                  </div>
                  <div *ngIf="checkDescriptionAvailability(restrictSectionIndex)" class="info-section" fxLayout="row"
                    fxLayoutAlign="center center">
                    <img class="info-con" alt="info" src="../../../../../../../assets/images/info-24px .svg" />
                    <div class="info-desc">{{getDescription(restrictSectionIndex)}}</div>
                  </div>
                </div>
                <!-- first select input end -->

                <!-- preciding select input start -->
                <div *ngIf="checkCountrySelectAvailability(restrictSectionIndex)" formArrayName="addedRestrictionArray"
                  class="division-input">
                  <!--restrictionArray.controls[restrictSectionIndex].addedRestrictionArray.controls-->
                  <div class="dynamic-input form-group" fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row"
                    fxFlex.xs="100%" role="none"
                    *ngFor="let restrictItem of addedRestrictionArray(restrictSectionIndex).controls; index as ind; let k = index">
                    <mat-form-field class="example-form-field">
                      <mat-label>{{getRestrictionType(restrictSectionIndex).value.includes('Departure')?'Departure
                        country':'Destination country'}}</mat-label>
                      <mat-select formControlName="{{ind}}"
                        [disabled]="k <=restrictSection.controls['addedRestrictionArray'].controls.length-2"
                        *ngIf="k <= restrictSection.controls['addedRestrictionArray'].controls.length-2">
                        <mat-option [value]="country.countryName"
                          *ngFor="let country of allCountryList let i=index; let l=last" style="font-size: 14px;">
                          {{ country.countryName}}
                        </mat-option>
                      </mat-select>
                      <mat-select formControlName="{{ind}}"
                        [disabled]="k <= restrictSection.controls['addedRestrictionArray'].controls.length-2"
                        *ngIf="k > restrictSection.controls['addedRestrictionArray'].controls.length-2">
                        <mat-option [value]="country?.countryName"
                          *ngFor="let country of getCountryListWRTType(restrictSection) let i=index; let l=last"
                          style="font-size: 14px;">
                          {{ country.countryName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button class="minus"
                      *ngIf="!(ind === 0 && restrictSection.controls['addedRestrictionArray'].controls.length === 1 && restrictSection.get('addedRestrictionArray').controls[0].value == '')"
                      (click)="pushBackToListCountry(ind,restrictItem,restrictSectionIndex,restrictSection)"></button>
                    <button class="plus"
                      *ngIf="ind === restrictSection.controls['addedRestrictionArray'].controls.length - 1"
                      [disabled]="false"
                      (click)="associateCountry(restrictSectionIndex,restrictItem,restrictSection)"></button>
                    <br />
                  </div>
                </div>
                <!-- preciding select input end -->
                <!-- MMClient program cashout restriction matcard starts-->
                <form [formGroup]="cashOutMMUserRestrictionForm">
                <mat-card *ngIf="isMMUser && checkCashOutRestrictionAvailability(restrictSectionIndex)"
                  class="cashOutResctrictioncard">
                  <mat-card-content fxFlex="100%" fxLayout="column">
                    <div fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" role="none">
                      <mat-form-field class="cashout-input">
                        <mat-label class="">% Allowed</mat-label>
                        <input matInput formControlName="percentAllowed" type="number" placeholder="0" min="0" max="100">
                      </mat-form-field>
                      <div fxLayout="column" *ngIf="isArrowVisible">
                        <mat-icon class="up-down-arrow" (click)="increasePercentValue()">arrow_drop_up</mat-icon>
                        <mat-icon  class="up-down-arrow" (click)="decreasePercentValue()">arrow_drop_down</mat-icon>
                      </div>
                      <mat-form-field class="cashout-input mrgl10">
                        <mat-label>Days</mat-label>
                        <input matInput type="number" formControlName="days" placeholder="0" min="0">
                      </mat-form-field>
                      <div fxLayout="column" *ngIf="isArrowVisible">
                        <mat-icon class="up-down-arrow" (click)="increaseDaysValue()">arrow_drop_up</mat-icon>
                        <mat-icon  class="up-down-arrow" (click)="decreaseDaysValue()">arrow_drop_down</mat-icon>
                      </div>
                      <div class="cashOut-slide-toggler">
                        <p class="mrglr">Before</p>
                        <mat-slide-toggle [checked]="true" [disabled]="!isArrowVisible">After</mat-slide-toggle>
                      </div>
                    </div>
                    <mat-form-field>
                      <mat-label>Transferee Milestone</mat-label>
                      <mat-select formControlName="milestone" (selectionChange)="cashoutMilestone($event)">
                        <mat-option *ngFor="let milestone of milestoneValues" [value]="milestone">{{milestone}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </mat-card-content>
                </mat-card>
              </form>
                <!-- MMClient program cashout restriction matcard ends-->

                <!-- preciding simple_rules select input start -->
                <div *ngIf="checkOperatorSelectAvailability(restrictSectionIndex)" formArrayName="addedRestrictionArray"
                 class="simple-rules-input">
                 <div class="dynamic-input form-group" fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row"
                   fxFlex.xs="100%" role="none"
                   *ngFor="let restrictItem of addedRestrictionArray(restrictSectionIndex).controls; index as ind; let k = index">
                    <app-operator-uitype-restriction fxFlex="100%"  [restriction]="restrictItem"  [parentForm]="addedRestrictionArray(restrictSectionIndex).controls[k]" [operatorsList]="operatorsList"></app-operator-uitype-restriction>
                 </div>
                </div>
                <!-- preciding simple_rules select input end -->
              </mat-card-content>
            </div>
          </div>
        </form>
      </mat-card>
      <button mat-button class="text-button main-btn" [disabled]="disableRestriction()"
        (click)="addRestrictionSection()">+ Add Restriction</button>
    </div>
    <!--Restrict box end  -->

    <!--Next Restrict box start  -->
    <div class="restrict-card" style="padding-top: 12px;">
      <mat-card>
        <mat-card-header>
          <mat-card-title>If this benefit is selected</mat-card-title>
        </mat-card-header>



        <form class="middle-section" [formGroup]="ruleForm">
          <!-- first select input start -->
          <div formArrayName="ruleArray">
            <div *ngFor="let ruleSection of getRuleSections(ruleForm); let j = index" [formGroupName]="j">
              <mat-card-content style="background:#f8f8f8; ">
                <div class="middle-section">
                  <div class="division-input">
                    <mat-form-field class="example-form-field">
                      <mat-label>Rule</mat-label>
                        <mat-select formControlName="rule" *ngIf="j >= 0" (selectionChange)=addBenefitFormField(j) disableOptionCentering="true" [disabled]="ruleSection.get('rule').value!=''">
                          <mat-option [value]="rule.name" *ngFor="let rule of allRuleList; let i = index" style="font-size: 14px;" [disabled]="rule.isDisable" (click)="checkRule(rule.name)">
                            {{ rule.name}}
                          </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button class="minus restrict-minus" [disabled]="ruleSection.status === 'INVALID'"
                      (click)="clearRestrictions2(j)"></button>
                  </div>
                </div>
                <!-- first select input end -->

                <!-- preciding select input start -->
                <!--<form *ngIf="rule.value" class="middle-section" [formGroup]="ruleForm"> -->
                <div *ngIf="ruleSection.get('rule').value" formArrayName="addedRule" class="division-input">
                  <div [formGroupName]="k" class="dynamic-input form-group" fxFlex="100%" fxLayoutAlign="start"
                    fxLayout.xs="row" fxFlex.xs="100%" role="none"
                    *ngFor="let divisions of getaddedRule(ruleSection); index as ind ; let k = index">
                    <mat-form-field class="example-form-field">
                      <mat-label>Linked Benefit</mat-label>
                      <input matInput [value]="ruleSection.value.addedRule[k].ind.displayName" 
                        type="text" [readonly]="k <= ruleSection.value.addedRule.length-1"
                        *ngIf="k <= ruleSection.value.addedRule.length-1 && ruleSection.value.addedRule[k].ind.displayName">
                      <mat-select formControlName="ind" [compareWith]="compareBenefits"
                        [disabled]="k <= ruleSection.value.addedRule.length-2"
                        *ngIf="ruleSection.value.addedRule[k].ind === ''" (selectionChange)="addBenefit($event)">
                        <mat-option [value]="benefit" *ngFor="let benefit of data.benefitList; let i=index; let l=last"
                          style="font-size: 14px;">
                          {{ benefit.displayName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button class="minus" *ngIf="checkRemoveButtonVisibility2(k, ruleSection)"
                      (click)="pushBackToList(k, j)"></button>
                    <button class="plus" *ngIf="checkAddButtonVisibility2(k, ruleSection)"
                      [disabled]="ruleSection.controls.addedRule.status === 'INVALID'"
                      (click)="associateBenefit(k, j)"></button>
                    <br />
                  </div>
                </div>
              </mat-card-content>
            </div>
          </div>

        </form>
        <!-- preciding select input end -->
      </mat-card>
      <button mat-button class="text-button main-btn" (click)="addRuleSection()"
        [disabled]="ruleForm.status === 'INVALID' || ruleForm.get('ruleArray').value.length === 3">+
        Add Rule</button>
    </div>
    <!--Restrict box end  -->

    <!--Defining tasks start  -->
    <div class="restrict-card" style="padding-top: 12px;">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Define Task(s)</mat-card-title>
        </mat-card-header>

        <form class="middle-section" [formGroup]="tasksForm">
          <div formArrayName="tasksArray">
            <!-- Tasks existing-->
            <ng-container  *ngIf="tasksForm?.get('tasksArray')['controls'].length">
              <div *ngFor="let task of tasksForm?.get('tasksArray')['controls'];let j =index; let lastTask = last; let firstTask = first;">
                <mat-card-content style="background:#f8f8f8; ">
                  <div class="middle-section">
                    <div class="division-input">
                      <mat-form-field class="example-form-field mat-form-field-textarea">
                        <mat-label>Task #{{j+1}}</mat-label>
                        <textarea matInput [formControlName]="j"  class="input_text"></textarea>
                      </mat-form-field>
                      <button class="minus restrict-minus" *ngIf="firstTask" [disabled]="tasksForm.status === 'INVALID'"
                        (click)="clearTasks()"></button>
                      <button class="minus" *ngIf="tasksForm?.get('tasksArray')['controls'] && tasksForm?.get('tasksArray')['controls'].length > 1"
                        (click)="removeTask(j)"></button>
                      <button class="plus" *ngIf="lastTask &&  tasksForm?.get('tasksArray')['controls'].length < 4"
                        (click)="addNewTask()"></button>
                    </div>
                  </div>
                </mat-card-content>
              </div>
            </ng-container>
            <!--No tasks-->
            <ng-container *ngIf="!tasksForm?.get('tasksArray')['controls'].length">
              <div class="division-input">
                  <p class="empty_restrictions_lbl">
                      Task list is empty, click on ‘Add Task’ to define task(s)
                  </p>
              </div>
            </ng-container>
          </div>
        </form>

      </mat-card>
      <button mat-button class="text-button main-btn" [ngClass]="{'empty_restriction_btn' : (tasksForm?.get('tasksArray')['controls'].length)}" (click)="tasksForm?.get('tasksArray')['controls'].length || addNewTask()"
        [disabled]="tasksForm.status === 'INVALID' || tasksForm?.get('tasksArray').value.length === 4">+
        Add Task</button>
    </div>
    <!--Defining tasks end  -->

  </div>
  <!-- pop up action buttons start -->
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
    <button mat-button class="text-button rest-btn" (click)="close()">CANCEL</button>
    <!-- <button mat-button class="contained-button" (click)='save()' [disabled] = "!this.restrictionForm.get('restrictionArray').valid && disableSave">Save</button> -->
    <button mat-button class="contained-button" (click)='save()'
      [disabled]="!this.restrictionForm.get('restrictionArray').valid">Save</button>
  </mat-dialog-actions>
  <!-- pop up action buttons end -->

</div>