<div fxLayout="row" >
    <div fxFlex="95">
        <span fxLayout="row" class="resction_info_div_title">{{restriction.type}}</span>
    </div>
    <div fxFlex="5">
        <div fxLayoutAlign="end start">
            <mat-icon  class="offering_delete_icon" *ngIf="!disableEditOffering" (click)="removeRestriction(parentForm?.get('type').value)">remove</mat-icon>
        </div>
    </div>
</div>
<div fxLayout="row wrap" class="" *ngIf="parentForm" [formGroup]="parentForm">
    <ng-container formArrayName="values">
        <div fxFlex="100" class="resction_details_div" fxLayoutGap="1.25em"  *ngFor="let item of parentForm?.get('values')['controls']; let i = index; let fisrtItem = first; let lastItem = last;" [formGroupName]="i">
            <div fxFlex="40" >
                <mat-form-field appearance="outline" class="">
                    <mat-label>Restriction Criteria</mat-label>
                    <!-- <mat-select disableOptionCentering="true" (openedChange)="matFocusedEvent($event,i,'complex')" [(ngModel)]="restriction.values[i].displayName" (ngModelChange)="lastItem && lastCriteriaNotFound = false;" [disabled]="disableEditOffering">
                    <mat-option *ngFor="let item of complexRulesList" [disabled]="disableResOption(item)" (click)="disableResOption(item) || selectedComplexRes(item,i,'complex')" [value]="item.name">{{item.name}}</mat-option>
                    </mat-select> -->
                    <mat-select disableOptionCentering="true" formControlName="displayName">
                        <mat-option *ngFor="let item of complexRulesList" [disabled]="disableResOption(item)" (click)="disableResOption(item) || selectedComplexRes(item,i,'complex')" [value]="item.name">{{item.name}}</mat-option>
                        </mat-select>
                    <mat-icon matSuffix *ngIf="!matFocused[i] || !matFocused[i].complex">keyboard_arrow_down</mat-icon>
                        <mat-icon matSuffix *ngIf="matFocused[i] && matFocused[i].complex">keyboard_arrow_up </mat-icon>
                </mat-form-field>
                <mat-error *ngIf="lastCriteriaNotFound && parentForm?.get('values').get(''+i).get('displayName').invalid">*Please select the criteiria</mat-error>
            </div>
        <div fxFlex="20" fxLayoutAlign="start start" >
            <div fxFlex="25" *ngIf="parentForm?.get('values') && parentForm?.get('values').value && parentForm?.get('values').value.length > 1 && !disableEditOffering">
                <div  class="row_delete_icon" fxLayoutAlign="center center">
                    <img src="../assets/icons/arrow_delete.svg" (click)="removeComplexRestriction(i)"  alt=""/>
                </div>
            </div>
            <div fxFlex="25" *ngIf="addNewComplexresIsAlwd() && !disableEditOffering ">
                <div *ngIf="lastItem " class="row_delete_icon" fxLayoutAlign="center center">
                    <mat-icon (click)="addComplexRestriction()" >add</mat-icon>
                </div>
            </div>
        </div>
        <div fxFlex="40" fxLayoutAlign="start center">
            <div class="complex_rest_description" *ngIf="parentForm?.get('values').value[i]._id" fxLayoutAlign="start center">
                <span style="float: left;"><mat-icon>error_outline</mat-icon></span>
                {{getTheDescription(i)}}
            </div>
        </div>
        </div>
    </ng-container>
</div>
