<div class="templates_info_section">
    <div class="template-info-gorup-alignment">
        <div fxLayout="row" fxLayoutAlign="start">
            <div fxFlex="20">
                <img class="offering-logo-img" src="../assets/icons/gift_icon.png" alt="" />
                <span class="offering-logo-lbl">Offerings</span>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start" >
            <div fxFlex="60%" fxLayout="row" class="tab-row" fxLayoutGap="1.25em">
                <div fxFlex="33%" >
                    <div class="tab-div active-tab"  [ngClass]="{'tab-div-active':(selectedOfferingName === 'Existing Offerings')}" (click)="loadSelectedOfferings('Active')">
                        <div fxLayout="row">
                            <div fxFlex="20%" class="tab-icon">
                                <mat-icon>check_circle_outline</mat-icon>
                            </div>
                            <div fxFlex="80%" class="tab-name">
                                <p >
                                    {{offeringsCount.Active.displayName}}
                                </p>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="75%" class="tab-count" fxFlexOffset="20%">
                                <p >
                                    {{offeringsCount.Active.total}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div  fxFlex="30%" >
                    <div class="tab-div draft-tab" [ngClass]="{'tab-div-active':(selectedOfferingName === 'Draft Offerings')}"  (click)="loadSelectedOfferings('Draft')">
                        <div fxLayout="row">
                            <div fxFlex="25%" class="tab-icon">
                                <img *ngIf="selectedOfferingName !== 'Draft Offerings'" class="" src="../assets/icons/draft_icon.png" alt="" />
                                <img *ngIf="selectedOfferingName === 'Draft Offerings'" class="" src="../assets/icons/draft_icon_white.png" alt="" />
                            </div>
                            <div fxFlex="75%" class="tab-name">
                                <p >
                                    {{offeringsCount.Draft.displayName}}
                                </p>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="75%" class="tab-count" fxFlexOffset="25%">
                                <p >
                                    {{offeringsCount.Draft.total}}
                                </p>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div  fxFlex="33%">
                    <div  class="tab-div archive-tab" [ngClass]="{'tab-div-active':(selectedOfferingName === 'Archived Offerings')}"  (click)="loadSelectedOfferings('Archived')">
                        <div fxLayout="row">
                            <div fxFlex="20%" class="tab-icon">
                                <img *ngIf="selectedOfferingName !== 'Archived Offerings'" class="" src="../assets/icons/archived_icon.png" alt="" />
                                <img *ngIf="selectedOfferingName === 'Archived Offerings'" class="" src="../assets/icons/archived_icon_white.png" alt="" />
                            </div>
                            <div fxFlex="80%" class="tab-name">
                                <p >
                                    {{offeringsCount.Archived.displayName}}
                                </p>
                            </div>
                        </div>
                        <div fxLayout="row">
                            <div fxFlex="75%" class="tab-count" fxFlexOffset="20%">
                                <p >
                                    {{offeringsCount.Archived.total}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start" fxLayoutGap="1em">
            <div fxFlex="70%" fxLayout="row"  class="search_row" fxLayoutGap="1em">
                <div fxFlex="27%" fxLayout="column" class="dropdown-field" fxLayoutAlign="center">
                    <mat-form-field appearance="outline" >
                        <!-- <label>All templates</label> -->
                        <mat-select [(value)]="offeringsFilterVal" (openedChange)="matFocusedEvent($event)" (selectionChange)="filterOrSortOfferings();" disableOptionCentering="true" >
                            <mat-option *ngFor="let option of offeringsFilterOptions" [value]="option.value">{{ option.name + ' - '+ option.count}}</mat-option>
                          </mat-select>
                       <mat-icon *ngIf="!matSelectFocused" class="mat-arrow-down" matSuffix>keyboard_arrow_down</mat-icon>
                       <mat-icon *ngIf="matSelectFocused" class="mat-arrow-up" matSuffix>keyboard_arrow_up</mat-icon>
                      </mat-form-field>
                </div>
                <div fxFlex="53%" fxLayout="row" class="serch_input_row">
                    <div fxFlex="10%" fxLayout="column" fxLayoutAlign="center center">
                        <mat-icon  class="search_icon">search</mat-icon>
                    </div>
                    <div fxFlex="80%" fxLayoutAlign="start center">
                        <input class="search_input"  [(ngModel)]="searchOptions.searchText" matInput placeholder="Search Offerings..."  #searchInput  (ngModelChange)="searchOfferings(); ">
                    </div>
                    <div fxFlex="10%" fxLayoutAlign="center center">
                        <mat-icon *ngIf="showSearchCloseIcon" class="close_icon" (click)="showSearchCloseIcon=false;searchOptions.searchText=null;searchOfferings();">close</mat-icon>
                    </div>
                </div>
                <div fxFlex="4%" fxLayoutAlign="start center" class="filter-row-icons">
                    <mat-icon (click)="searchOptions.sortDir = searchOptions.sortDir == 'ASC' ? 'DESC' : 'ASC';filterOrSortOfferings();">sort</mat-icon>
                </div>
                <!--filter offerings-->
                <div fxFlex="4%" fxLayoutAlign="center center" class="filte_icon">
                    <span class="selected-count" *ngIf="filterCount > 0">{{filterCount}}</span>
                    <img src="../assets/icons/filter_icon.svg" alt="" class="filter-icon-style" (click)="openFilterModal()"/>
                </div>
            </div>
            <div fxFlex="28%" fxLayoutAlign="end start">
                <button mat-stroked-button color="primary" (click)="createOffering()"  class="create_template_btn">
                    <mat-icon class="btn_icon">add</mat-icon>
                    Create Offerings
                </button>
            </div>
        </div>
        <div *ngIf="showSearchCloseIcon || filterCount > 0"  fxLayout="row" class="offering-list-header-row" fxLayoutAlign="start center">
            <div fxFlex="55%">
                <span class="results_count_span">{{(searchCount && searchCount !== '0') ? searchCount : 'No'}} Results</span>
                <span> found</span>
            </div>
        </div>
    </div>    
</div>
<div class="template-gorup-alignment">
    <div class="offering_lists_section" *ngIf="offeringsData.selectedOfferings && offeringsData.selectedOfferings.length">
        <div fxLayout="row" fxLayoutAlign="start" *ngFor="let offering of offeringsData.selectedOfferings"  style="cursor: pointer;">
            <div fxFlex="100%">
                <mat-card class="offering-card">
                    <div fxLayout="row" class="" fxLayoutAlign="center center">
                        <div fxFlex="10%" (click)="goToOffering(offering.reference)" fxLayoutAlign="center center">
                            <span *ngIf="offering.iconDetails">
                                <img class="iconSize" src={{offering.iconDetails.iconName}}>
                            </span>
                            <span *ngIf="!offering.iconDetails">
                                <img src="../assets/images/offering_sample_img.png" alt="" />
                            </span>
                        </div>
                        <div fxFlex="84%"  (click)="goToOffering(offering.reference)" class="product_info_section">
                            <div fxLayout="row" class="offering_info" fxLayoutAlign="start center">
                                <div fxFlex="100%">
                                   <span class="offering-name"> {{offering.displayName}}</span>
                                </div>
                            </div>
                            <div fxLayout="row" class="offering_info">
                                <div fxFlex="100%" >
                                    <p class="offering-desription">
                                        {{offering.description}}
                                    </p>
                                </div>
                            </div>
                            <div fxLayout="row" class="offering_info offering_status_info"  fxLayoutAlign="start center">
                                <div fxFlex="25%">
                                    <div class="prod-name">
                                        <span >{{offering.productSpecificationReference[0].displayName}}</span>
                                    </div>
                                 </div>
                                 <div fxFlex="25%">
                                    <div fxLayout="row" fxLayoutAlign="start center">
                                        <div  #tooltip="matTooltip" matTooltip="{{dispalyCategory(offering).list.join('\n')}}" [matTooltipDisabled]="(!dispalyCategory(offering).count || dispalyCategory(offering).count <= 1)" matTooltipClass="tooltip-list" [matTooltipPosition]="'below'" fxLayoutAlign="start center">
                                            <div class="prod-status" *ngIf="dispalyCategory(offering).displayName" [ngStyle]="{'border-color':dispalyCategory(offering).colorCode}"
                                            >
                                                <span class="prod-status-color" [ngStyle]="{'background-color':dispalyCategory(offering).colorCode}"></span>
                                                <span class="prod-status-type">
                                                    {{dispalyCategory(offering).displayName}}
                                                </span>
                                            </div>
                                            <span *ngIf="dispalyCategory(offering).count > 1" class="prod-status-count" [ngStyle]="{'border-color':dispalyCategory(offering).colorCode}">+{{dispalyCategory(offering).count ? dispalyCategory(offering).count-1 : 0}}
                                            </span>
                                        </div>
                                    </div>
                                 </div>
                                 <div fxFlex="25%">
                                    <div *ngIf="getCreatedBy(offering.createdBy)" fxLayout="row" fxLayoutAlign="start center">
                                        <span class="prod-user-icon"><mat-icon>account_circle</mat-icon></span>
                                        <span class="prod-user-name">{{getCreatedBy(offering.createdBy)}}</span>
                                    </div>
                                </div>
                                 <div fxFlex="25%" fxLayoutAlign="center center">
                                    <div fxLayot="row" fxLayoutAlign="start center" *ngIf="offering.createdAt">
                                        <span class="prod-time-icon"> <mat-icon>access_time</mat-icon></span>
                                        <span class="prod-time-info">{{ offering.createdAt| date: 'YYYY-MM-dd'}}</span>
                                    </div>
                                 </div>
                            </div>
                        </div>
                        <div fxFlex="6%"   class="edict_icon_section">
                            <div fxLayout="row" *ngIf="this.searchOptions.status === 'Active' || this.searchOptions.status === 'Draft'" fxLayoutAlign="center center" class="edit-icon-row">
                                <div class="edit-icon-div" (click)="editOffering(offering.reference)">
                                    <mat-icon   #tooltip="matTooltip"
                                    matTooltip="Edit this Offering"
                                    [matTooltipPosition]="'below'">edit</mat-icon>
                                </div>
                            </div>
                            <div fxLayout="row" [ngClass]="{'show_note_icon':this.searchOptions.status === 'Active','hide_note_icon':this.searchOptions.status !== 'Active'}" *ngIf="this.searchOptions.status !== 'Archived'" fxLayoutAlign="center end" class="edit-icon-row">
                                <div class="edit-icon-div">
                                    <mat-icon  #tooltip="matTooltip"
                                    matTooltip="Add to Template"
                                    [matTooltipPosition]="'below'" (click)="addToTemplate(offering.reference)">note_add</mat-icon>
                                </div>
                            </div>
                            <div fxLayout="row" *ngIf="this.searchOptions.status === 'Archived'" fxLayoutAlign="center center" class="edit-icon-row">
                                <div class="edit-icon-div">
                                    <mat-icon  #tooltip="matTooltip"
                                    matTooltip="Restore Template"
                                    [matTooltipPosition]="'below'" (click)="restoreArchivedOffering(offering.reference)">restore</mat-icon>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    
</div>
<app-footer></app-footer>

