import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseService } from './base-service';
import { ApiConfigService } from './api-config.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorHandlerService } from './http-error-handler.service';
import { urlType } from '../models/urlType';
import { CookieService } from 'ngx-cookie-service';


/**
 * Used to make generic standard API calls.  The base URL for the service calls is based on the configuration.
 */
@Injectable({
  providedIn: 'root'
})
export class BaseClientService extends BaseService  {
     /** Run a GET API call, expecting a response with a single model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
  getOne<TResponseModel>(route: string, action: string = 'error executing requests',
  params: HttpParams = new HttpParams(), withCredentials: boolean = false): Observable<HttpResponse<TResponseModel>> {
    let options;
    if (withCredentials) {
      options = {
        headers: this.getRequestHeaders(),
        params: params,
        observe: 'response',
        responseType: 'json',
        withCredentials: true
      };
    } else {
      options = {
        headers: this.getRequestHeaders(),
        params: params,
        observe: 'response',
        responseType: 'json'
      };
    }
    return this.http.get<TResponseModel>(
      this.rootUrl + route, options)
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

      /** Run a GET API call, expectiing a response with an array of the expected model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @param isBenefitBuilder The root api url or benefits 
   * @return A response containing the expected models (array)
   */
  getArr<TResponseModel>(route: string, action: string = 'error executing requests',
  params: HttpParams = new HttpParams(), isBenefitBuilder: boolean = false): Observable<HttpResponse<Array<TResponseModel>>> {
    return this.http.get<TResponseModel>(
      !isBenefitBuilder ? this.rootUrl + route : this.getBenefitBuilderURL + route,
      {
        headers: this.getRequestHeaders(),
        withCredentials: true,
        params: params,
        observe: 'response',
        responseType: 'json'
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /**
   * base constructor
   * @param config API Config service injector
   * @param http HTTP Client injector
   * @param errorHandler HTTP error handler injector
   */
  constructor(config: ApiConfigService, 
    http: HttpClient, 
    private errorHandler: HttpErrorHandlerService,
    private cookieService: CookieService) {
    super(config, http);
  }
  /** Run a GET API call, expecting a response with a single model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
  getByIdBB<TResponseModel>(
    route: string,
    action: string = 'error executing requests',
  ): Observable<HttpResponse<TResponseModel>> {
    const url = this.getBenefitBuilderURL;
    return this.http
      .get<TResponseModel>(url + route, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }
  /** Run a GET API call, expecting a response with a single model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected model (single)
   */
  getById<TResponseModel>(route: string, action: string = 'error executing requests', apiTypeUrl?:string): Observable<HttpResponse<TResponseModel>> {
    let url = this.rootUrl + route;
    if(apiTypeUrl && apiTypeUrl === 'apiBBUrl') {
      url = this.apiBBUrl + route;
    }
    return this.http.get<TResponseModel>(url, { params: this.newParams(), observe: 'response', responseType: 'json', headers: this.getRequestHeaders() })
    .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a GET API call, expectiing a response with an array of the expected model
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param action The action that is performing the request
   * @return A response containing the expected models (array)
   */
   get<TResponseModel>(
    route: string,
    action = 'error executing requests',
    apiUrlType?: urlType
  ): Observable<HttpResponse<Array<TResponseModel>>> {
    const url = apiUrlType === urlType.apiInt ? this.accessManagementUrl : this.rootUrl;
    console.log(url);
    return this.http
      .get<TResponseModel>(url + route, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a PUT API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being posted
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
   put<TResponseModel>(
    route: string,
    body: any,
    action = 'error put request',
    apiUrlType?: urlType
  ): Observable<HttpResponse<TResponseModel>> {
    const url = apiUrlType === urlType.apiInt ? this.accessManagementUrl : this.rootUrl;
    return this.http
      .put<TResponseModel>(url + route, body, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a PATCH API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being posted
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
   patch<TResponseModel>(
    route: string,
    body: any,
    action = 'error patch request',
    apiUrlType?: urlType
  ): Observable<HttpResponse<TResponseModel>> {
    const url = apiUrlType === urlType.apiInt ? this.accessManagementUrl : this.rootUrl;
    return this.http
      .patch<TResponseModel>(url + route, body, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a POST API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being posted
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
   post<TResponseModel>(
    route: string,
    body: any,
    action = 'error posting request',
    apiUrlType?: urlType
  ): Observable<HttpResponse<TResponseModel>> {
    const url = apiUrlType === urlType.apiInt ? this.accessManagementUrl : this.rootUrl;
    return this.http
      .post<TResponseModel>(url + route, body, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }

  /** Run a DELETE API call
   * @param route The endpoint for the delete request
   * @param action The action that is performing the request
   * @return A response containing the expected result
   */
   delete<TResponseModel>(
    route: string,
    action = 'error delete request',
    apiUrlType?: urlType
  ): Observable<HttpResponse<TResponseModel>> {
    const url = apiUrlType === urlType.apiInt ? this.accessManagementUrl : this.rootUrl;
    return this.http
      .delete<TResponseModel>(url + route, {
        params: this.newParams(),
        observe: 'response',
        responseType: 'json',
        headers: this.getRequestHeaders()
      })
      .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
  }


  private getRequestHeaders(): HttpHeaders {
    // Only send headers which have values to send
    let headers;
    const token = this.cookieService.get('car-ses-tok');
    const context = sessionStorage.getItem('car-ses-con');
    if (context) {
      headers = new HttpHeaders({
        'Authorization': token,
        'app-context': context,
        'Content-Type':'application/json'
      });
    } else {
      headers = new HttpHeaders({
        'Authorization': token,
        'Content-Type':'application/json'
      });
    }
    return headers;
  }

    /** Run a GET Integration API call, expectiing a response with an array of the expected model
   * @param route The endpoint for the request (ie. - '/v1/admin')
   * @param action The action that is performing the request
   * @return A response containing the expected models (array)
   */
    getInt<TResponseModel>(
      route: string,
      action = 'error executing requests',
      apiUrlType?: urlType
    ): Observable<HttpResponse<Array<TResponseModel>>> {
      const url = apiUrlType === urlType.apiInt ? this.apiIntegrationUrl : this.rootUrl;
      console.log(url);
      return this.http
        .get<TResponseModel>(url + route, {
          params: this.newParams(),
          observe: 'response',
          responseType: 'json',
          headers: this.getRequestHeaders()
        })
        .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }

  /** Run a PUT Integration API call
   * @param route The endpoint for the request (ie. - '/v1/reports_recent')
   * @param body The object that is being posted
   * @param action The action that is performing the request
   * @return A response containing the expected result (single)
   */
    putInt<TResponseModel>(
      route: string,
      body: any,
      action = 'error put request',
      apiUrlType?: urlType
    ): Observable<HttpResponse<TResponseModel>> {
      const url = apiUrlType === urlType.apiInt ? this.apiIntegrationUrl : this.rootUrl;
      return this.http
        .put<TResponseModel>(url + route, body, {
          params: this.newParams(),
          observe: 'response',
          responseType: 'json',
          headers: this.getRequestHeaders()
        })
        .pipe(catchError(this.errorHandler.handleHttpErrorResponse(action)));
    }
}
